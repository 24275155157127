import React from 'react'
import {Text, View, Image, StyleSheet} from '@react-pdf/renderer'
import moment from 'moment'
import momenttz from 'moment-timezone'
import LogoImg from './../../../assets/images/logo-green.png'

const Dispatch = ({data}) => {
  const {number, exporter, producer, reception, products, drying, dispatch} = data
  let totalweightGuide = 0
  let totalNetTeightGuide = 0
  let totalTareGuide = 0
  const dryingContainer = [].concat.apply([], drying.containers)

  // eslint-disable-next-line array-callback-return
  dryingContainer.map((c) => {
    totalweightGuide = totalweightGuide + c.weightWithContainer
    totalNetTeightGuide = totalNetTeightGuide + c.weightWithContainer - c.tare
    totalTareGuide = totalTareGuide + c.tare
  })

  const performance = ((totalNetTeightGuide / reception.netWeight) * 100).toFixed(2)
  const styles = StyleSheet.create({
    container: {
      paddingTop: 15,
      display: 'flex',
      flexDirection: 'column',
    },
    row: {
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 8,
      marginLeft: 50,
      marginBottom: 18,
    },
    rowObs: {
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 8,
      marginLeft: 50,
      marginBottom: 10,
    },
    rowLine: {
      width: '100%',
      borderBottomWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      marginBottom: 18,
    },
    rowLineLoad: {
      width: '100%',
      borderBottomWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      marginBottom: 18,
      paddingTop: 15,
    },
    image: {
      marginVertical: 25,
      marginHorizontal: 190,
    },
    truckImage: {
      marginLeft: 25,
      width: '90%',
      opacity: '0.6',
    },
    photos: {
      marginTop: 10,
      marginHorizontal: 100,
    },
    title: {
      textAlign: 'center',
      fontWeight: 'bold',
      borderWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      marginBottom: 20,
      fontSize: 14,
    },
    text: {
      margin: 3,
    },

    lot: {
      borderBottomWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      fontWeight: 'bold',
    },
    total: {
      borderBottomWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      paddingTop: 20,
    },
    colA: {
      width: '20%',
      fontFamily: 'Helvetica-Bold',
    },
    colATrip: {
      width: '15%',
      fontFamily: 'Helvetica-Bold',
    },
    colB: {
      width: '30%',
      marginBottom: 5,
    },
    colBTrip: {
      width: '15%',
      marginBottom: 5,
    },
    colC: {
      width: '20%',
      paddingLeft: 40,
    },
    rowLoadTruck: {
      marginTop: -46,
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 8,
      marginLeft: 38,
    },
    rowLoad: {
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 8,
      marginLeft: 50,
    },
    load: {
      borderWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      width: 24,
      height: 24,
    },
    code: {
      marginTop: 7,
      marginLeft: 3,
    },
    colContainer: {
      borderTopWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      width: '20%',
    },
    rowContainer: {
      marginLeft: 50,
      marginTop: 50,
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 8,
    },
  })

  const formatNumber = (number) => {
    return new Intl.NumberFormat('de-DE').format(number)
  }

  return (
    <>
      <Image style={styles.image} src={LogoImg} />
      <Text style={styles.title}>Informe consolidado</Text>
      <View style={styles.container}>
        <View style={styles.row}>
          <View style={styles.colA}>
            <Text>Exportadora:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{exporter?.name ? exporter.name : '-'}</Text>
          </View>
          <View style={styles.colA}>
            <Text>N° Guia origen:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{number}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.colA}>
            <Text>Productor:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{producer.name}</Text>
          </View>
          <View style={styles.colA}>
            <Text>Fecha de Recepción:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{moment(momenttz(reception.updatedAt).tz('America/Santiago')).format('DD-MM-YYYY HH:mm')}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.colA}>
            <Text>Variedad:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{products.name}</Text>
          </View>
          <View style={styles.colA}>
            <Text>Peso neto húmedo:</Text>
          </View>
          <View style={styles.colB}>
            {/*  <Text>{totalNetTeightGuide}</Text> */}
            <Text>{formatNumber(reception.netWeight)}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.colA}>
            <Text></Text>
          </View>
          <View style={styles.colB}>
            <Text></Text>
          </View>
          <View style={styles.colA}>
            <Text>Peso neto seco:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{formatNumber(totalNetTeightGuide)}</Text>
          </View>
        </View>
        <View style={styles.rowObs}>
          <View style={styles.colA}>
            <Text>Observación:</Text>
          </View>
          <View style={styles.colB}>
            <Text></Text>
          </View>
          <View style={styles.colA}>
            <Text>Rendimiento:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{`${formatNumber(performance)}%`}</Text>
          </View>
        </View>

        <View style={styles.rowObs}>
          <View>
            <Text>{dispatch.commentary}</Text>
          </View>
        </View>
      </View>
    </>
  )
}

export default Dispatch
