import React from 'react'
import ReactExport from 'react-export-excel'
import {EuiButton, EuiFlexGroup, EuiFlexItem} from '@elastic/eui'
import moment from 'moment'
import momenttz from 'moment-timezone'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const Excel = ({data}) => {
  return (
    <ExcelFile
      filename={`Reporte_${moment(momenttz().tz('America/Santiago')).format('DD-MM-YY')}`}
      element={
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}></EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton fill iconType='download' color='secondary'>
              Descargar Excel
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      }
    >
      <ExcelSheet data={data} name='Recepción y procesos'>
        <ExcelColumn label='Fecha recepción' value='receptionDate' />
        <ExcelColumn label='Guía despacho' value='number' />
        <ExcelColumn label='Hora recepción' value='receptionHour' />
        <ExcelColumn label='Lote' value='lot' />
        <ExcelColumn label='Exportadora' value='exporter' />
        <ExcelColumn label='Productor' value='producer' />
        <ExcelColumn label='Tipo envase' value='containerType' />
        <ExcelColumn label='Tara' value='containerTare' />
        <ExcelColumn label='# Envase' value='nContainer' />
        <ExcelColumn label='Peso según GD' value='weightGuide' />
        <ExcelColumn label='Peso bruto planta' value='grossWeight' />
        <ExcelColumn label='Tara' value='receptionTare' />
        <ExcelColumn label='Peso neto verde' value='netWeight' />
        <ExcelColumn label='Diferencia peso neto GD- Planta' value='weightDiff' />
        <ExcelColumn label='Peso neto promedio por envase' value='averageWeight' />
        <ExcelColumn label='Cantidad de envases' value='nContainersDrying' />
        <ExcelColumn label='Tipo de envases' value='typeContainer' />
        <ExcelColumn label='Peso c/envase' value='weightWithContainer' />
        <ExcelColumn label='Tara' value='tareDrying' />
        <ExcelColumn label='Peso neto' value='netWeightDrying' />
        <ExcelColumn label='Rendimiento' value={(col) => `${col.performance}%`} />
        <ExcelColumn label='Humedad de término' value={(col) => `${col.endHumidity}%`} />
        <ExcelColumn label='Cantidad de envases' value='dispatchContainers' />
        <ExcelColumn label='Tipo de envases' value='dispatchTypeContainer' />
        <ExcelColumn label='Peso neto' value='dispatchNetWeightDrying' />
        <ExcelColumn label='Guía despacho' value='dispatchNumber' />
      </ExcelSheet>
    </ExcelFile>
  )
}

export default Excel
