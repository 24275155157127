import React, {useState, useContext} from 'react'
import {
  EuiOverlayMask,
  EuiModal,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiForm,
  EuiFormRow,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiButton,
  EuiBasicTable,
  EuiSuperSelect,
  EuiListGroup,
  EuiHealth,
  EuiPanel,
  EuiSpacer,
  EuiCollapsibleNavGroup,
} from '@elastic/eui'
import {validate} from 'validate.js'
import {constraintsEs} from './validate'
import DRYING_SERVICES from './../../services/drying'
import {DataContext} from './../../context/DataContext'
import * as actionsUI from '../../store/actions/uiActions'
import ApproveButton from './../ApproveButton'
import moment from 'moment'
import momenttz from 'moment-timezone'

const Measurements = ({guide, setAlertHumidity}) => {
  const {drying} = guide
  const {updateGuide, user, dispatch, time, humidity} = useContext(DataContext)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [errors, setErrors] = useState({})
  const [value, setValue] = useState('')
  const [measures, setMeasures] = useState()
  const [selectedOptionsBoxes, setSelectedOptionsBoxes] = useState([])
  const measurements = [].concat.apply([], guide.drying.measurements)
  const boxList = [].concat.apply([], guide.drying.boxes)
  const [edit, setEdit] = useState(false)
  const [measureId, setMeasureId] = useState('')
  const [alerts, setAlerts] = useState()
  const [editBox, setEditBox] = useState(false)
  const [boxes, setBoxes] = useState('')
  const [disable, setDisable] = useState(true)
  const [disableSave, setDisableSave] = useState(false)
  const [oldBox, setOldBox] = useState(0)
  const [approve, setApprove] = useState(false)
  const [boxSelected, setBoxSelected] = useState('')

  const onChangeBox = (e) => {
    const box = Number(e.target.value)
    setBoxes(box <= 0 ? '' : box)
    box > 15 || box <= 0 ? setDisable(true) : setDisable(false)
  }

  const closeModal = () => {
    setIsModalVisible(false)
    setValue('')
    setErrors({})
    setSelectedOptionsBoxes([])
    setEdit(false)
    setBoxes('')
    setOldBox('')
    setEditBox(false)
  }
  const showModal = async () => {
    setBoxSelected('')
    setDisableSave(false)
    setApprove(false)
    setIsModalVisible(true)
    setMeasures(measurements)
    const boxes = [].concat.apply([], drying?.boxes)
    if (drying.boxes.length > 0) {
      const datas = boxes.map((box) => {
        const measures = measurements.filter((measure) => {
          return measure.box === box.box
        })

        let last = measurements
          .filter((measure) => {
            return measure.box === box.box
          })
          .pop()
        if (last?.date) {
          last = moment(last.date).diff(momenttz().tz('America/Santiago'), 'minute') * -1
        }

        return {
          box: box.box,
          //pendings: Math.floor(minutes / time - measures.length),
          boxDate: box.boxAdd,
          alert: last !== undefined ? last >= time : true,
          last: measures.length > 0 ? measures.pop().value : '-',
        }
      })

      setAlerts(datas)
    }
  }
  const onChangeBoxes = (selectedOptions) => {
    setSelectedOptionsBoxes(selectedOptions)
    setMeasures(measurements.filter((measurement) => measurement.box === selectedOptions))
  }

  const onChangeValue = (e) => {
    setValue(Number(e.target.value))
  }
  const save = async () => {
    setDisableSave(true)
    const form = {
      value,
      box: selectedOptionsBoxes,
      measureId: measureId ? measureId : '',
    }

    let validationResult
    validationResult = validate(form, constraintsEs)
    if (validationResult !== undefined) {
      setErrors(validationResult)
      setDisableSave(false)
      return
    }

    setErrors({})

    await DRYING_SERVICES.addMeasure(user.token, form, guide.drying._id)
      .then((data) => {
        const measurements = [].concat.apply([], data.data.measurements)
        const boxes = [].concat.apply([], data.data.boxes)

        const measure = boxes.map((box) => {
          return measurements.filter((measure) => measure.box === box.box)
        })

        const measurepop = measure.map((t) => {
          return t.pop()
        })

        measurepop.filter((t) => t?.value < humidity).length > 0 ? setAlertHumidity(true) : setAlertHumidity(false)

        closeModal()
        updateGuide(Object.assign(guide, {drying: data.data}))
        setMeasureId('')

        dispatch(actionsUI.addAlert({id: '1', title: 'Medida ingresada', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        console.log(error)
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const updateBox = async () => {
    await DRYING_SERVICES.editBox(user.token, {box: boxes, oldBox}, guide.drying._id)
      .then((data) => {
        updateGuide(Object.assign(guide, {drying: data.data}))

        closeModal()

        dispatch(actionsUI.addAlert({id: '1', title: 'Cajón actualizado', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const editOne = (e) => {
    setSelectedOptionsBoxes(e.box)
    setValue(e.value)
    setEdit(true)
    setMeasureId(e.id)
  }
  const editBoxes = (e) => {
    setEditBox(true)
    setOldBox(e.box)
    setBoxes(e.box)
    setApprove(false)
  }

  const cancelActions = () => {
    setApprove(false)
    setEditBox(false)
  }

  const approveDeleteBox = async () => {
    await DRYING_SERVICES.deleteBox(user.token, {box: boxSelected}, guide.drying._id)
      .then((data) => {
        updateGuide(Object.assign(guide, {drying: data.data}))
        setApprove(false)
        closeModal()
        dispatch(
          actionsUI.addAlert({id: '1', title: `Cajón #${boxSelected} eliminado`, color: 'success', iconType: 'check'}),
        )
      })
      .catch((error) => {
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const deleteBox = (e) => {
    setApprove(true)
    setBoxSelected(e.box)
    setEditBox(false)
  }

  const cancelEdit = () => {
    setEdit(false)
    setSelectedOptionsBoxes([])
    setValue('')
  }

  const actions = [
    {
      name: 'Edit',
      description: 'Editar',
      icon: 'documentEdit',
      type: 'icon',
      onClick: editOne,
    },
  ]

  const actionBox = [
    {
      name: 'Edit',
      description: 'Editar cajón',
      icon: 'documentEdit',
      type: 'icon',
      onClick: editBoxes,
    },
    {
      name: 'Eliminar',
      description: 'Eliminar',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: deleteBox,
    },
  ]

  const columns = [
    {
      field: 'date',
      name: 'Fecha',
      dataType: 'date',
      width: '55%',
      render: (date) => moment(momenttz(date).tz('America/Santiago')).format('DD-MM-YYYY HH:mm'),
    },
    {
      field: 'box',
      name: 'Cajón',
    },
    {
      field: 'value',
      name: 'Valor',
      render: (value) => `${value}%`,
    },
    {
      actions,
      width: '5%',
    },
  ]

  const columnsAlerts = [
    {
      actions: actionBox,
      width: '10%',
    },
    {
      field: 'box',
      name: '# Cajón',
      dataType: 'number',
      width: '10%',
    },

    {
      field: 'boxDate',
      name: 'Fecha ingreso',
      dataType: 'date',
      width: '20%',
      render: (date) => moment(momenttz(date).tz('America/Santiago')).format('DD-MM-YYYY HH:mm'),
    },
    {
      field: 'boxDate',
      name: 'T. transcurrido',
      dataType: 'date',
      width: '15%',
      render: (date) =>
        moment
          .duration(
            moment(momenttz(date).tz('America/Santiago')).diff(momenttz().tz('America/Santiago'), 'seconds') * -1,
            'seconds',
          )
          .format('HH:mm'),
    },

    {
      field: 'last',
      name: 'Último ingreso',
      width: '15%',
      render: (value) => {
        const color = value < humidity ? 'danger' : 'success'
        return value > 0 ? <EuiHealth color={color}>{`${value.toFixed(0)}%`}</EuiHealth> : '-'
      },
    },
    {
      field: 'alert',
      name: '',
      dataType: 'number',
      width: '25%',
      render: (alert) => {
        const color = !alert ? 'success' : 'danger'
        const label = !alert ? 'Muestras al dia' : 'Falta de muestras'
        return <EuiHealth color={color}>{label}</EuiHealth>
      },
    },
  ]

  const editABox = (
    <>
      <EuiModalHeaderTitle>Indique número de cajón</EuiModalHeaderTitle>
      <EuiForm>
        <EuiFlexGroup style={{paddingTop: 10}}>
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiFormRow>
                  <EuiFieldNumber max='15' name='boxes' onChange={(e) => onChangeBox(e)} value={boxes} />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          {' '}
          <EuiFlexItem>
            <EuiButton color='secondary' onClick={updateBox} isDisabled={disable} fill>
              Actualizar
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButton color='secondary' onClick={() => cancelActions()}>
              Cancelar
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </>
  )

  const deleteABox = (
    <>
      <EuiModalHeaderTitle>Eliminar cajon #{boxSelected}</EuiModalHeaderTitle>
      <EuiForm>
        <EuiFlexGroup gutterSize='none' style={{paddingTop: 10}}>
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <ApproveButton approve={approveDeleteBox} />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton color='danger' onClick={() => cancelActions()}>
                  Cancelar
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </>
  )

  const ingresoMedidas = (
    <>
      <EuiModalHeaderTitle>Ingreso de medidas</EuiModalHeaderTitle>
      <EuiForm>
        <EuiFlexGroup gutterSize='none' style={{paddingTop: 10}}>
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem grow={5}>
                <EuiFormRow
                  label='Porcentaje de humedad'
                  isInvalid={!errors.value ? false : true}
                  error={!errors.value ? '' : errors.value[0]}
                >
                  <EuiFieldNumber min='0' name='value' onChange={(e) => onChangeValue(e)} value={value} />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem grow={3}>
                <EuiFormRow
                  label='Cajón'
                  isInvalid={!errors.box ? false : true}
                  error={!errors.box ? '' : errors.box[0]}
                >
                  <EuiSuperSelect
                    placeholder='Seleccione una opción'
                    options={boxList.map((c) => ({value: c.box, inputDisplay: c.box}))}
                    valueOfSelected={selectedOptionsBoxes}
                    onChange={onChangeBoxes}
                    fullWidth
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size='m' />
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiButton onClick={save} isDisabled={disableSave} fullWidth fill>
                  Guardar
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </>
  )
  const formSample = (
    <>
      <EuiFlexGroup>
        <EuiFlexItem grow={4}>
          <EuiPanel paddingSize='s' color={'warning'}>
            {approve ? deleteABox : editBox ? editABox : ingresoMedidas}{' '}
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={10}>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiCollapsibleNavGroup
                iconType='watchesApp'
                paddingSize='none'
                titleSize={'s'}
                style={{backgroundColor: '#d3d9e4'}}
                title='Alertas'
                isCollapsible={true}
                initialIsOpen={true}
              >
                <EuiPanel paddingSize='none' color={'warning'}>
                  <EuiBasicTable items={alerts} rowHeader='firstName' columns={columnsAlerts} />
                </EuiPanel>
              </EuiCollapsibleNavGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiCollapsibleNavGroup
                iconType='metricbeatApp'
                paddingSize='none'
                titleSize={'s'}
                style={{backgroundColor: '#d3d9e4'}}
                title='Medidas'
                isCollapsible={true}
                initialIsOpen={true}
              >
                <EuiPanel paddingSize='none'>
                  <EuiBasicTable items={measures} rowHeader='firstName' columns={columns} />
                </EuiPanel>
              </EuiCollapsibleNavGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )

  let modal
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal
          style={{margin: 'auto', width: '90%'}}
          maxWidth={false}
          onClose={closeModal}
          initialFocus='[name=popswitch]'
        >
          <EuiListGroup></EuiListGroup>
          <EuiModalBody>{formSample}</EuiModalBody>

          <EuiModalFooter>
            {edit ? (
              <>
                <EuiButtonEmpty onClick={() => cancelEdit()}>Cancelar</EuiButtonEmpty>
                <EuiButton iconType={'check'} color='secondary' onClick={save} fill>
                  Actualizar
                </EuiButton>
              </>
            ) : (
              <>
                <EuiButtonEmpty onClick={closeModal}>Salir</EuiButtonEmpty>
              </>
            )}
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <>
      {modal}
      <EuiButtonIcon onClick={showModal} iconType='metricbeatApp' iconSize='l' />
    </>
  )
}

export default Measurements
