export const constraintsEs = {
  selectedOptionsBox: {
    presence: {
      allowEmpty: false,
      message: '^Seleccione una opción',
    },
  },
  selectedOptionsContainerType: {
    presence: {
      allowEmpty: false,
      message: '^Seleccione una opción',
    },
  },
  tare: {
    numericality: {
      greaterThan: 0,
      notGreaterThan: 'debe ser mayor a 0',
      message: '^ Ingrese un numero',
    },
  },
  weightWithContainer: {
    numericality: {
      greaterThan: 0,
      notGreaterThan: 'debe ser mayor a 0',
      message: '^ Ingrese un numero',
    },
  },
}
