import React, {useState, useEffect, useContext} from 'react'
import {EuiBeacon, EuiPopover, EuiText} from '@elastic/eui'
import moment from 'moment'
import momenttz from 'moment-timezone'
import {DataContext} from './../../context/DataContext'

const AlertBeacon = ({drying}) => {
  const {time} = useContext(DataContext)
  const [alertBox, setAlertBox] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen)
  const closePopover = () => setIsPopoverOpen(false)

  const measurements = [].concat.apply([], drying?.measurements)
  const boxes = [].concat.apply([], drying?.boxes)

  useEffect(() => {
    const timer = setInterval(() => {
      const measure = boxes.map((box) => {
        return measurements.filter((measure) => measure.box === box.box)
      })

      const measurepop = measure.map((t) => t.pop())

      const alerted = measurepop.filter(
        (t) => moment(t?.date).diff(momenttz().tz('America/Santiago'), 'minute') * -1 >= time,
      )
      alerted.length > 0 ? setAlertBox(true) : setAlertBox(false)
    }, 3000)
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drying])

  const button = <EuiBeacon style={{backgroundColor: 'orange', marginLeft: 5}} onClick={onButtonClick} />

  return alertBox ? (
    <EuiPopover ownFocus button={button} isOpen={isPopoverOpen} closePopover={closePopover}>
      <EuiText>
        <p>Muestras pendientes de ingreso</p>
      </EuiText>
    </EuiPopover>
  ) : null
}

export default AlertBeacon
