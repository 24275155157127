import React from 'react'
import {EuiFlexGroup, EuiFlexItem, EuiPanel, EuiStat} from '@elastic/eui'
const Stats = ({totalDry, totalContainers, loading}) => {
  return (
    <EuiFlexGroup>
      <EuiFlexItem></EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiPanel paddingSize='s'>
          <EuiStat
            title={new Intl.NumberFormat('de-DE').format(totalContainers)}
            description='Total envases'
            titleColor='primary'
            textAlign='center'
            isLoading={loading}
          />
        </EuiPanel>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiPanel paddingSize='s'>
          <EuiStat
            title={new Intl.NumberFormat('de-DE').format(totalDry)}
            description='Total kilos secos'
            titleColor='secondary'
            textAlign='center'
            isLoading={loading}
          />
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default Stats
