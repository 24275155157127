import React, {useState, useContext} from 'react'
import {
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiForm,
  EuiFormRow,
  EuiFieldNumber,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiButton,
  EuiFormErrorText,
} from '@elastic/eui'
import DRYING_SERVICES from './../../services/drying'
import {DataContext} from './../../context/DataContext'

const Boxes = ({guide}) => {
  const {updateGuide, user, dispatch, actionsUI} = useContext(DataContext)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [boxes, setBoxes] = useState('')
  const [disable, setDisable] = useState(true)
  const closeModal = () => {
    setIsModalVisible(false)
  }
  const showModal = async () => {
    setIsModalVisible(true)
    if (guide.drying.boxes) {
      setBoxes(guide.drying.boxes)
    }
  }

  const onChangeBoxes = (e) => {
    const box = Number(e.target.value)
    setBoxes(box <= 0 ? '' : box)
    box > 15 || box <= 0 ? setDisable(true) : setDisable(false)
  }

  const save = async () => {
    setDisable(true)
    await DRYING_SERVICES.addBox(user.token, {box: boxes}, guide.drying._id)
      .then((data) => {
        closeModal()
        updateGuide(Object.assign(guide, {drying: data.data}))

        dispatch(actionsUI.addAlert({id: '1', title: 'Medida ingresada', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const formSample = (
    <EuiForm style={{maxWidth: 340}}>
      <EuiFormRow>
        <EuiFieldNumber max='15' name='boxes' onChange={(e) => onChangeBoxes(e)} value={boxes} />
      </EuiFormRow>
      {boxes > 15 ? <EuiFormErrorText id={1}>Ingrese del 1 al 15</EuiFormErrorText> : null}
    </EuiForm>
  )

  let modal
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal maxWidth={false} onClose={closeModal} initialFocus='[name=popswitch]'>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Indique número de cajón</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>{formSample}</EuiModalBody>

          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeModal}>Salir</EuiButtonEmpty>

            <EuiButton onClick={save} isDisabled={disable} fill>
              Guardar
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }
  return (
    <>
      {modal}
      <EuiButtonIcon onClick={showModal} iconType='savedObjectsApp' iconSize='l' />
    </>
  )
}

export default Boxes
