import API from './constants'

var AUTH_SERVICES = {
  login: async (data) => {
    return await API.post('auth/login', data)
  },
  recovery: async function (email) {
    return await API.post('auth/recovery', {email})
  },
  approve: async function (token, data) {
    return await API.post('auth/approve', data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },

  changePassword: async function (token, data) {
    return await API.post('auth/changePassword', data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  register: async function (data) {
    return await API.post('auth/register', data)
  },
  me: async (token) => {
    return await API.get('auth/me', {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
}
export default AUTH_SERVICES
