export const constraintsEs = {
  totalSample: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese número',
    },
  },
  s1: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese número',
    },
  },
  s2: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese número',
    },
  },
  s3: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese número',
    },
  },
  s4: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese número',
    },
  },
  s5: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese número',
    },
  },
  photos: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese al menos una imagen',
    },
  },
}
