import axios from 'axios'

// export const URL_LOCAL = 'http://192.168.1.8:3000'
export const URL_LOCAL = 'https://ulmen-secado-api.kunturtech.app'
export const URL_AWS = 'https://api-dev.ulmengroup.com'
export const URL_TEST = 'http://104.198.63.121:8080/'

let API = axios.create({baseURL: URL_LOCAL})

export const setClientToken = (token) => {
  API.defaults.headers.common = {
    Authorization: `bearer ${token}`,
  }
}

export default API
