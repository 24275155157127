import React, {useState, useContext} from 'react'
import {
  EuiFormRow,
  EuiFormErrorText,
  EuiForm,
  EuiFieldPassword,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui'
import AUTH_SERVICES from './../../services/auth'
import {DataContext} from './../../context/DataContext'

const UpdatePassword = ({user, handleUpdatePassword}) => {
  const {dispatch, actionsUI} = useContext(DataContext)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, newOldPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [invalidRut, setInvalidRut] = useState(false)

  const onChangeOldPassword = (e) => {
    setOldPassword(e.target.value)
  }

  const onChangeNewPassword = (e) => {
    newOldPassword(e.target.value)
  }

  const send = async () => {
    setLoading(true)
    if (oldPassword === '') {
      setInvalidRut(true)
      setLoading(false)
    } else {
      setInvalidRut(false)
    }

    if (newPassword === '') {
      setInvalid(true)
      setLoading(false)
    } else {
      setInvalid(false)
    }
    if (oldPassword === '' || newPassword === '') {
      return null
    }
    await AUTH_SERVICES.changePassword(user.token, {password: oldPassword, newPassword, email: user.user.email})
      .then((data) => {
        handleUpdatePassword(false)
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: 'Contraseña actualizada', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  return (
    <EuiForm>
      <EuiFlexGroup direction='column' gutterSize='xs' justifyContent='spaceAround'>
        <EuiFlexItem>
          <EuiFormRow>
            <EuiFieldPassword
              fullWidth
              placeholder='Contraseña actual'
              value={oldPassword}
              onChange={(e) => onChangeOldPassword(e)}
            />
          </EuiFormRow>
          {invalidRut ? <EuiFormErrorText id={1}>Ingrese contraseña actual</EuiFormErrorText> : null}
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFormRow>
            <EuiFieldPassword
              isInvalid={invalid}
              fullWidth
              placeholder='Nueva contraseña'
              value={newPassword}
              onChange={(e) => onChangeNewPassword(e)}
            />
          </EuiFormRow>
          {invalid ? <EuiFormErrorText id={1}>Ingrese nueva contraseña</EuiFormErrorText> : null}
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButton isLoading={loading} fullWidth={true} type='submit' fill onClick={send}>
            Actualizar
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiForm>
  )
}

export default UpdatePassword
