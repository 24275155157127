import React, {useState, useContext} from 'react'
import {
  EuiListGroupItem,
  EuiFlexGroup,
  EuiFlexItem,
  EuiListGroup,
  EuiText,
  EuiButton,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
} from '@elastic/eui'
import QrReader from 'react-qr-reader'
import DRYING_SERVICES from './../../services/drying'
import {DataContext} from './../../context/DataContext'

const CheckContainers = ({guide, dataContainers, handleSelected, handleAllChecked}) => {
  const {updateGuide, user, dispatch, actionsUI} = useContext(DataContext)
  const [qr, setQr] = useState('Sin resultados')
  const [showCam, setShowCam] = useState(false)
  const [code, setCode] = useState()
  const [message, setMessage] = useState()
  const [multiScan, setMultiScan] = useState(false)
  const [camera, setCamera] = useState('environment')

  const handleScan = async (data) => {
    if (data) {
      setQr(data)
      console.log(qr)
      if (Number(data) === Number(code)) {
        await DRYING_SERVICES.statesContainers(user.token, {code, type: 'stateCheck'}, guide.drying._id)
          .then((data) => {
            dataContainers = [].concat.apply([], data.data.containers)
            const scanCodes = dataContainers.filter((c) => c.stateCheck === false)
            if (scanCodes.length === 0) handleAllChecked(false)
            setShowCam(false)
            updateGuide(Object.assign(guide, {drying: data.data}))
            if (multiScan) multipleScan()
            dispatch(actionsUI.addAlert({id: '1', title: 'Codigo validado', color: 'success', iconType: 'check'}))
          })
          .catch((error) => {
            dispatch(
              actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
            )
          })
      } else {
        setMessage(`Codigo esperado: ${code} | Codigo escaneado: ${data}`)
      }
    }
  }

  const handleError = (err) => {
    console.error(err)
  }

  const compareCode = (code, state) => {
    setMultiScan(false)
    if (state === false) {
      setShowCam(true)
      setCode(Number(code))
      setMessage(`Código esperado: ${code}`)
    }
  }

  const multipleScan = () => {
    setMultiScan(true)
    setShowCam(true)
    const scanCodes = dataContainers.filter((c) => c.stateCheck === false)

    if (scanCodes.length > 0) {
      const scanCode = scanCodes.splice(0, 1)[0].code
      setCode(Number(scanCode))
      setMessage(`Código esperado: ${scanCode}`)
    } else {
      handleAllChecked(false)
      setMultiScan(false)
      setShowCam(false)
      dispatch(
        actionsUI.addAlert({id: '1', title: 'No hay mas códigos para escanear', color: 'success', iconType: 'check'}),
      )
    }
  }

  return (
    <>
      {!showCam ? (
        <>
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}>
              <EuiButton iconType='arrowLeft' onClick={() => handleSelected('start')}>
                Volver
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton iconType='nested' fill onClick={() => multipleScan()}>
                Escaneo multiple
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='xl' />
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiText size='s'>Seleccione un código pendiente de escaneo</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiListGroup maxWidth={false}>
                {dataContainers.map((item) => (
                  <EuiListGroupItem
                    id='link1'
                    iconType='analyzeEvent'
                    label={` ${item.code}`}
                    isActive
                    onClick={() => compareCode(item.code, item.stateCheck)}
                    extraAction={{
                      color: item.stateCheck ? 'secondary' : 'danger',
                      iconType: item.stateCheck ? 'check' : 'alert',
                      iconSize: 'l',
                      'aria-label': 'Favorite link1',
                      alwaysShow: true,
                    }}
                  />
                ))}
              </EuiListGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      ) : (
        <>
          <EuiButton
            iconType='editorRedo'
            onClick={() => (camera === 'user' ? setCamera('environment') : setCamera('user'))}
          >
            Cambiar cámara
          </EuiButton>
          <EuiPanel paddingSize='s'>
            <EuiFlexGroup justifyContent='spaceAround'>
              {dataContainers.map((c) => (
                <EuiFlexItem grow={false}>
                  <EuiIcon color={c.stateCheck ? 'secondary' : 'danger'} type='analyzeEvent' size='l' />
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          </EuiPanel>

          <EuiSpacer size='xs' />
          <EuiFlexGroup>
            <EuiFlexItem>
              <QrReader
                delay={800}
                onError={handleError}
                onScan={handleScan}
                style={{width: '100%'}}
                facingMode={camera}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='xs' />
          <EuiPanel paddingSize='s'>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiText>{`${message}`}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </>
      )}
    </>
  )
}

export default CheckContainers
