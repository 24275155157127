import API from './constants'

var RECEPTIONS_SERVICES = {
  create: async (token, data) => {
    return await API.post(`reception`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  updateState: async (token, data, id) => {
    return await API.put(
      `reception/${id}`,
      {state: data},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        contentType: true,
      },
    )
  },
  update: async (token, data, id) => {
    return await API.put(
      `reception/update/${id}`,
      {data},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        contentType: true,
      },
    )
  },
}

export default RECEPTIONS_SERVICES
