import React, {useState, useContext, useEffect} from 'react'
import {
  EuiForm,
  EuiFormRow,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiButton,
  EuiCallOut,
  EuiOverlayMask,
  EuiConfirmModal,
  EuiText,
  EuiHorizontalRule,
  EuiKeyPadMenu,
  EuiIcon,
  EuiKeyPadMenuItem,
  EuiSpacer,
  EuiProgress,
  EuiTextColor,
  EuiPanel,
  EuiDescriptionList,
  EuiTextArea,
} from '@elastic/eui'
import TRIPS_SERVICES from './../../services/trips'
import DISPATCH_SERVICES from './../../services/dispatch'
import GUIDES_SERVICES from '../../services/guides'
import {DataContext} from './../../context/DataContext'
import {validate} from 'validate.js'
import Template from './../Template'
import {constraintsEs} from './validate'
import TruckIcon from './../../assets/images/truck-icon.svg'
import ApproveButton from './../ApproveButton'
import PreviewPdf from './../PreviewPdf'

const LoadContainers = ({guide, handleSelected, closeModal}) => {
  const {user, dispatch, actionsUI, updateGuide, getGuides, guidesDispatch} = useContext(DataContext)
  const [patent, setPatent] = useState('')
  const [tripId, setTripId] = useState('')
  const [inProgress, setInProgress] = useState(false)
  const [load, setLoad] = useState(false)
  const [loading, setLoading] = useState(false)
  const [allLoad, setAllLoad] = useState(false)
  const [errors, setErrors] = useState({})
  const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false)
  const [commentary, setCommentary] = useState('')
  const dataContainers = [].concat.apply([], guide.drying.containers)
  useEffect(() => {
    setCommentary(guide.dispatch.commentary)
    setLoad(false)
    let notLoaded = dataContainers.filter((c) => c.stateLoad === false)

    if (notLoaded.length === 0) {
      setAllLoad(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleAllLoad = (option) => {
    setAllLoad(option)
  }

  const onChangePatent = (e) => {
    setPatent(e.target.value.toUpperCase())
  }
  const onChangeCommentary = (e) => setCommentary(e.target.value)
  const handleLoad = (value) => {
    setLoad(value)
    setPatent('')
  }

  let guides = guide.dispatch.trip.map((t) => {
    return t.guides
  })

  guides = [].concat.apply([], guides)

  guides = guides.filter((g) => g.entryGuide === guide.number)

  const closeDestroyModal = () => setIsDestroyModalVisible(false)
  const showDestroyModal = () => setIsDestroyModalVisible(true)
  let destroyModal
  if (isDestroyModalVisible) {
    const data = [
      {
        title: 'Patente:',
        description: patent,
      },
    ]
    destroyModal = (
      <EuiOverlayMask>
        <EuiConfirmModal
          title='Nueva carga'
          onCancel={closeDestroyModal}
          onConfirm={() => save()}
          cancelButtonText='Cancelar'
          confirmButtonText='Aceptar'
          defaultFocusedButton='confirm'
        >
          <EuiPanel paddingSize='s'>
            <EuiText>Creación de viaje</EuiText>
            <EuiHorizontalRule margin='xs' />
            <EuiDescriptionList type='responsiveColumn' listItems={data} />
          </EuiPanel>
        </EuiConfirmModal>
      </EuiOverlayMask>
    )
  }

  //Si no existe patente en Trip, muestro formularo y al primer scaneo de qr creo la planilla
  const search = async () => {
    const trips = [].concat.apply(
      [],
      // eslint-disable-next-line array-callback-return
      guidesDispatch.filter((g) => {
        if (g.status === 'dispatch') {
          return g.dispatch.trip
        }
      }),
    )

    const patentfound = trips.filter((t) => t.patent === patent && t.status !== 'load')
    if (patentfound.length > 0) {
      dispatch(
        actionsUI.addAlert({
          id: '1',
          title: `La patente ${patent} ya se encuentra en gestión en estos momentos`,
          color: 'warning',
          iconType: 'help',
        }),
      )
      return
    }

    let validationResult
    validationResult = validate({patent}, constraintsEs)

    if (validationResult !== undefined) {
      setLoading(false)
      setErrors(validationResult)
      return
    }

    setErrors({})

    await TRIPS_SERVICES.getByPatent(user.token, patent)
      .then((data) => {
        setTripId(data.data._id)
        if (data.data.status === 'progress') {
          setInProgress(true)
          setLoad(false)
        } else {
          setLoad(true)
          setInProgress(false)
        }
      })
      .catch((error) => {
        showDestroyModal()
      })
  }

  const save = async (send) => {
    const form = {
      patent,
      //exitGuide,
      load: [
        {seat: 1, code: 0, state: 'empty', exitGuide: 0},
        {seat: 2, code: 0, state: 'empty', exitGuide: 0},
        {seat: 3, code: 0, state: 'empty', exitGuide: 0},
        {seat: 4, code: 0, state: 'empty', exitGuide: 0},
        {seat: 5, code: 0, state: 'empty', exitGuide: 0},
        {seat: 6, code: 0, state: 'empty', exitGuide: 0},
        {seat: 7, code: 0, state: 'empty', exitGuide: 0},
        {seat: 8, code: 0, state: 'empty', exitGuide: 0},
        {seat: 9, code: 0, state: 'empty', exitGuide: 0},
        {seat: 10, code: 0, state: 'empty', exitGuide: 0},
        {seat: 11, code: 0, state: 'empty', exitGuide: 0},
        {seat: 12, code: 0, state: 'empty', exitGuide: 0},
        {seat: 13, code: 0, state: 'empty', exitGuide: 0},
        {seat: 14, code: 0, state: 'empty', exitGuide: 0},
        {seat: 15, code: 0, state: 'empty', exitGuide: 0},
        {seat: 16, code: 0, state: 'empty', exitGuide: 0},
        {seat: 17, code: 0, state: 'empty', exitGuide: 0},
        {seat: 18, code: 0, state: 'empty', exitGuide: 0},
        {seat: 19, code: 0, state: 'empty', exitGuide: 0},
        {seat: 20, code: 0, state: 'empty', exitGuide: 0},
        {seat: 21, code: 0, state: 'empty', exitGuide: 0},
        {seat: 22, code: 0, state: 'empty', exitGuide: 0},
        {seat: 23, code: 0, state: 'empty', exitGuide: 0},
        {seat: 24, code: 0, state: 'empty', exitGuide: 0},
        {seat: 25, code: 0, state: 'empty', exitGuide: 0},
        {seat: 26, code: 0, state: 'empty', exitGuide: 0},
        {seat: 27, code: 0, state: 'empty', exitGuide: 0},
        {seat: 28, code: 0, state: 'empty', exitGuide: 0},
        {seat: 29, code: 0, state: 'empty', exitGuide: 0},
        {seat: 30, code: 0, state: 'empty', exitGuide: 0},
      ],
      status: 'load',
    }

    if (send === 'delivery') {
      await TRIPS_SERVICES.updateState(user.token, {status: 'delivery'}, tripId)
        .then((data) => {
          setInProgress(false)
          dispatch(actionsUI.addAlert({id: '1', title: 'Viaje cerrado', color: 'success', iconType: 'check'}))
        })
        .catch((err) => {
          console.log({err})
        })
    }
    //// ENVIAR TRIP ID Y EN TEMPLATE BUSCAR TRIP CON ESE ID PARA RELLENAR
    await TRIPS_SERVICES.create(user.token, form)
      .then((data) => {
        setTripId(data.data._id)
        setLoad(true)
        closeDestroyModal()
        setInProgress(false)
        DISPATCH_SERVICES.update(user.token, {trip: data.data._id}, guide.dispatch._id)
          .then((g) => {
            updateGuide(Object.assign(guide, {dispatch: g.data}))
            setInProgress(false)
            dispatch(actionsUI.addAlert({id: '1', title: 'Reporte actualizado', color: 'success', iconType: 'check'}))
          })
          .catch((err) => {
            console.log({err})
          })
      })
      .catch((error) => {
        console.log({error})
      })
  }

  const openTruck = (id, status) => {
    setTripId(id)
    setLoad(true)
  }

  const approve = async () => {
    setLoading(true)
    GUIDES_SERVICES.update(user.token, {state: 'attended'}, guide._id)
      .then((data) => {
        getGuides()

        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: 'Guia finalizada', color: 'success', iconType: 'check'}))
        closeModal()
      })
      .catch((err) => {
        setLoading(false)
        console.log({err})
      })
  }

  const updateCommentary = async () => {
    setLoading(true)
    await DISPATCH_SERVICES.updateCommentary(user.token, {commentary}, guide.dispatch._id)
      .then((data) => {
        setLoading(false)
        updateGuide(Object.assign(guide, {dispatch: data.data}))
        //  setCommentary(data.data.commentary)
        dispatch(actionsUI.addAlert({id: '1', title: 'Reporte actualizado', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }
  return !load ? (
    <>
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexItem grow={false}>
          <EuiButton iconType='arrowLeft' onClick={() => handleSelected('start')}>
            Volver
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}></EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size='xl' />
      <EuiForm>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow
              label='Patente'
              fullWidth
              isInvalid={!errors.patent ? false : true}
              error={!errors.patent ? '' : errors.patent[0]}
            >
              <EuiFieldText fullWidth name='patent' onChange={(e) => onChangePatent(e)} value={patent} />
            </EuiFormRow>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiButton style={{marginTop: 21}} fullWidth onClick={() => search()} isLoading={loading} fill>
              Buscar
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
      {inProgress ? (
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiCallOut title='La patente ingresada se encuentra en progreso. Crear nuevo viaje ?' iconType='help'>
              <EuiButton fullWidth onClick={() => save('delivery')} fill>
                Aceptar
              </EuiButton>
            </EuiCallOut>
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : null}
      <EuiForm>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow fullWidth>
              <EuiTextArea
                placeholder='Observación'
                fullWidth
                compressed
                name='commentary'
                onChange={(e) => onChangeCommentary(e)}
                value={commentary}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButton fullWidth onClick={() => updateCommentary()} isLoading={loading} fill>
              Guardar Observación
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
      {guides.length === guide.dispatch.trip.length && allLoad ? (
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <ApproveButton approve={approve} size={'s'} />
          </EuiFlexItem>
          <EuiFlexItem>
            <PreviewPdf guide={guide} />
          </EuiFlexItem>
          <EuiFlexItem></EuiFlexItem>
        </EuiFlexGroup>
      ) : null}

      <EuiSpacer size='xl' />
      <EuiPanel paddingSize='s' color='subdued'>
        <EuiText>
          Envases <EuiTextColor color='secondary'>cargados</EuiTextColor> {' y '}
          <EuiTextColor color='danger'>por cargar</EuiTextColor>
        </EuiText>
        {loading ? <EuiProgress size='xs' /> : <EuiHorizontalRule margin='xs' />}
        <EuiKeyPadMenu style={{minWidth: 500}}>
          {dataContainers.map((c) => {
            return (
              <EuiKeyPadMenuItem label={`#${c.code}`}>
                <EuiIcon type='analyzeEvent' size='xl' color={c.stateLoad ? 'secondary' : 'danger'} />
              </EuiKeyPadMenuItem>
            )
          })}
        </EuiKeyPadMenu>

        {guide.dispatch.trip?.length > 0 ? (
          <>
            <EuiText>Camiones con cargas asociadas a esta guía</EuiText>
          </>
        ) : (
          <EuiText>Sin camion asociados para esta guía</EuiText>
        )}
        {loading ? <EuiProgress size='xs' /> : <EuiHorizontalRule margin='xs' />}
        <EuiKeyPadMenu>
          {guide && guide.dispatch && guide.dispatch.trip
            ? guide.dispatch.trip?.map((trip) => {
                return (
                  <EuiKeyPadMenuItem
                    label={trip.patent}
                    style={trip.status === 'progress' ? null : {color: 'black'}}
                    onClick={() => openTruck(trip._id, trip.status)}
                  >
                    <EuiIcon type={TruckIcon} size='xxl' />
                  </EuiKeyPadMenuItem>
                )
              })
            : null}
        </EuiKeyPadMenu>
      </EuiPanel>

      {destroyModal}
    </>
  ) : (
    <Template
      guide={guide}
      tripId={tripId}
      save={save}
      setLoad={handleLoad}
      handleAllLoad={handleAllLoad}
      allLoad={allLoad}
    />
  )
}

export default LoadContainers
