import {EuiText} from '@elastic/eui'

var objets = {
  options: [
    {
      value: 10,
      inputDisplay: <EuiText size='m'>10</EuiText>,
    },
    {
      value: 20,
      inputDisplay: <EuiText size='m'>20</EuiText>,
    },
    {
      value: 40,
      inputDisplay: <EuiText size='m'>40</EuiText>,
    },
    {
      value: 80,
      inputDisplay: <EuiText size='m'>80</EuiText>,
    },
    {
      value: 100,
      inputDisplay: <EuiText size='m'>100</EuiText>,
    },
  ],
}
export default objets
