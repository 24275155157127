export const constraints = {
  email: {
    presence: {
      allowEmpty: false,
      message: '^Por favor ingrese un correo electrónico',
    },
    email: {
      message: '^Por favor ingrese un correo electrónico válido',
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: '^Por favor ingrese un password',
    },
    length: {
      minimum: 4,
      maximum: 10,
    },
  },
}

export const constraintsRecovery = {
  email: {
    presence: {
      allowEmpty: false,
      message: '^Por favor ingrese un correo electrónico',
    },
    email: {
      message: '^Por favor ingrese un correo electrónico válido',
    },
  },
}
