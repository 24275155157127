export const constraintsEs = {
  patent: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese patente',
    },
  },
  /* exitGuide: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese patente',
    },
  },*/
}
