export const constraintsEn = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^Please enter a name',
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: '^Please enter a last name',
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: '^Please enter an email address',
    },
    email: {
      message: '^Please enter a valid email address',
    },
  },

  typeLegal: {
    presence: {
      allowEmpty: false,
      message: '^Please selected a type legal docuemnt ID',
    },
  },
  legalDni: {
    presence: {
      allowEmpty: false,
      message: '^Please enter a identity number',
    },
  },
  type: {
    presence: {
      allowEmpty: false,
      message: '^Please selected a user type',
    },
  },
  status: {
    presence: {
      allowEmpty: false,
      message: '^Please selected a status',
    },
  },
}

export const constraintsEs = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^Por favor ingrese un nombre',
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: '^Por favor ingrese un apellido',
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: '^Por favor ingrese un email',
    },
    email: {
      message: '^Por favor ingrese un email válido',
    },
  },

  type: {
    presence: {
      allowEmpty: false,
      message: '^Por favor selecione un tipo de usuario',
    },
  },
  status: {
    presence: {
      allowEmpty: false,
      message: '^Por favor selecione un estado',
    },
  },
}
