import React, {useContext} from 'react'
import {EuiTabbedContent} from '@elastic/eui'
import ControlProcess from './../../components/Report/ControlProcess'
import PdfReport from './../../components/Report/PdfReport'
import MailReception from './../../components/Report/MailReception'
import Kpis from './../../components/Report/Kpis'

import {DataContext} from './../../context/DataContext'

const Reports = () => {
  const {user} = useContext(DataContext)
  const tabsUser = [
    {
      id: 'control-process',
      name: 'Recepción y Procesos',
      content: <ControlProcess />,
    },
  ]

  const tabsAdmin = [
    {
      id: 'control-process',
      name: 'Recepción y Procesos',
      content: <ControlProcess />,
    },
    {
      id: 'pdf-reports',
      name: 'Reportes generados',
      content: <PdfReport />,
    },
    {
      id: 'mail-reception',
      name: 'Recepción de correos',
      content: <MailReception />,
    },
    {
      id: 'kpi',
      name: 'Indicadores de rendimiento',
      content: <Kpis />,
    },
  ]

  return (
    <EuiTabbedContent
      tabs={user.user.type === 'admin' ? tabsAdmin : tabsUser}
      initialSelectedTab={user.user.type === 'admin' ? tabsAdmin[0] : tabsUser[0]}
      autoFocus='selected'
      onTabClick={(tab) => {
        console.log('clicked tab', tab)
      }}
    />
  )
}

export default Reports
