import React, {useState, useEffect} from 'react'
import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiPagination,
  EuiSpacer,
  EuiText,
  EuiSuperSelect,
  EuiLoadingSpinner,
  EuiForm,
  EuiFormControlLayout,
  EuiFieldNumber,
  EuiFieldSearch,
} from '@elastic/eui'
import {useDispatch, useSelector} from 'react-redux'
import CONTAINERS_SERVICES from '../../../services/containers'
import * as actionsUI from '../../../store/actions/uiActions'

const Container = () => {
  const dispatch = useDispatch()
  const token = useSelector((store) => store.auth.user.token)
  const [maskOpen, setMaskOpen] = useState(false)
  //const [confirm, setConfirm] = useState(false)
  const [modal, setModal] = useState(false)
  const [action, setAction] = useState('')
  const [errors, setErrors] = useState({})
  const [name, setName] = useState('')
  const [tare, setTare] = useState('')
  const [selectedOptionsContainerType, setSelectedOptionsContainerType] = useState([])
  const [pageOfItems, setPageOfItems] = useState([])
  const [customHeader] = useState(true)
  //const [isResponsive] = useState(true)
  const [pageNext, setPagenext] = useState('')
  const [pageIndex, setPageIndex] = useState(0)
  const [pageIndexPrevious, setPageIndexPrevious] = useState(0)
  const [sortField, setSortField] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')
  const [, setSelectedItems] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [activePage, setActivePage] = useState(0)
  const [item, setItem] = useState({})
  const [loadOther, setloadOther] = useState(false)
  const [pagePrevius, setPagePrvevius] = useState('')
  const [actionsEvent] = useState(false)
  const [searchName, setSearchName] = useState('')

  useEffect(() => {
    setMaskOpen(true)
    getContainers('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, actionsEvent])

  useEffect(() => {
    setMaskOpen(true)
    getContainers('*')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, loadOther])

  const getContainers = async (type) => {
    let next = ''
    let previous = ''
    if (type === '') {
      next = pageIndex > pageIndexPrevious ? pageNext : ''
      previous = pageIndex < pageIndexPrevious ? pagePrevius : ''
      setPageIndexPrevious(pageIndex)
    } else {
      setPageIndexPrevious(0)
      setPageIndex(0)
      setActivePage(0)
      next = ''
      previous = ''
    }
    await CONTAINERS_SERVICES.getAll(token, pageSize, next, previous, ['admin'], sortField, searchName)
      .then((data) => {
        const datos = data.data
        setPageOfItems(datos.docs)
        setPagenext(datos.hasNext ? datos.next : '')
        setPagePrvevius(datos.hasPrevious ? datos.previous : '')
        setPageCount(Math.ceil(datos.totalDocs / pageSize))
        setMaskOpen(false)
      })
      .catch((error) => {
        setMaskOpen(false)
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const modalLoading = (
    <>
      <EuiOverlayMask>
        <EuiLoadingSpinner size='xl' />
      </EuiOverlayMask>
    </>
  )

  const closeModal = () => {
    setName('')
    setTare('')
    setSelectedOptionsContainerType([])
    setModal(false)
  }

  const containerType = [
    {value: 'Bin', inputDisplay: 'BIN'},
    {value: 'Batea', inputDisplay: 'BATEA'},
    {value: 'Maxisaco', inputDisplay: 'MAXISACO'},
  ]

  const onChangeName = (e) => {
    setName(e.target.value)
  }
  const onChangeTare = (e) => {
    setTare(e.target.value)
  }

  const onChangeContainerType = (selectedOptions) => {
    setSelectedOptionsContainerType(selectedOptions)
  }

  const onChangeSearchName = (e) => {
    setSearchName(e.target.value)
  }

  const addContainer = () => {
    setAction('Add')
    setModal(true)
  }

  const capitalize = (word) => {
    const lower = word.toLowerCase()
    return lower[0].toUpperCase() + lower.slice(1)
  }

  const save = async () => {
    const form = {
      name: capitalize(name),
      tare: Number(tare),
      type: selectedOptionsContainerType,
    }
    setErrors({})
    setMaskOpen(true)

    if (action === 'Add') {
      await CONTAINERS_SERVICES.create(token, form)
        .then((data) => {
          setloadOther(!loadOther)
          dispatch(actionsUI.addAlert({id: '1', title: 'Envase ingresado', color: 'success', iconType: 'check'}))
          closeModal()
        })
        .catch((error) => {
          setloadOther(!loadOther)
          dispatch(
            actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
          )
        })
    } else {
      CONTAINERS_SERVICES.update(token, form, item._id)
        .then((data) => {
          setPageOfItems(pageOfItems.map((item) => (item._id === data.data._id ? {...data.data} : item)))
          dispatch(actionsUI.addAlert({id: '1', title: 'Envase actualizado', color: 'success', iconType: 'check'}))
          setMaskOpen(false)
          closeModal()
        })
        .catch((error) => {
          setMaskOpen(false)
          dispatch(
            actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
          )
        })
    }
  }

  const editContainer = async (container) => {
    setMaskOpen(true)
    setItem(container)
    await CONTAINERS_SERVICES.getOne(token, container._id)
      .then((data) => {
        const record = data.data
        setName(record.name)
        setTare(record.tare)
        setSelectedOptionsContainerType(containerType.filter((element) => element.value === record.type)[0].value)
        setAction('Update')
        setModal(true)
        setMaskOpen(false)
      })
      .catch((error) => {
        setMaskOpen(false)
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }
  const deleteContainer = async (container) => {
    setMaskOpen(true)
    await CONTAINERS_SERVICES.delete(container._id, token)
      .then((data) => {
        setloadOther(!loadOther)
        dispatch(actionsUI.addAlert({id: '1', title: 'Envase eliminado', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        setMaskOpen(false)
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
    setSelectedItems([])
  }

  const actions = [
    {
      name: 'Edit',
      description: 'Editar',
      icon: 'documentEdit',
      type: 'icon',
      onClick: editContainer,
    },
    {
      name: 'Delete',
      description: 'Eliminar',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: deleteContainer,
    },
  ]
  const onTableChange = ({page = {}, sort = {}}) => {
    const {index: pageIndex, size: pageSize} = page
    const {field: sortField, direction: sortDirection} = sort
    setPageIndex(pageIndex)
    setPageSize(pageSize)
    setSortField(sortField)
    setSortDirection(sortDirection)
  }

  const columns = [
    {
      field: 'name',
      name: 'Nombre',
      truncateText: true,
      sortable: true,
      mobileOptions: {
        render: customHeader ? (item) => <span>{item.name}</span> : undefined,
        header: true,
        fullWidth: customHeader ? true : false,
        enlarge: customHeader ? true : false,
        truncateText: customHeader ? false : true,
      },
    },
    {
      field: 'tare',
      name: 'Tara',
      truncateText: true,
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'type',
      name: 'Tipo',
      truncateText: true,
      mobileOptions: {
        show: true,
      },
    },
    {
      name: 'Acciones',
      actions,
    },
  ]

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  }

  const options = [
    {
      value: 10,
      inputDisplay: <EuiText size='m'>10</EuiText>,
    },
    {
      value: 20,
      inputDisplay: <EuiText size='m'>20</EuiText>,
    },
    {
      value: 40,
      inputDisplay: <EuiText size='m'>40</EuiText>,
    },
    {
      value: 80,
      inputDisplay: <EuiText size='m'>80</EuiText>,
    },
    {
      value: 100,
      inputDisplay: <EuiText size='m'>100</EuiText>,
    },
  ]

  const goToPage = (pageNumber) => {
    setPageIndexPrevious(activePage)
    setPageIndex(pageNumber)
    setActivePage(pageNumber)
  }

  const onSearchName = (value) => {
    setSearchName(value)
    setloadOther(!loadOther)
  }

  return (
    <>
      {maskOpen ? modalLoading : undefined}

      {modal && (
        <EuiOverlayMask>
          <EuiModal onClose={closeModal} initialFocus='[name=popswitch]' style={{width: 400}}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>Envase</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody style={{height: 300}}>
              <EuiForm>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      label='Nombre'
                      fullWidth
                      isInvalid={errors.name === undefined ? false : true}
                      error={errors.name === undefined ? '' : errors.name[0]}
                    >
                      <EuiFieldText fullWidth name='name' onChange={(e) => onChangeName(e)} value={name} />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>

                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      label='Tipo envase'
                      fullWidth
                      isInvalid={errors.type === undefined ? false : true}
                      error={errors.type === undefined ? '' : errors.type[0]}
                    >
                      <EuiSuperSelect
                        placeholder='Seleccione una opción'
                        options={containerType}
                        valueOfSelected={selectedOptionsContainerType}
                        onChange={onChangeContainerType}
                        fullWidth
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>

                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow
                        label='Tara'
                        fullWidth
                        isInvalid={errors.tare === undefined ? false : true}
                        error={errors.tare === undefined ? '' : errors.tare[0]}
                      >
                        <EuiFieldNumber min='0' fullWidth name='tare' onChange={(e) => onChangeTare(e)} value={tare} />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiForm>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButtonEmpty onClick={closeModal}>Cancelar</EuiButtonEmpty>
              <EuiButton onClick={save} fill>
                Guardar
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      )}
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton onClick={addContainer} fill size='s'>
            Agregar envase
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormControlLayout>
            <EuiFieldSearch
              placeholder='Ingrese nombre'
              value={searchName}
              isClearable={true}
              onChange={onChangeSearchName}
              onSearch={onSearchName}
            />
          </EuiFormControlLayout>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <>
            <EuiBasicTable
              items={pageOfItems}
              itemId='id'
              columns={columns}
              //pagination={pagination}
              sorting={sorting}
              isSelectable={true}
              hasActions={true}
              responsive={true}
              onChange={onTableChange}
            />
            <EuiSpacer size='m' />
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div style={{display: 'flex', alignItems: 'center', width: '50%'}}>
                <EuiText size='m'>Filas</EuiText>
                <EuiSuperSelect
                  options={options}
                  valueOfSelected={pageSize}
                  onChange={(value) => {
                    setPageSize(value)
                  }}
                  style={{marginLeft: 10}}
                  compressed
                />
              </div>
              <div style={{width: '50%', justifyContent: 'flex-end'}}>
                <EuiPagination
                  pageCount={pageCount}
                  activePage={activePage}
                  onPageClick={(activePage) => goToPage(activePage)}
                  compressed
                  style={{justifyContent: 'flex-end'}}
                />
              </div>
            </div>
          </>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

export default Container
