import { UI_TYPES } from '../actions/uiActions';

const INITIAL_STATE = {
    alerts: []
};

export const uiReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UI_TYPES.UI_ADDALERT:
            return {
                alerts: state.alerts.concat(action),
            }
        case UI_TYPES.UI_REMOVEALERT:
            return {
                alerts: state.alerts.filter(toast => toast.id !== action.id)
            };
        default:
            return state;
    }
}