import React from 'react'
import {EuiFlexGroup, EuiFlexItem, EuiPanel} from '@elastic/eui'
import Container from './Container'
import Variable from './Variable'
const Configurations = () => {
  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFlexGroup direction='column'>
            <EuiFlexItem grow={false}>
              <EuiPanel style={{marginBottom: 15}} color='subdued' borderRadius='none' hasShadow={false}>
                <p style={{fontSize: 20}}>Envase</p>
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <Container />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFlexGroup direction='column'>
            <EuiFlexItem grow={false}>
              <EuiPanel style={{marginBottom: 15}} color='subdued' borderRadius='none' hasShadow={false}>
                <p style={{fontSize: 20}}>Variable</p>
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <Variable />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}
export default Configurations
