import React from 'react'
import {Text, View, Image, StyleSheet} from '@react-pdf/renderer'
import LogoImg from './../../../assets/images/logo-green.png'
import moment from 'moment'
import momenttz from 'moment-timezone'

const Reception = ({data}) => {
  const {number, producer, reception, exporter, products} = data
  const styles = StyleSheet.create({
    container: {
      paddingTop: 15,
      display: 'flex',
      flexDirection: 'column',
    },
    row: {
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 8,
      marginLeft: 50,
      marginBottom: 18,
    },

    rowObs: {
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 8,
      marginLeft: 50,
      marginBottom: 10,
    },

    rowLine: {
      width: '100%',
      borderBottomWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      marginBottom: 18,
    },

    image: {
      marginVertical: 25,
      marginHorizontal: 190,
    },
    photos: {
      marginTop: 10,
      marginHorizontal: 100,
    },
    title: {
      textAlign: 'center',
      fontWeight: 'bold',
      borderWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      marginBottom: 20,
      fontSize: 14,
    },
    text: {
      margin: 3,
    },

    lot: {
      borderBottomWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      fontWeight: 'bold',
    },
    total: {
      borderBottomWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      paddingTop: 20,
    },
    colA: {
      width: '20%',
      fontFamily: 'Helvetica-Bold',
    },
    colB: {
      width: '30%',
      marginBottom: 5,
    },
    colTipoEnvase: {
      width: '8%',
      marginBottom: 5,
    },
    colBatea: {
      width: '10%',
      paddingLeft: 20,
    },
    colA1: {
      width: '15%',
      fontFamily: 'Helvetica-Bold',
    },
    colB1: {
      width: '10%',
      marginBottom: 5,
      paddingLeft: 20,
    },
  })

  const formatNumber = (number) => {
    return new Intl.NumberFormat('de-DE').format(number)
  }

  return (
    <>
      <Image style={styles.image} src={LogoImg} />
      <Text style={styles.title}>Informe de Recepción</Text>
      <View style={styles.container}>
        <View style={styles.row}>
          <View style={styles.colA}>
            <Text>Exportadora</Text>
          </View>
          <View style={styles.colB}>
            <Text>{exporter?.name ? exporter.name : '-'}</Text>
          </View>
          <View style={styles.colA}>
            <Text>N° Guía origen:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{number}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.colA}>
            <Text>Productor:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{producer.name}</Text>
          </View>
          <View style={styles.colA}>
            <Text>Fecha de Recepción:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{moment(momenttz(reception.updatedAt).tz('America/Santiago')).format('DD-MM-YYYY HH:mm')}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.colA}>
            <Text>Variedad:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{products.name}</Text>
          </View>
          <View style={styles.colA}>
            <Text></Text>
          </View>
          <View style={styles.colB}>
            <Text></Text>
          </View>
        </View>
        {/************************************************************************************* */}
        <View style={styles.rowLine}>
          <View></View>
        </View>
        {/************************************************************************************* */}
        <View style={styles.row}>
          <View style={styles.colA}>
            <Text></Text>
          </View>
          <View style={styles.colB}>
            <Text></Text>
          </View>
          <View style={styles.colA}>
            <Text></Text>
          </View>
          <View style={styles.colB}>
            <Text></Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.colA}>
            <Text>Kilos neto ingresados verde:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{formatNumber(reception.netWeight)} </Text>
          </View>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.colA}>
          <Text>Tipo Envase:</Text>
        </View>
        <View style={styles.colB}>
          <Text>{reception.container.name} </Text>
        </View>

        <View style={styles.colA1}>
          <Text>Cantidad envase:</Text>
        </View>
        <View style={styles.colB1}>
          <Text>{formatNumber(reception.numberContainer)}</Text>
        </View>
      </View>

      <View style={styles.rowObs}>
        <View style={styles.colA}>
          <Text>Observación:</Text>
        </View>
      </View>

      <View style={styles.rowObs}>
        <View>
          <Text>{reception.commentary}</Text>
        </View>
      </View>
    </>
  )
}

export default Reception
