import React, {useState, useContext} from 'react'
import {
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiForm,
  EuiFormRow,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSuperSelect,
  EuiButtonIcon,
  EuiHorizontalRule,
  EuiBasicTable,
  EuiSpacer,
  EuiButton,
  EuiButtonEmpty,
  EuiProgress,
  EuiTextArea,
} from '@elastic/eui'
import {useDispatch} from 'react-redux'
import {validate} from 'validate.js'
import GUIDES_SERVICES from './../../services/guides'
import * as actionsUI from '../../store/actions/uiActions'
import FooterFormReport from './../FooterFormReport'
import {DataContext} from './../../context/DataContext'
import CONTAINERS_SERVICES from './../../services/containers'
import DRYING_SERVICES from './../../services/drying'
import {constraintsEs} from './validate'
import DISPATCH_SERVICES from './../../services/dispatch'
import QrPrint from './../QrPrint'

let containerType = []

const DryingReport = ({guide}) => {
  const {updateGuide, deleteGuide, addGuide, user} = useContext(DataContext)
  const dispatch = useDispatch()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [container, setContainer] = useState([{}])
  const [guideContainers, setGuideContainers] = useState([])
  const [boxes, setBoxes] = useState([{}])
  const [selectedOptionsContainerType, setSelectedOptionsContainerType] = useState([])
  const [selectedOptionsBox, setSelectedOptionsBox] = useState([])
  const [weightWithContainer, setWeightWithContainer] = useState('')
  const [weight, setWeight] = useState('')
  const [tare, setTare] = useState('')
  const [showApprovalPanel, setShowApprovalPanel] = useState(false)
  const [containerId, setContainerId] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [maskOpen, changeMask] = useState(false)
  const [code, setCode] = useState(0)
  const [showApprove, setShowApprove] = useState(false)
  const [commentary, setCommentary] = useState('')

  const approveButton = (dataBox, dataContainer) => {
    let totalBox = dataBox.length
    let boxwithContainer = dataBox.map((box) => {
      return dataContainer.find((c) => c.box === box.box)
    })
    let filtered = boxwithContainer.filter(function (el) {
      return el != null
    })

    if (filtered.length === totalBox) {
      setShowApprove(true)
    }
  }

  const onChangeCommentary = (e) => setCommentary(e.target.value)

  const closeModal = () => {
    setIsModalVisible(false)
    setGuideContainers([])
    setContainerId('')
    setShowApprove(false)
  }

  const showModal = async () => {
    const dataContainer = [].concat.apply([], guide.drying.containers)

    const dataBox = [].concat.apply([], guide.drying.boxes)
    const dataSelecteBox = dataBox.map((b) => ({value: b.box, inputDisplay: b.box}))
    setBoxes(dataSelecteBox)
    setIsModalVisible(true)
    getContainers()
    approveButton(dataBox, dataContainer)
    setCommentary(guide.drying.commentary)

    if (user.user.type !== 'user') setShowApprovalPanel(true)
    setGuideContainers(dataContainer)
  }

  const onChangeContainerType = (selectedOptions) => {
    setSelectedOptionsContainerType(selectedOptions)
    setTare(container.filter((c) => c.value === selectedOptions)[0].tare)
  }
  const onChangeBox = (selectedOptions) => {
    const dataContainer = [].concat.apply([], guide.drying.containers)
    setSelectedOptionsBox(selectedOptions)
    setGuideContainers(dataContainer.filter((c) => c.box === selectedOptions))
  }
  const onChangeTare = (e) => setTare(Number(e.target.value))

  const onChangeWithContainer = (e) => {
    setWeightWithContainer(Number(e.target.value <= 0 ? '' : e.target.value))
  }

  const deleteOne = (e) => {
    update('delete', e)
  }

  const editOne = (e) => {
    setContainerId(e.id)
    setSelectedOptionsContainerType(e.container[0]._id)
    setTare(e.tare)
    setWeightWithContainer(e.weightWithContainer)
    setEdit(true)
  }

  const updateOne = () => {
    //const old = {id: containerId, container: }

    const validates = {
      selectedOptionsBox,
      selectedOptionsContainerType,
      tare,
      weightWithContainer,
    }
    let validationResult
    validationResult = validate(validates, constraintsEs)
    if (validationResult !== undefined) {
      setErrors(validationResult)
      return
    }

    setErrors({})

    update('update')
  }
  const addOne = () => {
    const validates = {
      selectedOptionsBox,
      selectedOptionsContainerType,
      tare,
      weightWithContainer,
    }
    let validationResult
    validationResult = validate(validates, constraintsEs)

    if (validationResult !== undefined) {
      setErrors(validationResult)
      return
    }

    setErrors({})

    update('add')
  }

  const changeMaskQr = (e) => {
    setCode(e.code)
    setWeight(e.weightWithContainer)
    changeMask(true)
  }

  const actions = [
    {
      name: 'Editar',
      description: 'Editar',
      icon: 'documentEdit',
      type: 'icon',
      onClick: editOne,
    },
    {
      name: 'Eliminar',
      description: 'Eliminar',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: deleteOne,
    },
    {
      name: 'Imprimir',
      description: 'Imprimir',
      icon: 'tableDensityExpanded',
      type: 'icon',
      onClick: changeMaskQr,
    },
  ]

  const columns = [
    {
      field: 'box',
      name: 'Cajón',
      width: '25%',
    },
    {
      field: 'container[0].name',
      name: 'Envase',
      width: '25%',
    },
    {
      field: 'tare',
      name: 'Tara',
    },
    {
      field: 'weightWithContainer',
      name: 'Peso con envase',
    },

    {
      actions,
      width: '5%',
    },
  ]
  const getContainers = async () => {
    await CONTAINERS_SERVICES.getAllList(user.token)
      .then((data) => {
        containerType = data.data.map((c) => ({tare: c.tare, value: c._id, inputDisplay: c.name, disabled: false}))
        setContainer(containerType)
      })
      .catch((error) => {
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const approve = async () => {
    setLoading(true)
    await DRYING_SERVICES.updateState(user.token, {state: 'sent', approvalDate: new Date(Date.now())}, guide.drying._id)
      .then((data) => {
        showModal(true)
        setIsModalVisible(true)
        setShowApprovalPanel(true)
        updateGuide(Object.assign(guide, {drying: data.data}))
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: 'Reporte actualizado', color: 'success', iconType: 'check'}))
      })
      .catch((err) => {
        setLoading(false)
        console.log({err})
      })

    await DISPATCH_SERVICES.create(user.token, {commentary: ''})
      .then((data) => {
        GUIDES_SERVICES.update(user.token, {state: 'dispatch', dispatch: data.data._id}, guide._id)
          .then((data) => {
            deleteGuide(data.data)
            addGuide(data.data)
            setLoading(false)
            dispatch(
              actionsUI.addAlert({id: '1', title: 'Guia enviada a despacho', color: 'success', iconType: 'check'}),
            )
          })
          .catch((err) => {
            setLoading(false)
            console.log({err})
          })
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  const update = async (op, e) => {
    setLoading(true)
    let form = ''
    setShowApprovalPanel(true)
    if (op === 'add' || op === 'update') {
      form = {
        box: selectedOptionsBox,
        container: selectedOptionsContainerType,
        tare: Number(tare),
        weightWithContainer,
        commentary,
      }
    }
    if (op === 'delete') {
      form = {id: e.id, container: e.container[0]._id, tare: e.tare, weightWithContainer: e.weightWithContainer}
    }

    await DRYING_SERVICES.update(user.token, form, guide.drying._id, op, containerId ? containerId : undefined)
      .then((data) => {
        showModal(true)
        if (op !== 'delete') {
          setIsModalVisible(true)
          changeMask(true)
        }
        setShowApprovalPanel(true)
        setTare('')
        setWeight(weightWithContainer)
        setWeightWithContainer('')
        setCommentary(data.data.commentary)
        setSelectedOptionsContainerType('')
        setEdit(false)
        updateGuide(Object.assign(guide, {drying: data.data}))
        setGuideContainers([].concat.apply([], data.data.containers))
        setContainerId('')
        setLoading(false)
        approveButton([].concat.apply([], data.data.boxes), [].concat.apply([], data.data.containers))
        setCode([].concat.apply([], data.data.containers).pop().code)
      })
      .catch((err) => {
        setLoading(false)
        console.log({err})
      })
  }

  const formSample = (
    <EuiForm style={{maxWidth: 660}}>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow
            label='Cajón'
            fullWidth
            isInvalid={!errors.selectedOptionsBox ? false : true}
            error={!errors.selectedOptionsBox ? '' : errors.selectedOptionsBox[0]}
          >
            <EuiSuperSelect
              placeholder='Seleccione una opción'
              options={boxes}
              valueOfSelected={selectedOptionsBox}
              onChange={onChangeBox}
              fullWidth
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow
            label='Envase'
            fullWidth
            isInvalid={!errors.selectedOptionsContainerType ? false : true}
            error={!errors.selectedOptionsContainerType ? '' : errors.selectedOptionsContainerType[0]}
          >
            <EuiSuperSelect
              placeholder='Seleccione una opción'
              options={container}
              valueOfSelected={selectedOptionsContainerType}
              onChange={onChangeContainerType}
              fullWidth
            />
          </EuiFormRow>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFormRow
            label='Tara'
            fullWidth
            isInvalid={!errors.tare ? false : true}
            error={!errors.tare ? '' : errors.tare[0]}
          >
            <EuiFieldNumber readOnly fullWidth name='tare' onChange={(e) => onChangeTare(e)} value={tare} />
          </EuiFormRow>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFormRow
            label='Peso con envase'
            isInvalid={!errors.weightWithContainer ? false : true}
            error={!errors.weightWithContainer ? '' : errors.weightWithContainer[0]}
          >
            <EuiFieldNumber
              name='weightWithContainer'
              onChange={(e) => onChangeWithContainer(e)}
              value={weightWithContainer}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      {edit ? (
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiButtonEmpty onClick={() => setEdit(false)}>Cancelar</EuiButtonEmpty>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButton iconType={'check'} color='secondary' onClick={updateOne} fill>
              Actualizar
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : null}
      <EuiSpacer size='m' />
      <EuiFormRow fullWidth>
        <EuiTextArea
          placeholder='Observación'
          fullWidth
          compressed
          name='commentary'
          onChange={(e) => onChangeCommentary(e)}
          value={commentary}
        />
      </EuiFormRow>
      <EuiHorizontalRule margin='l' />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiBasicTable items={guideContainers} rowHeader='firstName' columns={columns} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size='l' />
      <FooterFormReport
        showApprovalPanel={showApprovalPanel}
        {...user}
        approve={approve}
        closeModal={closeModal}
        create={addOne}
        data={guide.drying.containers.length > 0 && showApprove ? true : false}
        loading={loading}
        guide={guide}
      />
    </EuiForm>
  )

  const modalMask = <QrPrint guide={guide} code={code} weight={weight} changeMask={changeMask} />

  let modal
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal maxWidth={false} onClose={closeModal} initialFocus='[name=popswitch]'>
          <EuiModalHeader>
            {loading ? <EuiProgress size='xs' position='absolute' /> : null}
            <EuiModalHeaderTitle>Informe de Secado</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>{formSample}</EuiModalBody>

          <EuiModalFooter></EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <>
      {modal}
      {maskOpen ? modalMask : undefined}

      <EuiButtonIcon onClick={showModal} iconType='reportingApp' iconSize='l' />
    </>
  )
}

export default DryingReport
