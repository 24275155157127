import React from 'react'

import {EuiHeader, EuiText, EuiImage} from '@elastic/eui'
import {useSelector} from 'react-redux'
import HeaderUserMenu from './../../components/HeaderUserMenu'
import NavBar from './../../components/NavBar'
import LogoImg from '../../assets/images/logo-green.png'

const Header = ({setNavIsDocked}) => {
  const user = useSelector((store) => store.auth.user)
  const updateDocked = (val) => {
    setNavIsDocked(val)
  }

  const leftSectionItems = [
    <NavBar updateDocked={updateDocked} />,
    <EuiText style={{marginLeft: 10, marginTop: 5}}>
      <EuiImage size={60} alt='Accessible image alt goes here' url={LogoImg} />
    </EuiText>,
  ]

  return (
    <EuiHeader
      position='fixed'
      sections={[
        {
          items: leftSectionItems,
          //borders: 'right',
        },
        {
          items: [<HeaderUserMenu name={`${user.user.name} ${user.user.lastName}`} user={user} />],
        },
      ]}
    />
  )
}

export default Header
