import API from './constants'

var EMAIL_SERVICES = {
  getActivity: async (token, data) => {
    return await API.put(`email`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
}

export default EMAIL_SERVICES
