import API from './constants'

var CONTAINERS_SERVICES = {
  getAll: async (token, limit, next, previous, types, sortField, searchName) => {
    return await API.post(
      `container/all?limit=${limit}&next=${next}&previous=${previous}&sortField=${sortField}&filterName=${searchName}`,
      {types},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        contentType: true,
      },
    )
  },
  getAllList: async (token) => {
    return await API.get(`container/list`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  getOne: async (token, id) => {
    return await API.get(`container/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  create: async (token, data) => {
    return await API.post(`container`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  update: async (token, data, id) => {
    return await API.put(`container/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  delete: async (id, token) => {
    return await API.delete(`container/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
}

export default CONTAINERS_SERVICES
