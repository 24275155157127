import API from './constants'

var GUIDES_SERVICES = {
  createPreLoad: async (token, data) => {
    return await API.post(`guide/pre-load`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  create: async (token, data) => {
    return await API.post(`guide`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  getOne: async (token, id) => {
    return await API.get(`guide/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  getPhotos: async (token, id) => {
    return await API.get(`guide/getPhotos/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },  
  getProducersByExporter: async (token, data) => {
    return await API.post(
      `guide/producerByExporter`,
      data,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        contentType: true,
      },
    )
  },
  getByNumber: async (token, number, producerRut) => {
    return await API.get(`guide/number/${number}/${producerRut}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  getByLot: async (token, lot) => {
    return await API.get(`guide/lot/${lot}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  getAll: async (
    token,
    types,
    filterExporter,
    filterProducer,
    number,
    exitNumber,
    startDate,
    endDate,
    limit,
    next,
    previous,
    sortField,
  ) => {
    return await API.post(
      `guide/all?filterExporter=${filterExporter}&filterProducer=${filterProducer}&filterNumber=${number}&filterExitNumber=${exitNumber}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&next=${next}&previous=${previous}&sortField=${sortField}`,
      types,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        contentType: true,
      },
    )
  },
  getData: async (token, types) => {
    return await API.post(`guide/data`, types, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  addReceptionReport: async (token, data, id) => {
    return await API.put(
      `guide/reception/${id}`,
      {reception: data},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        contentType: true,
      },
    )
  },
  updateState: async (token, data, id) => {
    return await API.put(
      `guide/${id}`,
      {state: data},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        contentType: true,
      },
    )
  },
  update: async (token, data, id) => {
    return await API.put(
      `guide/update/${id}`,
      {data},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        contentType: true,
      },
    )
  },
  reports: async (token, data, id) => {
    return await API.put(
      `guide/reports/${id}`,
      {data},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        contentType: true,
      },
    )
  },
  updatePhotos: async (token, data, id) => {
    return await API.put(`guide/updatePhotos/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  delete: async (id, token) => {
    return await API.delete(`guide/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
}

export default GUIDES_SERVICES
