import React, {useState, useContext, useEffect, useCallback} from 'react'
import {
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiForm,
  EuiFormRow,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiCollapsibleNavGroup,
  EuiFormLabel,
  EuiButtonIcon,
  EuiFieldText,
  EuiProgress,
  EuiTextArea,
  EuiSpacer,
} from '@elastic/eui'
import {useDispatch, useSelector} from 'react-redux'
import {validate} from 'validate.js'
import QUALITY_SERVICES from './../../services/quality'
import GUIDES_SERVICES from './../../services/guides'
import DRYING_SERVICES from './../../services/drying'

import * as actionsUI from '../../store/actions/uiActions'
import {constraintsEs} from './validate'
import FilePicker from './../FilePicker'
import FooterFormReport from './../FooterFormReport'
import {DataContext} from './../../context/DataContext'

const QualityReport = ({guide}) => {
  const {updateGuide, deleteGuide, addGuide} = useContext(DataContext)
  const {quality} = guide
  const dispatch = useDispatch()
  const user = useSelector((store) => store.auth.user)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [totalSample, setTotalSample] = useState('')
  const [errors, setErrors] = useState({})
  const [files, setFiles] = useState([])
  const [showApprovalPanel, setShowApprovalPanel] = useState(false)
  const [reportId, setReportId] = useState('')
  const [takePhoto, setTakePhoto] = useState(false)
  const [s1, setS1] = useState('')
  const [s2, setS2] = useState('')
  const [s3, setS3] = useState('')
  const [s4, setS4] = useState('')
  const [s5, setS5] = useState('')
  const [p1, setP1] = useState('')
  const [p2, setP2] = useState('')
  const [p3, setP3] = useState('')
  const [p4, setP4] = useState('')
  const [p5, setP5] = useState('')
  const [loading, setLoading] = useState(false)
  const [commentary, setCommentary] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calc = useCallback((value) => {
    const calcule = (Number(value) / Number(totalSample)) * 100
    return calcule.toFixed(0)
  })

  useEffect(() => {
    setP1(calc(s1))
    setP2(calc(s2))
    setP3(calc(s3))
    setP4(calc(s4))
    setP5(calc(s5))
  }, [totalSample, s1, calc, s2, s3, s4, s5])

  const handleTakePhoto = () => {
    setTakePhoto(!takePhoto)
  }

  const onChangeS1 = (e) => {
    setS1(Number(e.target.value))
  }

  const onChangeS2 = (e) => {
    setS2(Number(e.target.value))
  }

  const onChangeS3 = (e) => {
    setS3(Number(e.target.value))
  }

  const onChangeS4 = (e) => {
    setS4(Number(e.target.value))
  }

  const onChangeCommentary = (e) => setCommentary(e.target.value)

  const onChangeS5 = (e) => {
    setS5(Number(e.target.value))
  }

  const onChangeTotalSample = (e) => {
    setTotalSample(e.target.value)
  }

  const closeModal = () => {
    setIsModalVisible(false)
    setTakePhoto(false)
    setFiles([])
  }

  const showModal = async () => {
    setIsModalVisible(true)

    if (quality) {
      setReportId(quality._id)
      setTotalSample(quality.totalSample)
      setShowApprovalPanel(true)
      setS1(quality.samples.s1)
      setS2(quality.samples.s2)
      setS3(quality.samples.s3)
      setS4(quality.samples.s4)
      setS5(quality.samples.s5)
      setP1(quality.samples.p1)
      setP2(quality.samples.p2)
      setP3(quality.samples.p3)
      setP4(quality.samples.p4)
      setP5(quality.samples.p5)

      await QUALITY_SERVICES.getPhotos(user.token, quality._id)
        .then((data) => {
          setFiles([].concat.apply([], data.data.photos))
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const approve = async () => {
    setLoading(true)
    await QUALITY_SERVICES.update(user.token, {state: 'sent', approvalDate: new Date(Date.now())}, reportId)
      .then((data) => {
        closeModal()
        setShowApprovalPanel(false)
        setFiles([])
        setReportId(data.data._id)
        dispatch(actionsUI.addAlert({id: '1', title: 'Reporte aprobado', color: 'success', iconType: 'check'}))
      })
      .catch((err) => {
        setLoading(false)
        console.log({err})
      })

    await DRYING_SERVICES.create(user.token, {measurements: []})
      .then((data) => {
        GUIDES_SERVICES.update(user.token, {state: 'drying', drying: data.data._id}, guide._id)
          .then((data) => {
            deleteGuide(data.data)
            addGuide(data.data)
            dispatch(actionsUI.addAlert({id: '1', title: 'Guia enviada a Secado', color: 'success', iconType: 'check'}))
          })
          .catch((err) => {
            setLoading(false)
            console.log({err})
          })
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
    setShowApprovalPanel(false)
    setLoading(false)
  }

  const create = async () => {
    setLoading(true)
    const form = {
      totalSample,
      samples: {s1, p1, s2, p2, s3, p3, s4, p4, s5, p5},
      commentary,
      photos: files,
      user: user.user._id,
      s1,
      s2,
      s3,
      s4,
      s5,
    }

    let validationResult
    validationResult = validate(form, constraintsEs)

    if (validationResult !== undefined) {
      setLoading(false)
      setErrors(validationResult)

      return
    }

    setErrors({})
    if (quality) {
      await QUALITY_SERVICES.update(user.token, form, reportId)
        .then((data) => {
          updateGuide(Object.assign(guide, {quality: data.data}))
          dispatch(actionsUI.addAlert({id: '1', title: 'Reporte actualizado', color: 'success', iconType: 'check'}))
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log({err})
        })
    } else {
      await QUALITY_SERVICES.create(user.token, form)
        .then((data) => {
          setCommentary(data.data.commentary)
          showModal(true)
          setIsModalVisible(true)
          setShowApprovalPanel(true)
          setReportId(data.data._id)
          updateGuide(Object.assign(guide, {quality: data.data}))
          setLoading(false)

          GUIDES_SERVICES.update(user.token, {quality: data.data._id}, guide._id)
            .then((data) => {
              setLoading(false)
            })
            .catch((err) => {
              setLoading(false)
              console.log({err})
            })

          dispatch(
            actionsUI.addAlert({id: '1', title: 'Reporte de calidad ingresado', color: 'success', iconType: 'check'}),
          )
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
          dispatch(
            actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
          )
        })
    }
  }

  const formSample = (
    <EuiForm fullWidth>
      <EuiFlexGroup gutterSize='none'>
        <EuiFlexItem>
          <EuiCollapsibleNavGroup isCollapsible={false} initialIsOpen={true}>
            <>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow
                    fullWidth
                    isInvalid={!errors.totalSample ? false : true}
                    error={!errors.totalSample ? '' : errors.totalSample[0]}
                  >
                    <EuiFieldNumber
                      min='0'
                      prepend={<EuiFormLabel htmlFor='textField19'>Cantidad total nueces</EuiFormLabel>}
                      fullWidth
                      name='totalSample'
                      onChange={(e) => onChangeTotalSample(e)}
                      value={totalSample}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow fullWidth isInvalid={!errors.s1 ? false : true} error={!errors.s1 ? '' : errors.s1[0]}>
                    <EuiFieldNumber
                      min='0'
                      prepend={<EuiFormLabel htmlFor='textField19'>Pelón adherido</EuiFormLabel>}
                      placeholder='N° de nueces'
                      fullWidth
                      name='s1'
                      onChange={(e) => onChangeS1(e)}
                      value={s1}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFormRow>
                    <EuiFieldText readOnly fullWidth placeholder='%' name='p1' value={`${isFinite(p1) ? p1 : ''}%`} />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow fullWidth isInvalid={!errors.s2 ? false : true} error={!errors.s2 ? '' : errors.s2[0]}>
                    <EuiFieldNumber
                      min='0'
                      prepend={<EuiFormLabel htmlFor='textField19'>Daño por insecto</EuiFormLabel>}
                      placeholder='N° de nueces'
                      fullWidth
                      name='s2'
                      onChange={(e) => onChangeS2(e)}
                      value={s2}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFormRow fullWidth>
                    <EuiFieldText readOnly fullWidth placeholder='%' name='p2' value={`${isFinite(p2) ? p2 : ''}%`} />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow fullWidth isInvalid={!errors.s3 ? false : true} error={!errors.s3 ? '' : errors.s3[0]}>
                    <EuiFieldNumber
                      min='0'
                      prepend={<EuiFormLabel htmlFor='textField19'>Hongo en cascara</EuiFormLabel>}
                      placeholder='N° de nueces'
                      fullWidth
                      name='s3'
                      onChange={(e) => onChangeS3(e)}
                      value={s3}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFormRow fullWidth>
                    <EuiFieldText readOnly placeholder='%' name='p3' value={`${isFinite(p3) ? p3 : ''}%`} />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow fullWidth isInvalid={!errors.s4 ? false : true} error={!errors.s4 ? '' : errors.s4[0]}>
                    <EuiFieldNumber
                      min='0'
                      prepend={<EuiFormLabel htmlFor='textField19'>Nueces vanas</EuiFormLabel>}
                      placeholder='N° de nueces'
                      fullWidth
                      name='s4'
                      onChange={(e) => onChangeS4(e)}
                      value={s4}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFormRow fullWidth>
                    <EuiFieldText readOnly placeholder='Porcentaje' name='p4' value={`${isFinite(p4) ? p4 : ''}%`} />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow fullWidth isInvalid={!errors.s5 ? false : true} error={!errors.s5 ? '' : errors.s5[0]}>
                    <EuiFieldNumber
                      min='0'
                      prepend={<EuiFormLabel htmlFor='textField19'>Nueces partidas y trizadas</EuiFormLabel>}
                      placeholder='N° de nueces'
                      fullWidth
                      name='s5'
                      onChange={(e) => onChangeS5(e)}
                      value={s5}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFormRow fullWidth>
                    <EuiFieldText readOnly placeholder='%' name='p5' value={`${isFinite(p5) ? p5 : ''}%`} />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
            <EuiSpacer size='xs' />
            <EuiFormRow fullWidth>
              <EuiTextArea
                placeholder='Observación'
                fullWidth
                compressed
                name='commentary'
                onChange={(e) => onChangeCommentary(e)}
                value={commentary}
              />
            </EuiFormRow>
            <EuiSpacer size='xs' />
            <FilePicker erros={errors} files={files} setFiles={setFiles} handleTakePhoto={handleTakePhoto} />
          </EuiCollapsibleNavGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      <FooterFormReport
        showApprovalPanel={showApprovalPanel}
        {...user}
        approve={approve}
        closeModal={closeModal}
        create={create}
        data={guide.quality}
        loading={loading}
        guide={guide}
      />
    </EuiForm>
  )

  let modal
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal maxWidth={false} onClose={closeModal} initialFocus='[name=popswitch]'>
          {loading ? <EuiProgress size='xs' position='absolute' /> : null}
          <EuiModalHeader>
            <EuiModalHeaderTitle>Informe de Calidad</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>{formSample}</EuiModalBody>

          <EuiModalFooter></EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <>
      {modal}

      <EuiButtonIcon onClick={showModal} iconType='reportingApp' iconSize='l' />
    </>
  )
}

export default QualityReport
