import API from './constants'

var EXPORTERS_SERVICES = {
  getByRut: async (token, rut) => {
    return await API.get(`exporter/rut/${rut}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  createFromPreLoad: async (token, data) => {
    return await API.post(`exporter/preLoad/`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  create: async (token, data) => {
    return await API.post(`exporter/`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  getAll: async (token, types) => {
    return await API.post(
      `exporter/all`,
      {types},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        contentType: true,
      },
    )
  },
  update: async (token, data, id) => {
    return await API.put(`exporter/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
}

export default EXPORTERS_SERVICES
