import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {
  EuiCollapsibleNav,
  EuiCollapsibleNavGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiListGroupItem,
  EuiShowFor,
  EuiListGroup,
} from '@elastic/eui'

const NavBar = ({updateDocked}) => {
  const user = useSelector((store) => store.auth.user)
  const [openGroups, setOpenGroups] = useState([])
  const [navIsOpen, setNavIsOpen] = useState(false)
  const [navIsDocked, setNavIsDocked] = useState(false)

  const toggleAccordion = (isOpen, title) => {
    if (!title) return
    const itExists = openGroups.includes(title)
    if (isOpen) {
      if (itExists) return
      openGroups.push(title)
    } else {
      const index = openGroups.indexOf(title)
      if (index > -1) {
        openGroups.splice(index, 1)
      }
    }
    setOpenGroups([...openGroups])
    localStorage.setItem('openNavGroups', JSON.stringify(openGroups))
  }

  updateDocked(navIsDocked)
  const ConfigurationLinks = [
    {
      label: (
        <Link style={{color: '#006636'}} to='/containers' onClick={() => setNavIsOpen(!navIsOpen)}>
          Envases
        </Link>
      ),
    },
    {
      label: (
        <Link style={{color: '#006636'}} to='/variables' onClick={() => setNavIsOpen(!navIsOpen)}>
          Variables
        </Link>
      ),
    },
  ]
  return (
    <EuiCollapsibleNav
      style={{marginTop: 50, paddingBottom: 50}}
      id='guideCollapsibleNavAllExampleNav'
      aria-label='Main navigation'
      isOpen={navIsOpen}
      isDocked={navIsDocked}
      button={
        <EuiHeaderSectionItemButton aria-label='Toggle main navigation' onClick={() => setNavIsOpen(!navIsOpen)}>
          <EuiIcon type={'menu'} size='m' aria-hidden='true' />
        </EuiHeaderSectionItemButton>
      }
      onClose={() => setNavIsOpen(false)}
    >
      {/* BOTTOM */}
      <EuiFlexItem className='eui-yScroll'>
        {/* Home section */}
        <EuiCollapsibleNavGroup
          style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Link style={{color: '#006636'}} to='/' onClick={() => setNavIsOpen(!navIsOpen)}>
            <EuiCollapsibleNavGroup title='Inicio' iconType='home' isCollapsible={false}></EuiCollapsibleNavGroup>
          </Link>

          <Link style={{color: '#006636'}} to='/reports' onClick={() => setNavIsOpen(!navIsOpen)}>
            <EuiCollapsibleNavGroup title='Reportes' iconType='copy' isCollapsible={false}></EuiCollapsibleNavGroup>
          </Link>
          {user.user.type === 'admin' ? (
            <>
              <Link style={{color: '#006636'}} to='/users' onClick={() => setNavIsOpen(!navIsOpen)}>
                <EuiCollapsibleNavGroup title='Usuarios' iconType='user' isCollapsible={false}></EuiCollapsibleNavGroup>
              </Link>
              <Link style={{color: '#006636'}}>
                <EuiCollapsibleNavGroup
                  title='Configuración'
                  iconType='gear'
                  isCollapsible={true}
                  initialIsOpen={openGroups.includes('Configuration')}
                  onToggle={(isOpen) => toggleAccordion(isOpen, 'Configuration')}
                >
                  <EuiListGroup
                    aria-label='Configuracion'
                    listItems={ConfigurationLinks}
                    //onPinClick={addPin}
                    maxWidth='none'
                    gutterSize='none'
                    size='s'
                  />
                </EuiCollapsibleNavGroup>
              </Link>
            </>
          ) : null}
        </EuiCollapsibleNavGroup>

        {/* Docking button only for larger screens that can support it*/}
        <EuiShowFor sizes={['l', 'xl']}>
          <EuiCollapsibleNavGroup>
            <EuiListGroupItem
              size='xs'
              color='subdued'
              label={`${navIsDocked ? 'Desbloquear' : 'Bloquear'} navegación`}
              onClick={() => {
                setNavIsDocked(!navIsDocked)
                localStorage.setItem('navIsDocked', JSON.stringify(!navIsDocked))
              }}
              iconType={navIsDocked ? 'lock' : 'lockOpen'}
            />
          </EuiCollapsibleNavGroup>
        </EuiShowFor>
      </EuiFlexItem>
    </EuiCollapsibleNav>
  )
}

export default NavBar
