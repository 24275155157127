export const UI_TYPES = {
    UI_ADDALERT: '[UI ALERT] ui add alert',
    UI_REMOVEALERT: '[UI ALERT] ui del alert',
}

export const addAlert = (alert) => ({
    type: UI_TYPES.UI_ADDALERT,
    ...alert
});

export const removeAlert = (alert) => ({
    type: UI_TYPES.UI_REMOVEALERT,
    ...alert
});