import React, {useState, useEffect} from 'react'
import {EuiBadge, EuiLoadingSpinner} from '@elastic/eui'
import moment from 'moment'
import 'moment-duration-format'
import momenttz from 'moment-timezone'

const Timer = ({date}) => {
  const [seconds, setSeconds] = useState(0)
  const [isActive] = useState(true)

  /* function toggle() {
    setIsActive(!isActive)
    moment(momenttz(createdAt).tz('America/Santiago')).format('DD-MM-YYYY HH:mm')
  }

  function pause() {
    setIsActive(false)
  }
*/
  useEffect(() => {
    let interval = null
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(
          moment(momenttz(date).tz('America/Santiago')).diff(momenttz().tz('America/Santiago'), 'seconds') * -1,
        )
      }, 1000)
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [date, isActive, seconds])

  return (
    <EuiBadge style={{width: 72, backgroundColor: 'rgba(125, 226, 209, 0.3)'}} color={'secondary'}>
      {seconds === 0 ? <EuiLoadingSpinner size='s' /> : moment.duration(seconds, 'seconds').format('HH : mm : ss')}
    </EuiBadge>
  )
}

export default Timer
