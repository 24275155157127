import React, {useState, useContext} from 'react'
import {EuiButton, EuiFormRow, EuiFieldPassword, EuiPopover, EuiSpacer, EuiForm} from '@elastic/eui'
import {DataContext} from './../../context/DataContext'
import AUTH_SERVICES from './../../services/auth'

const ApproveButton = ({approve, size, text}) => {
  const {user, dispatch, actionsUI} = useContext(DataContext)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen)
  const closePopover = () => setIsPopoverOpen(false)
  const [value, setValue] = useState('')
  const [isPassword, setIsPassword] = useState(true)
  const [disable, setDisable] = useState(false)

  const validateUser = async () => {
    setDisable(true)
    if (!value) {
      dispatch(actionsUI.addAlert({id: 1, title: 'Debe ingresar su contraseña', color: 'danger', iconType: 'alert'}))
      setDisable(false)
      return null
    }

    AUTH_SERVICES.approve(user.token, {password: value, email: user.user.email})
      .then((data) => {
        setIsPassword(data.data)
        setDisable(false)
        if (data.data) approve()
      })
      .catch((error) => {
        console.log({error})
        setDisable(false)
        dispatch(actionsUI.addAlert({id: 1, title: error, color: 'danger', iconType: 'alert'}))
      })
  }

  return (
    <>
      <EuiPopover
        button={
          <EuiButton size={size ? size : 'm'} iconType={'check'} color='secondary' onClick={onButtonClick} fill>
            {text ? text : 'Aprobar'}
          </EuiButton>
        }
        anchorPosition='upCenter'
        isOpen={isPopoverOpen}
        closePopover={closePopover}
        initialFocus='[id=pw]'
        ownFocus={true}
      >
        <EuiForm>
          <EuiFormRow
            label='Para aprobar debe ingresar su contraseña'
            id='pw'
            isInvalid={!isPassword}
            error={'Contraseña incorrecta'}
          >
            <EuiFieldPassword placeholder='Contraseña' value={value} onChange={(e) => setValue(e.target.value)} />
          </EuiFormRow>
          <EuiSpacer />
          <EuiButton color='secondary' fullWidth size='s' fill isDisabled={disable} onClick={() => validateUser()}>
            Enviar
          </EuiButton>
        </EuiForm>
      </EuiPopover>
    </>
  )
}

export default ApproveButton
