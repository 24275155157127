export const constraintsEs = {
  selectedContainer: {
    presence: {
      allowEmpty: false,
      message: '^ Seleccione envase',
    },
  },
  selectedTare: {
    presence: {
      allowEmpty: false,
      message: '^ Indique tara',
    },
  },
  numberContainer: {
    numericality: {
      greaterThan: 0,
      notGreaterThan: 'debe ser mayor a 0',
      message: '^ Ingrese cantidad de envases',
    },
  },
  grossWeight: {
    numericality: {
      greaterThan: 0,
      notGreaterThan: 'debe ser mayor a 0',
      message: '^ Ingrese peso bruto',
    },
  },
}
