import React, {useState, useEffect, useCallback} from 'react'
import GUIDES_SERVICES from './../services/guides'
import VARIABLES_SERVICES from './../services/variables'
import PRODUCERS_SERVICES from './../services/producers'
import EXPORTERS_SERVICES from './../services/exporters'
import {useSelector, useDispatch} from 'react-redux'
import * as actionsUI from '../store/actions/uiActions'
import debounce from 'lodash.debounce'

export const DataContext = React.createContext()

const DataContextProvider = (props) => {
  const dispatch = useDispatch()

  const [time, setTime] = useState(0)
  const [lot, setLot] = useState(0)
  const [humidity, setHumidity] = useState(0)
  const [exitGuideSistem, setExitGuideSistem] = useState(0)
  const [guidesPreLoad, setGuidesPreLoad] = useState([])
  const [guidesLoad, setGuidesLoad] = useState([])
  const [guidesDrying, setGuidesDrying] = useState([])
  const [guidesQuality, setGuidesQuality] = useState([])
  const [guidesDispatch, setGuidesDispatch] = useState([])
  const [guidesAttended, setGuidesAttended] = useState([])
  const [comboProducers, setComboProducers] = useState([])
  const [comboExporters, setComboExporters] = useState([])
  const [loading, setLoading] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const user = useSelector((store) => store.auth.user)

  useEffect(() => {
    getGuides()
    getVariables()
    getProducers()
    getExporters()
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteGuide = (guide) => {
    const {_id} = guide
    setGuidesPreLoad(guidesPreLoad.filter((doc) => doc._id !== _id))
    setGuidesLoad(guidesLoad.filter((doc) => doc._id !== _id))
    setGuidesQuality(guidesQuality.filter((doc) => doc._id !== _id))
    setGuidesDrying(guidesDrying.filter((doc) => doc._id !== _id))
    setGuidesDispatch(guidesDispatch.filter((doc) => doc._id !== _id))
    setGuidesAttended(guidesAttended.filter((doc) => doc._id !== _id))
  }

  const addGuide = (guide) => {
    const {state} = guide
    if (state === 'preload') setGuidesPreLoad(guidesPreLoad.concat(guide))
    if (state === 'load') setGuidesLoad(guidesLoad.concat(guide))
    if (state === 'quality') setGuidesQuality(guidesQuality.concat(guide))
    if (state === 'drying') setGuidesDrying(guidesDrying.concat(guide))
    if (state === 'dispatch') setGuidesDispatch(guidesDispatch.concat(guide))
    if (state === 'attended') setGuidesAttended(guidesAttended.concat(guide))
  }

  const updateGuide = (guide) => {
    if (guide.state === 'preload') {
      const i = guidesPreLoad.findIndex((obj) => obj._id === guide._id)
      guidesPreLoad[i] = guide
    } else if (guide.state === 'load') {
      const i = guidesLoad.findIndex((obj) => obj._id === guide._id)
      guidesLoad[i] = guide
    } else if (guide.state === 'quality') {
      const i = guidesQuality.findIndex((obj) => obj._id === guide._id)
      guidesQuality[i] = guide
    } else if (guide.state === 'drying') {
      const i = guidesDrying.findIndex((obj) => obj._id === guide._id)
      guidesDrying[i] = guide
    } else if (guide.state === 'dispatch') {
      const i = guidesDispatch.findIndex((obj) => obj._id === guide._id)
      guidesDispatch[i] = guide
    } else if (guide.state === 'attended') {
      const i = guidesAttended.findIndex((obj) => obj._id === guide._id)
      guidesAttended[i] = guide
    }
  }

  const getGuides = async (filterProducer, number, startDate, endDate, filterExporter) => {
    setLoading(true)
    const producerId = filterProducer ? filterProducer[0]?.id : ''
    const exporterId = filterExporter ? filterExporter[0]?.id : ''

    await GUIDES_SERVICES.getAll(
      user.token,
      {types: ['admin', 'user', 'operator'], user: user.user, home: true},
      exporterId ? exporterId : '',
      producerId ? producerId : '',
      number,
      '',
      startDate ? startDate : '',
      endDate ? endDate : '',
      '',
      '',
      '',
      '',
    )
      .then((data) => {
        setGuidesLoad(
          data.data.docs.filter(
            (doc) =>
              (doc.state === 'load' && doc.reception === undefined) ||
              (doc.state === 'load' && doc.reception && doc.reception.state === 'draft'),
          ),
        )
        setGuidesPreLoad(data.data.docs.filter((doc) => doc.state === 'preload'))
        setGuidesQuality(data.data.docs.filter((doc) => doc.state === 'quality'))
        setGuidesDrying(data.data.docs.filter((doc) => doc.state === 'drying'))
        setGuidesDispatch(data.data.docs.filter((doc) => doc.state === 'dispatch' || doc.state === 'attended'))
        setGuidesAttended(data.data.docs.filter((doc) => doc.state === 'attended'))
        
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const getVariables = async () => {
    await VARIABLES_SERVICES.getAll(user.token, user.user.type, '')
      .then((data) => {
        data.data.map((v) =>
          v.name === 'Lote'
            ? setLot(v.value)
            : v.name === 'Tiempo de secado'
            ? setTime(v.value)
            : v.name === 'Humedad'
            ? setHumidity(v.value)
            : v.name === 'Guía salida'
            ? setExitGuideSistem(v.value)
            : null,
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getProducers = async () => {
    if (user.user.type === 'user' && user.user.isExporter){
      await GUIDES_SERVICES.getProducersByExporter(user.token, {rut: user.user.rut})
      .then((data) => {
      let dataProducers = data.data.map((c) =>
      c.name ? {id: c._id, label: c.name, email: c.email, emails: c.emails} : null,
    )
    setComboProducers(dataProducers)
      })
    } else {
      await PRODUCERS_SERVICES.getAll(user.token, ['admin', 'user', 'operator'])
      .then((data) => {
        if (data) {
          let dataProducers = data.data.docs.map((c) =>
            c.name ? {id: c._id, label: c.name, email: c.email, emails: c.emails} : null,
          )
          setComboProducers(dataProducers)
        }
      })
      .catch((error) => {
        dispatch(actionsUI.addAlert({id: 1, title: error, color: 'danger', iconType: 'alert'}))
      })
    }
  }

  const getExporters = async () => {
    await EXPORTERS_SERVICES.getAll(user.token, ['admin', 'user', 'operator'])
      .then((data) => {
        if (data) {
          const dataExporters = data.data.docs.map((c) =>
            c.name ? {id: c._id, label: c.name, email: c.email, emails: c.emails} : null,
          )
          setComboExporters(dataExporters)
        }
      })
      .catch((error) => {
        dispatch(actionsUI.addAlert({id: 1, title: error, color: 'danger', iconType: 'alert'}))
      })
  }

  const useDebounce = (callback, delay) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFn = useCallback(
      debounce((...args) => callback(...args), delay),
      [delay],
    )
    return debouncedFn
  }

  const formatNumber = (number) => {
    return new Intl.NumberFormat('de-DE').format(number)
  }

  return (
    <DataContext.Provider
      value={{
        time,
        guidesPreLoad,
        guidesLoad,
        guidesQuality,
        guidesDrying,
        guidesDispatch,
        guidesAttended,
        comboProducers,
        comboExporters,
        user,
        lot,
        humidity,
        exitGuideSistem,
        deleteGuide,
        addGuide,
        updateGuide,
        dispatch,
        getGuides,
        getVariables,
        useDebounce,
        formatNumber,
        actionsUI,
        loading,
        isMobile,
      }}
    >
      {props.children}
    </DataContext.Provider>
  )
}

export default DataContextProvider
