import React, {useState, useContext} from 'react'
import {EuiButtonIcon, EuiOverlayMask, EuiConfirmModal} from '@elastic/eui'
import GUIDES_SERVICES from './../../services/guides'
import {useDispatch, useSelector} from 'react-redux'
import * as actionsUI from '../../store/actions/uiActions'
import {DataContext} from './../../context/DataContext'

const DeleteGuide = ({guide}) => {
  const {deleteGuide} = useContext(DataContext)
  const {_id, number} = guide
  const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false)
  const token = useSelector((store) => store.auth.user.token)
  const dispatch = useDispatch()

  const closeDestroyModal = () => setIsDestroyModalVisible(false)
  const showDestroyModal = () => setIsDestroyModalVisible(true)

  let destroyModal

  if (isDestroyModalVisible) {
    destroyModal = (
      <EuiOverlayMask>
        <EuiConfirmModal
          title={`Eliminación guía #${number}`}
          onCancel={closeDestroyModal}
          onConfirm={() => deleteG()}
          cancelButtonText='Cancelar'
          confirmButtonText='Eliminar'
          buttonColor='danger'
          defaultFocusedButton='confirm'
        >
          <p>Esta seguro ?</p>
        </EuiConfirmModal>
      </EuiOverlayMask>
    )
  }

  const deleteG = async () => {
    await GUIDES_SERVICES.delete(_id, token)
      .then((data) => {
        deleteGuide(data.data)
        dispatch(actionsUI.addAlert({id: '1', title: 'Guia eliminada', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
    closeDestroyModal()
  }

  return (
    <>
      <EuiButtonIcon iconType='cross' onClick={showDestroyModal} />
      {destroyModal}
    </>
  )
}

export default DeleteGuide
