import React, {useState, useEffect, useContext} from 'react'
import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiLoadingSpinner,
  EuiForm,
  EuiFieldSearch,
  EuiFormControlLayout,
  EuiFieldNumber,
} from '@elastic/eui'
import VARIABLES_SERVICES from '../../../services/variables'
import {DataContext} from './../../../context/DataContext'

const Variable = () => {
  const {dispatch, user, actionsUI, getVariables} = useContext(DataContext)
  const [maskOpen, setMaskOpen] = useState(false)
  //const [confirm, setConfirm] = useState(false)
  const [modal, setModal] = useState(false)
  const [action, setAction] = useState('')
  const [errors, setErrors] = useState({})
  const [name, setName] = useState('')
  const [value, setValue] = useState('')
  const [pageOfItems, setPageOfItems] = useState([])
  const [customHeader] = useState(true)
  //const [isResponsive] = useState(true)
  const [pageIndex, setPageIndex] = useState(0)
  const [sortField, setSortField] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')
  const [pageSize, setPageSize] = useState(10)
  const [item, setItem] = useState({})
  const [loadOther, setloadOther] = useState(false)
  const [actionsEvent] = useState(false)
  const [searchName, setSearchName] = useState('')

  useEffect(() => {
    setMaskOpen(true)
    getVariable('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, actionsEvent])

  useEffect(() => {
    setMaskOpen(true)
    getVariable('*')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, loadOther])

  const getVariable = async () => {
    await VARIABLES_SERVICES.getAll(user.token, user.user.type, searchName)
      .then((data) => {
        const datos = data.data
        setPageOfItems(datos)
        setMaskOpen(false)
      })
      .catch((error) => {
        setMaskOpen(false)
        dispatch(actionsUI.addAlert({id: 1, title: error.response, color: 'danger', iconType: 'alert'}))
      })
  }

  const modalLoading = (
    <>
      <EuiOverlayMask>
        <EuiLoadingSpinner size='xl' />
      </EuiOverlayMask>
    </>
  )

  const closeModal = () => {
    setName('')
    setValue('')
    setModal(false)
  }

  const onChangeName = (e) => {
    setName(e.target.value)
  }
  const onChangeValue = (e) => {
    setValue(e.target.value)
  }

  const save = async () => {
    const form = {
      name,
      value: Number(value),
    }
    setErrors({})
    setMaskOpen(true)

    if (action === 'Add') {
      await VARIABLES_SERVICES.create(user.token, form)
        .then((data) => {
          setloadOther(!loadOther)
          dispatch(actionsUI.addAlert({id: '1', title: 'Envase ingresado', color: 'success', iconType: 'check'}))
          closeModal()
        })
        .catch((error) => {
          dispatch(
            actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
          )
        })
    } else {
      VARIABLES_SERVICES.update(user.token, form, item._id)
        .then((data) => {
          setPageOfItems(pageOfItems.map((item) => (item._id === data.data._id ? {...data.data} : item)))
          dispatch(actionsUI.addAlert({id: '1', title: 'Variable actualizada', color: 'success', iconType: 'check'}))
          setMaskOpen(false)
          closeModal()
          getVariables()
        })
        .catch((error) => {
          setMaskOpen(false)
          dispatch(
            actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
          )
        })
    }
  }
  const editVariable = async (variable) => {
    setMaskOpen(true)
    setItem(variable)
    await VARIABLES_SERVICES.getOne(user.token, variable._id)
      .then((data) => {
        const record = data.data
        setName(record.name)
        setValue(record.value)
        setAction('Update')
        setModal(true)
        setMaskOpen(false)
      })
      .catch((error) => {
        setMaskOpen(false)
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const actions = [
    {
      name: 'Edit',
      description: 'Editar',
      icon: 'documentEdit',
      type: 'icon',
      onClick: editVariable,
    },
  ]
  const onTableChange = ({page = {}, sort = {}}) => {
    const {index: pageIndex, size: pageSize} = page
    const {field: sortField, direction: sortDirection} = sort
    setPageIndex(pageIndex)
    setPageSize(pageSize)
    setSortField(sortField)
    setSortDirection(sortDirection)
  }

  const columns = [
    {
      field: 'name',
      name: 'Nombre',
      truncateText: true,
      sortable: true,
      mobileOptions: {
        render: customHeader ? (item) => <span>{item.name}</span> : undefined,
        header: customHeader ? false : true,
        fullWidth: customHeader ? true : false,
        enlarge: customHeader ? true : false,
        truncateText: customHeader ? false : true,
      },
    },
    {
      field: 'value',
      name: 'Valor',
      truncateText: true,
      mobileOptions: {
        show: true,
      },
    },
    {
      name: 'Acciones',
      actions,
    },
  ]

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  }

  const onChangeSearchName = (e) => {
    setSearchName(e.target.value)
  }

  const onSearchName = (value) => {
    setSearchName(value)
    setloadOther(!loadOther)
  }

  return (
    <>
      {maskOpen ? modalLoading : undefined}

      {modal && (
        <EuiOverlayMask>
          <EuiModal onClose={closeModal} initialFocus='[name=popswitch]' style={{width: 400}}>
            <EuiModalHeader>
              <EuiModalHeaderTitle></EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody style={{height: 300}}>
              <EuiForm>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      label='Nombre'
                      fullWidth
                      isInvalid={errors.name === undefined ? false : true}
                      error={errors.name === undefined ? '' : errors.name[0]}
                    >
                      <EuiFieldText disabled fullWidth name='name' onChange={(e) => onChangeName(e)} value={name} />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>

                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      label='Valor'
                      fullWidth
                      isInvalid={errors.value === undefined ? false : true}
                      error={errors.value === undefined ? '' : errors.value[0]}
                    >
                      <EuiFieldNumber min='0' fullWidth name='value' onChange={(e) => onChangeValue(e)} value={value} />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiForm>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButtonEmpty onClick={closeModal}>Cancelar</EuiButtonEmpty>
              <EuiButton onClick={save} fill>
                Guardar
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      )}
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiFormControlLayout>
            <EuiFieldSearch
              placeholder='Ingrese nombre'
              value={searchName}
              isClearable={true}
              onChange={onChangeSearchName}
              onSearch={onSearchName}
            />
          </EuiFormControlLayout>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          <>
            <EuiBasicTable
              items={pageOfItems}
              itemId='id'
              columns={columns}
              //pagination={pagination}
              sorting={sorting}
              isSelectable={true}
              hasActions={true}
              responsive={true}
              onChange={onTableChange}
            />
            <EuiSpacer size='m' />
          </>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

export default Variable
