import React from 'react'
import {Text, View, Image, StyleSheet, Page} from '@react-pdf/renderer'
import moment from 'moment'
import momenttz from 'moment-timezone'
import TruckImg from './../../../assets/images/truck-top.png'
import LogoImg from './../../../assets/images/logo-green.png'

const OneTrip = ({data, tripId}) => {
  const {number, dispatch, drying, producer, exporter, products} = data
  const trips = dispatch.trip
  const dryingContainer = [].concat.apply([], drying.containers)
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    row: {
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 8,
      marginLeft: 50,
      marginBottom: 18,
    },
    rowObs: {
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 8,
      marginLeft: 50,
      marginBottom: 10,
    },
    rowLine: {
      width: '100%',
      borderBottomWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      marginBottom: 18,
    },
    rowLineLoad: {
      width: '100%',
      borderBottomWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      marginBottom: 18,
      paddingTop: 15,
    },
    image: {
      marginVertical: 25,
      marginHorizontal: 225,
    },
    truckImage: {
      marginLeft: 25,
      width: '90%',
      opacity: '0.6',
    },
    photos: {
      marginTop: 10,
      marginHorizontal: 100,
    },
    title: {
      textAlign: 'center',
      fontWeight: 'bold',
      borderWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      marginBottom: 20,
      fontSize: 14,
      marginHorizontal: 35,
    },
    text: {
      margin: 3,
    },

    lot: {
      borderBottomWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      fontWeight: 'bold',
    },
    total: {
      borderBottomWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      paddingTop: 20,
    },
    colA: {
      width: '17%',
      fontFamily: 'Helvetica-Bold',
    },
    coltotal: {
      width: '17%',
      fontFamily: 'Helvetica-Bold',
      borderTopWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
    },
    colATrip: {
      width: '15%',
      fontFamily: 'Helvetica-Bold',
    },
    colB: {
      width: '30%',
      marginBottom: 5,
    },
    colBTrip: {
      width: '15%',
      marginBottom: 5,
    },
    colC: {
      width: '20%',
      paddingLeft: 40,
    },
    rowLoadTruck: {
      marginTop: -46,
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 8,
      marginLeft: 38,
    },
    rowLoad: {
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 8,
      marginLeft: 50,
    },
    load: {
      borderWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      width: 24,
      height: 24,
    },
    code: {
      marginTop: 7,
      marginLeft: 3,
    },
    colContainer: {
      borderTopWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      width: '17%',
    },

    rowContainer: {
      marginLeft: 50,
      marginTop: 50,
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 8,
    },
  })

  const formatNumber = (number) => {
    return new Intl.NumberFormat('de-DE').format(number)
  }

  let totalweight = 0
  let totalNetTeight = 0
  let totalTare = 0

  return (
    <>
      {
        // eslint-disable-next-line array-callback-return
        trips.map((trip) => {
          if (trip._id === tripId) {
            trip.load.sort((a, b) => a.seat - b.seat)
            const load = trip.load.reverse()

            const guides = trip.guides.find((guide) => {
              return guide.entryGuide === number
            })

            if (guides?.exitGuide !== undefined) {
              return (
                <Page>
                  <Image style={styles.image} src={LogoImg} />
                  <Text style={styles.title}>Informe de carga</Text>
                  <View style={styles.container}>
                    <View style={styles.row}>
                      <View style={styles.colA}>
                        <Text>Exportadora</Text>
                      </View>
                      <View style={styles.colB}>
                        <Text>{exporter?.name ? exporter.name : '-'}</Text>
                      </View>
                      <View style={styles.colA}>
                        <Text>N° Guia origen:</Text>
                      </View>
                      <View style={styles.colB}>
                        <Text>{number}</Text>
                      </View>
                    </View>

                    <View style={styles.row}>
                      <View style={styles.colA}>
                        <Text>Productor:</Text>
                      </View>
                      <View style={styles.colB}>
                        <Text>{producer.name}</Text>
                      </View>
                      <View style={styles.colA}>
                        <Text>N° Guía Ulmen:</Text>
                      </View>
                      <View style={styles.colB}>
                        <Text>{guides?.exitGuide}</Text>
                      </View>
                    </View>

                    <View style={styles.row}>
                      <View style={styles.colA}>
                        <Text>Variedad:</Text>
                      </View>
                      <View style={styles.colB}>
                        <Text>{products.name}</Text>
                      </View>
                      <View style={styles.colA}>
                        <Text>Fecha de despacho:</Text>
                      </View>
                      <View style={styles.colB}>
                        <Text>
                          {moment(momenttz(trip.updatedAt).tz('America/Santiago')).format('DD-MM-YYYY HH:mm')}
                        </Text>
                      </View>
                    </View>

                    <View style={styles.row}>
                      <View style={styles.colA}>
                        <Text>Patente:</Text>
                      </View>
                      <View style={styles.colBTrip}>
                        <Text> {trip.patent}</Text>
                      </View>
                    </View>
                    <View style={styles.rowObs}>
                      <View style={styles.colA}>
                        <Text>Observación:</Text>
                      </View>
                    </View>

                    <View style={styles.rowObs}>
                      <View>
                        <Text>{guides.commentary}</Text>
                      </View>
                    </View>
                    <Image style={styles.truckImage} src={TruckImg} />
                    <View style={styles.rowLoadTruck}>
                      {load.map((load) =>
                        load.seat % 2 === 0 ? (
                          <View style={styles.load}>
                            <Text style={styles.code}>
                              {guides && guides.exitGuide === load.exitGuide ? load.code : ''}
                            </Text>
                          </View>
                        ) : null,
                      )}
                    </View>

                    <View style={styles.rowLoadTruck}>
                      {load.map((load) =>
                        load.seat % 2 !== 0 ? (
                          <View style={styles.load}>
                            <Text style={styles.code}>
                              {guides && guides.exitGuide === load.exitGuide ? load.code : ''}
                            </Text>
                          </View>
                        ) : null,
                      )}
                    </View>
                    <View style={styles.rowContainer}>
                      <View style={styles.colA}>
                        <Text style={styles.text}>Código envase</Text>
                      </View>
                      <View style={styles.colA}>
                        <Text style={styles.text}>Tipo</Text>
                      </View>
                      <View style={styles.colA}>
                        <Text style={styles.text}>Peso bruto</Text>
                      </View>
                      <View style={styles.colA}>
                        <Text style={styles.text}>Tara</Text>
                      </View>
                      <View style={styles.colA}>
                        <Text style={styles.text}>Peso Neto</Text>
                      </View>
                    </View>

                    {load.map((load) => {
                      // eslint-disable-next-line array-callback-return
                      return dryingContainer.map((c) => {
                        if (load.code === c.code) {
                          totalweight = totalweight + c.weightWithContainer
                          totalNetTeight = totalNetTeight + c.weightWithContainer - c.tare
                          totalTare = totalTare + c.tare
                          return (
                            <>
                              <View style={styles.rowLoad}>
                                <View style={styles.colContainer}>
                                  <Text style={styles.text}>{c.code}</Text>
                                </View>
                                <View style={styles.colContainer}>
                                  <Text style={styles.text}>{c.container[0].type}</Text>
                                </View>
                                <View style={styles.colContainer}>
                                  <Text style={styles.text}>{formatNumber(c.weightWithContainer)}</Text>
                                </View>
                                <View style={styles.colContainer}>
                                  <Text style={styles.text}>{formatNumber(c.tare)}</Text>
                                </View>
                                <View style={styles.colContainer}>
                                  <Text style={styles.text}>{formatNumber(c.weightWithContainer - c.tare)}</Text>
                                </View>
                              </View>
                            </>
                          )
                        }
                      })
                    })}
                    <View style={styles.rowLoad}>
                      <View style={styles.coltotal}>
                        <Text style={styles.text}>TOTAL</Text>
                      </View>
                      <View style={styles.coltotal}>
                        <Text style={styles.text}></Text>
                      </View>
                      <View style={styles.coltotal}>
                        <Text style={styles.text}>{formatNumber(totalweight)}</Text>
                      </View>
                      <View style={styles.coltotal}>
                        <Text style={styles.text}>{formatNumber(totalTare)}</Text>
                      </View>
                      <View style={styles.coltotal}>
                        <Text style={styles.text}>{formatNumber(totalNetTeight)}</Text>
                      </View>
                    </View>
                  </View>
                </Page>
              )
            }
          }
        })
      }
    </>
  )
}

export default OneTrip
