import React, {useState, useContext, useEffect} from 'react'
import {
  EuiFlexItem,
  EuiCard,
  EuiFlexGroup,
  EuiHorizontalRule,
  EuiBadge,
  EuiButtonEmpty,
  EuiModalBody,
  EuiOverlayMask,
  EuiStat,
  EuiBeacon,
  EuiButtonIcon,
  EuiLoadingContent,
  EuiIcon,
  EuiText,
  EuiSpacer,
} from '@elastic/eui'
import './index.css'
import Timer from './../Timer'
import QualityReport from './../QualityReport'
import DryingReport from './../DryingReport'
import Measurements from './../Measurements'
import Boxes from './../Boxes'
import DispatchForm from '../DispatchForm'
import AlertBeacon from './../AlertBeacon'
import AlertHumidity from './../AlertHumidity'
import {DataContext} from './../../context/DataContext'
import DryingContainers from '../DryingContainers'
import ShowGuidePhoto from './../ShowGuidePhoto'
import Pdf from './../Pdf'
import GUIDES_SERVICES from './../../services/guides'
import QUALITY_SERVICES from './../../services/quality'

const GuideCard = ({guide}) => {
  const {drying, exporter, producer, state, quality, isGuidePhoto, dispatch} = guide
  const {user, isMobile, loading} = useContext(DataContext)
  const [maskOpen, changeMask] = useState(false)
  const [showPdf, setshowPdf] = useState('')
  const [alertHumidity, setAlertHumidity] = useState(false)
  const [guidePhotos, setGuidePhotos] = useState([])
  const [qualityPhotos, setQualityPhotos] = useState([])
  const [loadingPhoto, setLoadingPhoto] = useState(false)
  const [notLoad, setNotLoad] = useState(0)

  const handleAlertHumidity = (alert) => {
    setAlertHumidity(alert)
  }

  const openPdf = (type) => {
    if (type === 'user') {
      getPhotosUser(type)
    } else if (isGuidePhoto && type === 'reception') {
      getPhotos(type)
    } else if (type === 'quality') {
      getQualityPhotos(type)
    } else {
      console.log('no')
      changeMask(true)
      setshowPdf(type)
    }
  }

  useEffect(() => {
    if (dispatch) {
      const containers = [].concat.apply([], drying.containers)
      const notload = containers.filter((container) => container.stateLoad === false)

      setNotLoad(notload.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPhotos = async (type) => {
    setLoadingPhoto(true)
    await GUIDES_SERVICES.getPhotos(user.token, guide.id)
      .then((data) => {
        setGuidePhotos(data.data.guidePhoto)
        changeMask(true)
        setshowPdf(type)
        setLoadingPhoto(false)
      })
      .catch((err) => {
        console.log(err)
        setLoadingPhoto(false)
      })
  }

  const getQualityPhotos = async (type) => {
    setLoadingPhoto(true)
    await QUALITY_SERVICES.getPhotos(user.token, quality._id)
      .then((data) => {
        setQualityPhotos(data.data.photos)
        changeMask(true)
        setshowPdf(type)
        setLoadingPhoto(false)
      })
      .catch((err) => {
        console.log(err)
        setLoadingPhoto(false)
      })
  }

  const getPhotosUser = async (type) => {
    setLoadingPhoto(true)
    if (state === 'quality') {
      getPhotos(type)
    } else {
      await QUALITY_SERVICES.getPhotos(user.token, quality._id)
        .then((data) => {
          setQualityPhotos(data.data.photos)
          getPhotos(type)
        })
        .catch((err) => {
          console.log(err)
          setLoadingPhoto(false)
        })
    }
  }

  const modal = (
    <React.Fragment>
      <EuiOverlayMask
        onClick={() => {
          changeMask(false)
        }}
      >
        <EuiModalBody
          style={isMobile ? {position: 'fixed', overflow: 'scroll', height: '90vh', marginTop: 30} : {margin: 'auto'}}
        >
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon onClick={() => changeMask(false)} iconType='cross' iconSize='xl' color='danger' />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='s' />
          <EuiFlexGroup>
            <EuiFlexItem>
              <Pdf guide={guide} type={showPdf} guidePhotos={guidePhotos} qualityPhotos={qualityPhotos} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalBody>
      </EuiOverlayMask>
    </React.Fragment>
  )

  return (
    <>
      {maskOpen ? modal : undefined}
      <EuiCard
        style={{minWidth: 220}}
        textAlign='left'
        display='plain'
        description={
          <>
            <EuiStat title={exporter?.name ? exporter.name : '-'} description='Exportador' titleSize='s' />
            <EuiStat title={producer.name} description='Productor' titleSize='s' />

            {loadingPhoto ? (
              <EuiLoadingContent lines={1} />
            ) : user.user.type === 'user' ? (
              <EuiButtonEmpty
                style={{color: '#006636'}}
                size='xs'
                onClick={() => {
                  openPdf('user')
                }}
                iconType='documents'
              >
                Reporte
              </EuiButtonEmpty>
            ) : (
              <>
                {!loading ? (
                  <EuiButtonEmpty
                    style={{color: '#006636'}}
                    size='xs'
                    onClick={() => {
                      openPdf('reception')
                    }}
                    iconType='documents'
                  >
                    Recepción
                  </EuiButtonEmpty>
                ) : (
                  <EuiLoadingContent lines={1} />
                )}

                {state !== 'quality' && !loading ? (
                  <EuiButtonEmpty
                    style={{color: '#006636'}}
                    size='xs'
                    onClick={() => {
                      openPdf('quality')
                    }}
                    iconType='documents'
                  >
                    Calidad
                  </EuiButtonEmpty>
                ) : null}
                {(state === 'dispatch' && !loading) || (state === 'attended' && !loading) ? (
                  <EuiButtonEmpty
                    style={{color: '#006636'}}
                    size='xs'
                    onClick={() => {
                      openPdf('dispatch')
                    }}
                    iconType='documents'
                  >
                    Secado
                  </EuiButtonEmpty>
                ) : null}

                {state === 'attended' && !loading ? (
                  <EuiButtonEmpty
                    style={{color: '#006636'}}
                    size='xs'
                    onClick={() => {
                      openPdf('attended')
                    }}
                    iconType='documents'
                  >
                    Despacho
                  </EuiButtonEmpty>
                ) : null}
              </>
            )}
          </>
        }
        title={
          <EuiFlexGroup gutterSize='none'>
            {user.user.type === 'user' ? null : (
              <>
                <EuiFlexItem grow={1}>
                  <Timer date={guide.createdAt} />
                </EuiFlexItem>
                <EuiFlexItem grow={1}></EuiFlexItem>
              </>
            )}

            <EuiFlexItem grow={1}>
              <EuiBadge color='rgba(252, 247, 188, 0.3)'>
                Guía<b>{` #${guide.number}`}</b>
              </EuiBadge>
            </EuiFlexItem>
            <EuiFlexItem grow={1}></EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiBadge color='rgba(228, 166, 199, 0.3)'>
                Lote <b>{` #${guide.lot}`}</b>
              </EuiBadge>
            </EuiFlexItem>
            <EuiFlexItem grow={6}></EuiFlexItem>

            <EuiFlexItem grow={1}>
              {quality?.state === 'draft' ||
              (drying?.state === 'draft' && drying?.containers.length > 0 && user.user.type !== 'user') ? (
                <EuiBeacon style={{backgroundColor: 'red', marginLeft: 5, marginTop: 4}} />
              ) : null}
            </EuiFlexItem>
          </EuiFlexGroup>
        }
        footer={
          <span>
            {user.user.type === 'user' ? null : (
              <>
                {loading ? (
                  <EuiLoadingContent lines={1} />
                ) : (
                  <>
                    <EuiHorizontalRule margin='xs' style={{backgroundColor: '#006636'}} />
                    <EuiFlexGroup gutterSize='none' responsive={false} alignItems='center'>
                      {state === 'dispatch' || state === 'attended' ? (
                        <>
                          <EuiFlexItem grow={1}>
                            <DryingContainers guide={guide} />
                          </EuiFlexItem>

                          <EuiFlexItem grow={false}>
                            <EuiIcon type='analyzeEvent' color={notLoad === 0 ? 'secondary' : 'danger'} size='l' />
                          </EuiFlexItem>
                          <EuiFlexItem grow={1}>
                            <EuiText style={{fontSize: 18, paddingLeft: 3, fontWeight: '500'}}>
                              {notLoad === 0 ? '' : notLoad}
                            </EuiText>
                          </EuiFlexItem>
                        </>
                      ) : null}
                      {state === 'drying' ? (
                        <>
                          {drying?.boxes.length > 0 ? (
                            <EuiFlexItem grow={1}>
                              <Measurements guide={guide} setAlertHumidity={handleAlertHumidity} />
                            </EuiFlexItem>
                          ) : null}

                          <EuiFlexItem grow={1}>
                            <Boxes guide={guide} />
                          </EuiFlexItem>

                          <EuiFlexItem grow={1}>
                            <AlertBeacon drying={drying} />
                          </EuiFlexItem>
                          <EuiFlexItem grow={1}>
                            <AlertHumidity
                              alertHumidity={alertHumidity}
                              drying={drying}
                              handleAlertHumidity={handleAlertHumidity}
                            />
                          </EuiFlexItem>
                        </>
                      ) : null}
                      <EuiFlexItem grow={8}> </EuiFlexItem>

                      <EuiFlexItem grow={1}>
                        {guide.isGuidePhoto ? <ShowGuidePhoto id={guide.id} state={guide.state} /> : null}
                      </EuiFlexItem>
                      <EuiFlexItem grow={1}>
                        {state === 'quality' ? <QualityReport guide={guide} /> : null}
                        {state === 'drying' ? <DryingReport guide={guide} /> : null}
                        {state === 'dispatch' ? <DispatchForm guide={guide} /> : null}
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </>
                )}
              </>
            )}
          </span>
        }
      ></EuiCard>
    </>
  )
}

export default GuideCard
