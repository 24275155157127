import React, {useState, useContext} from 'react'
import {EuiButtonIcon, EuiOverlayMask, EuiPopover, EuiButton, EuiFlexGroup, EuiFlexItem} from '@elastic/eui'
import Camera, {FACING_MODES, IMAGE_TYPES} from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import {DataContext} from './../../context/DataContext'
import GUIDES_SERVICES from './../../services/guides'

const GuidePhoto = ({id, isGuidePhoto}) => {
  const {user, updateGuide, dispatch, actionsUI} = useContext(DataContext)
  const [maskOpenCamera, changeMaskCamera] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen)
  const closePopover = () => setIsPopoverOpen(false)

  const button = <EuiButtonIcon color='#1D1E24' onClick={onButtonClick} iconType='emsApp' iconSize='l' />

  const modalCamera = (
    <EuiOverlayMask
      onClick={() => {
        changeMaskCamera(false)
      }}
    >
      <Camera
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        imageType={IMAGE_TYPES.JPG}
        imageCompression={0.95}
        isImageMirror={false}
        onTakePhoto={(dataUri) => {
          handleGetPhoto(dataUri)
        }}
      />
    </EuiOverlayMask>
  )

  const handleGetPhoto = async (dataUri) => {
    if (dataUri !== 'data:,') {
      await GUIDES_SERVICES.updatePhotos(user.token, {guidePhoto: dataUri, isGuidePhoto: dataUri ? true : false}, id)
        .then((data) => {
          updateGuide(data.data)
          changeMaskCamera(false)
          dispatch(
            actionsUI.addAlert({
              id: '1',
              title: dataUri ? 'Guía ingresada' : 'Guía eliminada',
              color: 'success',
              iconType: 'check',
            }),
          )
        })
        .catch((e) => {
          console.log(e)
          dispatch(actionsUI.addAlert({id: '1', title: e.response.data.message, color: 'danger', iconType: 'alert'}))
        })
    } else {
      dispatch(
        actionsUI.addAlert({
          id: '1',
          title: 'Imagen no ubicada',
          color: 'danger',
          iconType: 'alert',
        }),
      )
    }
  }

  const openCamera = () => {
    changeMaskCamera(true)
    closePopover()
  }

  return (
    <>
      {maskOpenCamera ? modalCamera : undefined}
      <EuiPopover ownFocus button={button} isOpen={isPopoverOpen} closePopover={closePopover}>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiButton color='#1D1E24' onClick={() => openCamera()} iconType='image'>
              Subir guia
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
        {isGuidePhoto ? (
          <>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiButton color='danger' onClick={() => handleGetPhoto('')} iconType='cross'>
                  Eliminar imagenes
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </>
        ) : null}
      </EuiPopover>
    </>
  )
}

export default GuidePhoto
