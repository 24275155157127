import React from 'react'
import {EuiOverlayMask, EuiButton} from '@elastic/eui'
import moment from 'moment'
import QRCode from 'qrcode.react'
import './style.css'

const QrPrint = ({guide, code, weight, changeMask}) => {
  return (
    <React.Fragment>
      <EuiOverlayMask
        onClick={() => {
          changeMask(false)
        }}
      >
        <html class='body'>
          <body>
            <div class='ticket'>
              <QRCode className='img' value={code.toString()} renderAs={'svg'} size={140} />

              <table class='data'>
                <tbody>
                  <tr>
                    <th class='title borders'>Código:</th>
                    <td class='title borders'>{code.toString()}</td>
                  </tr>
                  <tr>
                    <th class='title borders'>Exportadora: </th>
                    <td class='title borders'>{guide.exporter ? guide.exporter.name : '-'}</td>
                  </tr>
                  <tr>
                    <th class='title borders'>Productor: </th>
                    <td class='value borders'>{guide.producer.name}</td>
                  </tr>
                  <tr>
                    <th class='title borders'>Guía despacho: </th>
                    <td class='value borders'>{guide.number}</td>
                  </tr>
                  <tr>
                    <th class='title borders'>Lote interno: </th>
                    <td class='value borders'>{guide.lot}</td>
                  </tr>
                  <tr>
                    <th class='title borders'>Peso: </th>
                    <td class='value borders'>{weight}</td>
                  </tr>
                  <tr>
                    <th class='title borders'>Fecha recepción: </th>
                    <td class='value borders'>{moment(guide.reception.updatedAt).format('L')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </body>
          <EuiButton className='oculto-impresion' fill fullWidth onClick={() => window.print()}>
            imprimir
          </EuiButton>
        </html>
      </EuiOverlayMask>
    </React.Fragment>
  )
}

export default QrPrint
