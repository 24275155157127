import React from 'react'
import {EuiButton, EuiButtonEmpty, EuiForm, EuiFlexGroup, EuiFlexItem} from '@elastic/eui'
import ApproveButton from './../ApproveButton'
import PreviewPdf from './../PreviewPdf'

const FooterFormReport = ({showApprovalPanel, user, approve, closeModal, create, data, loading, guide}) => {
  const handleApprove = () => {
    approve()
  }

  return (
    <EuiForm fullWidth>
      <EuiFlexGroup gutterSize='none'>
        {showApprovalPanel && user.type === 'admin' && data ? (
          <>
            <EuiFlexItem grow={false}>
              <ApproveButton approve={handleApprove} />
            </EuiFlexItem>
            <EuiFlexItem>
              <PreviewPdf guide={guide} />
            </EuiFlexItem>
          </>
        ) : null}

        <EuiFlexItem></EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty onClick={closeModal}>Salir</EuiButtonEmpty>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButton onClick={create} isLoading={loading} fill>
            Guardar
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiForm>
  )
}

export default FooterFormReport
