import React, {useContext} from 'react'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormControlLayout,
  EuiComboBox,
  EuiFieldSearch,
  EuiDatePickerRange,
  EuiDatePicker,
} from '@elastic/eui'
import {DataContext} from './../../../context/DataContext'

const Filters = ({
  loading,
  onChangeNumberGuide,
  numberGuideFilter,
  onChangeProducer,
  selectedProducer,
  onChangeExporter,
  selectedExporter,
  startDate,
  handleChangeStart,
  endDate,
  handleChangeEnd,
  exitNumberGuideFilter,
  onChangeExitNumberGuide,
  selectedStatus,
  onChangeStatus,
}) => {
  const {comboProducers, comboExporters, user} = useContext(DataContext)

  const emailStatus = [
    {
      id: 'delivered',
      label: 'Entregado',
    },
    {
      id: 'not_delivered',
      label: 'No entregado',
    },
    {
      id: 'bounced',
      label: 'Rebotado',
    },
  ]

  return (
    <EuiFlexGroup>
      <EuiFlexItem grow={2}>
        {user.user.type !== 'user' || user.user.isExporter ? (
          <EuiFormControlLayout>
            <EuiComboBox
              placeholder='Productor'
              singleSelection={{asPlainText: true}}
              options={comboProducers}
              selectedOptions={selectedProducer}
              onChange={onChangeProducer}
              fullWidth
              isLoading={loading}
              isDisabled={loading}
            />
          </EuiFormControlLayout>
        ) : null}
      </EuiFlexItem>
      <EuiFlexItem grow={2}>
        {user.user.type !== 'user' ? (
          <EuiFormControlLayout>
            <EuiComboBox
              placeholder='Exportador'
              singleSelection={{asPlainText: true}}
              options={comboExporters}
              selectedOptions={selectedExporter}
              onChange={onChangeExporter}
              fullWidth
              isLoading={loading}
              isDisabled={loading}
            />
          </EuiFormControlLayout>
        ) : null}
      </EuiFlexItem>
      {exitNumberGuideFilter !== 'fromMail' ? (
        <>
          {' '}
          <EuiFlexItem grow={2}>
            <EuiFormControlLayout>
              <EuiFieldSearch
                placeholder='N° Guía'
                fullWidth
                value={numberGuideFilter > 0 ? numberGuideFilter : ''}
                onChange={(e) => onChangeNumberGuide(e)}
                aria-label='Use aria labels when no actual label is in use'
                isLoading={loading}
                disabled={loading}
              />
            </EuiFormControlLayout>
          </EuiFlexItem>
          <EuiFlexItem grow={2}>
            <EuiFormControlLayout>
              <EuiFieldSearch
                placeholder='N° Guía Saliente'
                fullWidth
                value={exitNumberGuideFilter > 0 ? exitNumberGuideFilter : ''}
                onChange={(e) => onChangeExitNumberGuide(e)}
                aria-label='Use aria labels when no actual label is in use'
                isLoading={loading}
                disabled={loading}
              />
            </EuiFormControlLayout>
          </EuiFlexItem>
        </>
      ) : (
        <EuiFlexItem grow={2}>
          <EuiFormControlLayout>
            <EuiComboBox
              placeholder='Estatus'
              singleSelection={{asPlainText: true}}
              options={emailStatus}
              selectedOptions={selectedStatus}
              onChange={onChangeStatus}
              fullWidth
              isLoading={loading}
              isDisabled={loading}
            />
          </EuiFormControlLayout>
        </EuiFlexItem>
      )}

      <EuiFlexItem grow={4}>
        <EuiDatePickerRange
          startDateControl={
            <EuiDatePicker
              fullWidth
              selected={startDate}
              onChange={handleChangeStart}
              startDate={startDate}
              endDate={endDate}
              isInvalid={startDate > endDate}
              aria-label='Start date'
              readOnly={loading}
              disabled={loading}
            />
          }
          endDateControl={
            <EuiDatePicker
              selected={endDate}
              onChange={handleChangeEnd}
              startDate={startDate}
              endDate={endDate}
              isInvalid={startDate > endDate}
              aria-label='End date'
              readOnly={loading}
              disabled={loading}
            />
          }
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default Filters
