import React, {useState, Fragment, useEffect} from 'react'
import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiPagination,
  EuiSpacer,
  EuiText,
  EuiSuperSelect,
  EuiLoadingSpinner,
  EuiForm,
  EuiConfirmModal,
  EuiFormControlLayout,
  EuiFieldSearch,
} from '@elastic/eui'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {validate} from 'validate.js'
import {validate as validateRut, clean, format} from 'rut.js'
import {constraintsEs} from './validates'
import USERS_SERVICES from '../../services/users'
import * as actionsUI from '../../store/actions/uiActions'
import objets from './objets'

const Users = () => {
  const dispatch = useDispatch()
  const token = useSelector((store) => store.auth.user.token)
  const [actionsEvent] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [activePage, setActivePage] = useState(0)
  const [pageOfItems, setPageOfItems] = useState([])
  const [pageIndex, setPageIndex] = useState(0)
  const [pageIndexPrevious, setPageIndexPrevious] = useState(0)
  const [pagePrevius, setPagePrvevius] = useState('')
  const [pageNext, setPagenext] = useState('')
  const [sortField, setSortField] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')
  const [maskOpen, setMaskOpen] = useState(false)
  const [loadOther, setloadOther] = useState(false)
  const [, setSelectedItems] = useState([])
  const [errors, setErrors] = useState({})
  const [action, setAction] = useState('')
  const [item, setItem] = useState({})
  const [modal, setModal] = useState(false)
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [rut, setRut] = useState('')
  const [selectedOptionsUserType, setSelectedOptionsUserType] = useState([])
  const [selectedOptionsUserStatus, setSelectedOptionsUserStatus] = useState('ACTIVE')
  const [confirm, setConfirm] = useState(false)
  const [searchName, setSearchName] = useState('')
  const [searchEmail, setSearchEmail] = useState('')
  const [searchRut, setSearchRut] = useState('')
  const [customHeader] = useState(true)
  const [isResponsive] = useState(true)

  useEffect(() => {
    setMaskOpen(true)
    getUsers('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, actionsEvent])

  useEffect(() => {
    setMaskOpen(true)
    getUsers('*')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, loadOther])

  const getUsers = async (type) => {
    let next = ''
    let previous = ''
    if (type === '') {
      next = pageIndex > pageIndexPrevious ? pageNext : ''
      previous = pageIndex < pageIndexPrevious ? pagePrevius : ''
      setPageIndexPrevious(pageIndex)
    } else {
      setPageIndexPrevious(0)
      setPageIndex(0)
      setActivePage(0)
      next = ''
      previous = ''
    }
    await USERS_SERVICES.getAll(
      token,
      pageSize,
      next,
      previous,
      sortField,
      ['admin'],
      searchName,
      searchEmail,
      searchRut,
    )
      .then((data) => {
        const datos = data.data
        setPageOfItems(datos.docs)
        setPagenext(datos.hasNext ? datos.next : '')
        setPagePrvevius(datos.hasPrevious ? datos.previous : '')
        setPageCount(Math.ceil(datos.totalDocs / pageSize))
        setMaskOpen(false)
      })
      .catch((error) => {
        setMaskOpen(false)
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const addUser = () => {
    setAction('Add')
    setModal(true)
  }

  const editUser = async (user) => {
    setMaskOpen(true)
    setItem(user)
    await USERS_SERVICES.getOne(token, user._id)
      .then((data) => {
        const record = data.data
        setName(record.name)
        setLastName(record.lastName)
        setEmail(record.email)
        setRut(format(record.rut))
        setSelectedOptionsUserType(objets.userType.filter((element) => element.code === record.type))
        setSelectedOptionsUserStatus(objets.userStatus.filter((element) => element.code === record.status))
        setAction('Update')
        setModal(true)
        setMaskOpen(false)
      })
      .catch((error) => {
        setMaskOpen(false)
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const deleteUser = async (user) => {
    setMaskOpen(true)
    await USERS_SERVICES.delete(user._id, token)
      .then((data) => {
        setloadOther(!loadOther)
        dispatch(actionsUI.addAlert({id: '1', title: 'Usuario eliminado', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        setMaskOpen(false)
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
    setSelectedItems([])
  }

  const sendEmail = (user) => {
    setItem(user)
    if (user.sendMail === false) {
      setConfirm(true)
    } else {
      dispatch(actionsUI.addAlert({id: '1', title: 'Correo enviado', color: 'success', iconType: 'check'}))
    }
  }

  const columns = [
    {
      field: 'name',
      name: 'Nombre',
      truncateText: true,
      sortable: true,
      mobileOptions: {
        render: (item) => (
          <span>
            {item.name} {item.lastName}
          </span>
        ),
        header: true,
        truncateText: false,
        enlarge: true,
        fullWidth: true,
        show: true,
      },
    },
    {
      field: 'lastName',
      name: 'Apellido',
      truncateText: true,
      mobileOptions: {
        show: false,
      },
    },
    {
      field: 'email',
      name: 'Email',
      truncateText: true,
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'rut',
      name: 'Rut',
      truncateText: true,
      mobileOptions: {
        show: true,
      },
      render: (item) => format(item),
    },
    {
      field: 'type',
      name: 'Rol',
      truncateText: true,
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'status',
      name: 'Status',
      truncateText: true,
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'createdAt',
      name: 'F. Creación',
      truncateText: true,
      render: (date) => moment(date).format('LLL'),
      mobileOptions: {
        show: !isResponsive || !customHeader,
      },
    },
    {
      field: 'updatedAt',
      name: 'F. Actualización',
      truncateText: true,
      render: (date) => moment(date).format('LLL'),
      mobileOptions: {
        show: !isResponsive || !customHeader,
      },
    },
    {
      name: 'Acciones',
      actions: objets.action(editUser, deleteUser, sendEmail),
    },
  ]

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  }

  const goToPage = (pageNumber) => {
    setPageIndexPrevious(activePage)
    setPageIndex(pageNumber)
    setActivePage(pageNumber)
  }

  const onTableChange = ({page = {}, sort = {}}) => {
    const {index: pageIndex, size: pageSize} = page
    const {field: sortField, direction: sortDirection} = sort
    setPageIndex(pageIndex)
    setPageSize(pageSize)
    setSortField(sortField)
    setSortDirection(sortDirection)
  }

  const modalLoading = (
    <Fragment>
      <EuiOverlayMask>
        <EuiLoadingSpinner size='xl' />
      </EuiOverlayMask>
    </Fragment>
  )

  const closeModal = () => {
    setName('')
    setLastName('')
    setEmail('')
    setRut('')
    setSelectedOptionsUserStatus([])
    setSelectedOptionsUserType([])
    setModal(false)
  }

  const onChangeName = (e) => {
    setName(e.target.value)
  }

  const onChangeLastName = (e) => {
    setLastName(e.target.value)
  }

  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const onChangeUserType = (selectedOptions) => {
    setSelectedOptionsUserType(selectedOptions)
  }
  const onChangeSearchName = (e) => {
    setSearchName(e.target.value)
  }

  const onChangeSearchEmail = (e) => {
    setSearchEmail(e.target.value)
  }

  const onChangeSearchRut = (e) => {
    setSearchRut(e.target.value)
  }

  const onChangeUserStatus = (selectedOptions) => {
    setSelectedOptionsUserStatus(selectedOptions)
  }

  const onChangeRut = (e) => {
    setRut(format(e.target.value))
  }

  const doConfirm = async () => {
    setMaskOpen(true)
    await USERS_SERVICES.sendMail(token, item._id)
      .then((data) => {
        setPageOfItems(pageOfItems.map((element) => (element._id === item._id ? {...item, sendMail: true} : item)))
        setMaskOpen(false)
        setConfirm(false)
        dispatch(actionsUI.addAlert({id: '1', title: data.data.message, color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        setMaskOpen(false)
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const save = async () => {
    const form = {
      name,
      lastName,
      email,
      rut: clean(rut),
      type: selectedOptionsUserType,
      status: selectedOptionsUserStatus,
    }

    let validationResult
    validationResult = validate(form, constraintsEs)

    validate.validators.custom = function (value, options, key, attributes) {
      if (validateRut(value) === false) {
        return (validationResult = {...validationResult, rut: ['Rut invalido']})
      }
    }

    validate({rut: form.rut}, {rut: {custom: 'options'}})

    if (validationResult !== undefined) {
      setErrors(validationResult)
      return
    }
    setErrors({})
    setMaskOpen(true)
    if (action === 'Add') {
      await USERS_SERVICES.create(token, form)
        .then((data) => {
          setloadOther(!loadOther)
          dispatch(actionsUI.addAlert({id: '1', title: 'Usuario ingresado', color: 'success', iconType: 'check'}))
          closeModal()
        })
        .catch((error) => {
          setloadOther(!loadOther)
          dispatch(
            actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
          )
        })
    } else {
      USERS_SERVICES.update(token, form, item._id)
        .then((data) => {
          setPageOfItems(pageOfItems.map((item) => (item._id === data.data._id ? {...data.data} : item)))
          dispatch(actionsUI.addAlert({id: '1', title: 'Usuario actualizado', color: 'success', iconType: 'check'}))
          setMaskOpen(false)
          closeModal()
        })
        .catch((error) => {
          setMaskOpen(false)
          dispatch(
            actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
          )
        })
    }
  }

  const onSearchName = (value) => {
    setSearchName(value)
    setloadOther(!loadOther)
  }

  const onSearchEmail = (value) => {
    setSearchEmail(value.toLowerCase())
    setloadOther(!loadOther)
  }
  const onSearchRut = (value) => {
    setSearchRut(value)
    setloadOther(!loadOther)
  }

  return (
    <>
      {maskOpen ? modalLoading : undefined}
      {confirm && (
        <EuiOverlayMask>
          <EuiConfirmModal
            title='Envio de correo'
            onCancel={() => {
              setConfirm(false)
            }}
            onConfirm={doConfirm}
            cancelButtonText='Cancelar'
            confirmButtonText='Aceptar'
            defaultFocusedButton='confirm'
          >
            <p>¿ Enviar correo ?</p>
          </EuiConfirmModal>
        </EuiOverlayMask>
      )}
      {modal && (
        <EuiOverlayMask>
          <EuiModal onClose={closeModal} initialFocus='[name=popswitch]' style={{width: 900}}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>Usuario</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody style={{height: 300}}>
              <EuiForm>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      label='Nombre'
                      fullWidth
                      isInvalid={errors.name === undefined ? false : true}
                      error={errors.name === undefined ? '' : errors.name[0]}
                    >
                      <EuiFieldText fullWidth name='name' onChange={(e) => onChangeName(e)} value={name} />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow
                      label='Apellido'
                      fullWidth
                      isInvalid={errors.lastName === undefined ? false : true}
                      error={errors.lastName === undefined ? '' : errors.lastName[0]}
                    >
                      <EuiFieldText fullWidth name='lastName' onChange={(e) => onChangeLastName(e)} value={lastName} />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      label='Email'
                      fullWidth
                      isInvalid={errors.email === undefined ? false : true}
                      error={errors.email === undefined ? '' : errors.email[0]}
                    >
                      <EuiFieldText fullWidth name='email' onChange={(e) => onChangeEmail(e)} value={email} />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow
                      label='Rut'
                      fullWidth
                      isInvalid={errors.rut === undefined ? false : true}
                      error={errors.rut === undefined ? '' : errors.rut[0]}
                    >
                      <EuiFieldText fullWidth name='rut' onChange={(e) => onChangeRut(e)} value={rut} />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>

                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      label='Rol'
                      fullWidth
                      isInvalid={errors.type === undefined ? false : true}
                      error={errors.type === undefined ? '' : errors.type[0]}
                    >
                      <EuiSuperSelect
                        placeholder='Seleccione una opcion'
                        options={objets.userType}
                        valueOfSelected={selectedOptionsUserType}
                        onChange={onChangeUserType}
                        fullWidth
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow
                      label='Activo'
                      fullWidth
                      isInvalid={errors.status === undefined ? false : true}
                      error={errors.status === undefined ? '' : errors.status[0]}
                    >
                      <EuiSuperSelect
                        options={objets.userStatus}
                        valueOfSelected={selectedOptionsUserStatus}
                        onChange={onChangeUserStatus}
                        fullWidth
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiForm>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButtonEmpty onClick={closeModal}>Cancelar</EuiButtonEmpty>
              <EuiButton onClick={save} fill>
                Guardar
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      )}
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton onClick={addUser} fill size='s'>
            Nuevo Usuario
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormControlLayout>
            <EuiFieldSearch
              placeholder='Ingrese nombre'
              value={searchName}
              isClearable={true}
              onChange={onChangeSearchName}
              onSearch={onSearchName}
            />
          </EuiFormControlLayout>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormControlLayout>
            <EuiFieldSearch
              placeholder='Ingrese Email'
              value={searchEmail}
              isClearable={true}
              onChange={onChangeSearchEmail}
              onSearch={onSearchEmail}
            />
          </EuiFormControlLayout>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormControlLayout>
            <EuiFieldSearch
              placeholder='Ingrese Rut'
              value={searchRut}
              isClearable={true}
              onChange={onChangeSearchRut}
              onSearch={onSearchRut}
            />
          </EuiFormControlLayout>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <Fragment>
            <EuiBasicTable
              items={pageOfItems}
              itemId='id'
              columns={columns}
              //pagination={pagination}
              sorting={sorting}
              //selection={selection}
              isSelectable={true}
              hasActions={true}
              responsive={true}
              onChange={onTableChange}
            />
            <EuiSpacer size='m' />
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div style={{display: 'flex', alignItems: 'center', width: '50%'}}>
                <EuiText size='m'>Lineas por página</EuiText>
                <EuiSuperSelect
                  options={objets.options}
                  valueOfSelected={pageSize}
                  onChange={(value) => {
                    setPageSize(value)
                  }}
                  style={{marginLeft: 10}}
                  compressed
                />
              </div>
              <div style={{width: '50%', justifyContent: 'flex-end'}}>
                <EuiPagination
                  pageCount={pageCount}
                  activePage={activePage}
                  onPageClick={(activePage) => goToPage(activePage)}
                  compressed
                  style={{justifyContent: 'flex-end'}}
                />
              </div>
            </div>
          </Fragment>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

export default Users
