import React, {useEffect, useContext, useState} from 'react'
import {EuiStat, EuiFlexItem, EuiFlexGroup, EuiPanel, EuiTitle} from '@elastic/eui'
import DRYING_SERVICES from '../../../../services/drying'
import {DataContext} from './../../../../context/DataContext'

const AverageTimes = ({averageGuideTime, year}) => {
  const {totalDiffGuide, countGuide} = averageGuideTime[0]
  const {dispatch, actionsUI, user, formatNumber} = useContext(DataContext)
  const [loading, setLoading] = useState(false)
  // const [diffDriyng, setDiffDriyng] = useState({})

  useEffect(() => {
    getTimes()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTimes = async () => {
    setLoading(true)
    await DRYING_SERVICES.getData(user.token, {types: ['admin'], year})
      .then((data) => {
        //   setDiffDriyng(data.data.averageDryingTime[0])
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size='xs'>
            <h4>Tiempos promedios</h4>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel paddingSize='s'>
            <EuiStat
              titleSize='m'
              title={`${formatNumber((totalDiffGuide / countGuide).toFixed(1))} Hrs`}
              description='Tiempo promedio general planta'
              textAlign='right'
              isLoading={loading}
            ></EuiStat>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          {/* <EuiPanel paddingSize='s'>
              <EuiStat
                title={`${formatNumber((diffDriyng.totalDiffDrying / diffDriyng.count).toFixed(1))} Hrs`}
                description='Tiempo promedio de secado'
                titleColor='accent'
                textAlign='right'
                isLoading={false}
              ></EuiStat>
            </EuiPanel>
         */}{' '}
        </EuiFlexItem>
      </EuiFlexGroup>{' '}
    </>
  )
}

export default AverageTimes
