import React, {useEffect, useState, useContext} from 'react'
import {
  EuiFormRow,
  EuiText,
  EuiForm,
  EuiFieldText,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiPanel,
  EuiLoadingContent,
} from '@elastic/eui'
import PRODUCERS_SERVICES from '../../services/producers'
import EXPORTERS_SERVICES from './../../services/exporters'
import USERS_SERVICES from './../../services/users'
import {DataContext} from './../../context/DataContext'

const EditProfile = ({user, editProfile}) => {
  const {dispatch, actionsUI} = useContext(DataContext)
  const [producer, setProducer] = useState([])
  const [exporter, setExporter] = useState([])
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [producerName, setProducerName] = useState('')
  const [producerCity, setProducerCity] = useState('')
  const [producerCommune, setProducerCommune] = useState('')
  const [producerAddress, setProducerAddress] = useState('')
  const [producerEmails, setProducerEmails] = useState('')
  const [exporterName, setExporterName] = useState('')
  const [exporterCity, setExporterCity] = useState('')
  const [exporterCommune, setExporterCommune] = useState('')
  const [exporterAddress, setExporterAddress] = useState('')
  const [exporterEmails, setExporterEmails] = useState('')

  useEffect(() => {
    if (editProfile) {
      setLoading(true)
      getProducer()
      getExporter()
      getUser()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeFirstName = (e) => setFirstName(e.target.value)
  const onChangeLastName = (e) => setLastName(e.target.value)

  const onChangeProducerName = (e) => setProducerName(e.target.value)
  const onChangeProducerAddress = (e) => setProducerAddress(e.target.value)
  const onChangeProducerCity = (e) => setProducerCity(e.target.value)
  const onChangeProducerCommune = (e) => setProducerCommune(e.target.value)
  const onChangeProducerEmails = (e) => setProducerEmails(e.target.value)

  const onChangeExporterName = (e) => setExporterName(e.target.value)
  const onChangeExporterAddress = (e) => setExporterAddress(e.target.value)
  const onChangeExporterCity = (e) => setExporterCity(e.target.value)
  const onChangeExporterCommune = (e) => setExporterCommune(e.target.value)
  const onChangeExporterEmails = (e) => setExporterEmails(e.target.value)

  const getProducer = async () => {
    await PRODUCERS_SERVICES.getByRut(user.token, user.user.rut)
      .then((data) => {
        setProducer(data.data)
        setProducerName(data.data.name)
        setProducerCity(data.data.address.city)
        setProducerCommune(data.data.address.commune)
        setProducerAddress(data.data.address.street)
        setProducerEmails(data.data.emails)
        setLoading(false)
      })
      .catch(async (error) => {
        setLoading(false)
        setProducer(false)
      })
  }

  const getExporter = async () => {
    await EXPORTERS_SERVICES.getByRut(user.token, user.user.rut)
      .then((data) => {
        setExporter(data.data)
        setExporter(data.data)
        setExporterName(data.data.name)
        setExporterCity(data.data.address.city)
        setExporterCommune(data.data.address.commune)
        setExporterAddress(data.data.address.street)
        setExporterEmails(data.data.emails)
        setLoading(false)
      })
      .catch(async (error) => {})
  }

  const getUser = async () => {
    await USERS_SERVICES.getOne(user.token, user.user._id)
      .then((data) => {
        setFirstName(data.data.name)
        setLastName(data.data.lastName)
        setLoading(false)
      })
      .catch(async (error) => {
        setLoading(false)
        setExporter(false)
      })
  }

  const update = async () => {
    setLoading(true)
    const formUser = {
      name: firstName,
      lastName,
    }
    await USERS_SERVICES.edit(user.token, formUser, user.user._id)
      .then((data) => {
        dispatch(actionsUI.addAlert({id: '1', title: 'Usuario actualizado', color: 'success', iconType: 'check'}))
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })

    // actualizo productor
    if (producer.rut) {
      const formProducer = {
        rut: producer.rut,
        name: producerName,
        email: producer.email,
        turn: producer.turn,
        address: {
          city: producerCity,
          commune: producerCommune,
          street: producerAddress,
        },
        emails: producerEmails,
      }
      await PRODUCERS_SERVICES.update(user.token, formProducer, producer._id)
        .then((data) => {
          setLoading(false)
          dispatch(actionsUI.addAlert({id: '1', title: 'Productor actualizado', color: 'success', iconType: 'check'}))
        })
        .catch((error) => {
          setLoading(false)
          dispatch(
            actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
          )
        })
    }

    // actualizo exprtador
    if (exporter.rut) {
      const formExporter = {
        rut: exporter.rut,
        name: exporterName,
        email: exporter.email,
        turn: exporter.turn,
        contact: exporter.contact,
        transferType: exporter.transferType,
        economicActivity: exporter.economicActivity,
        address: {
          city: exporterCity,
          commune: exporterCommune,
          street: exporterAddress,
        },
        emails: exporterEmails,
      }
      await EXPORTERS_SERVICES.update(user.token, formExporter, exporter._id)
        .then((data) => {
          setLoading(false)
          dispatch(actionsUI.addAlert({id: '1', title: 'Exportdor actualizado', color: 'success', iconType: 'check'}))
        })
        .catch((error) => {
          setLoading(false)
          dispatch(
            actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
          )
        })
    }
  }

  return loading ? (
    <>
      <EuiLoadingContent lines={3} />
      <EuiLoadingContent lines={3} />
      <EuiLoadingContent lines={3} />
    </>
  ) : (
    <EuiForm>
      <EuiText textAlign='center'>Usuario</EuiText>
      <EuiPanel paddingSize='s'>
        <EuiFlexGroup gutterSize='xs' justifyContent='spaceAround'>
          <EuiFlexItem>
            <EuiFormRow label='Nombre'>
              <EuiFieldText compressed fullWidth value={firstName} onChange={(e) => onChangeFirstName(e)} />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label='Apellido' error={'aSASD'}>
              <EuiFieldText
                compressed
                fullWidth
                placeholder='Apellido'
                value={lastName}
                onChange={(e) => onChangeLastName(e)}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>

      <EuiSpacer size='s' />
      {/***
       * PRODUICTOR **************************************************************************************
       */}
      {producer?.rut ? (
        <>
          <EuiText textAlign='center'>Perfil Productor</EuiText>
          <EuiPanel paddingSize='s'>
            <EuiFlexGroup direction='column' gutterSize='xs' justifyContent='spaceAround'>
              <EuiFlexItem>
                <EuiFormRow label='Razón social' fullWidth>
                  <EuiFieldText
                    compressed
                    fullWidth
                    name='producerName'
                    onChange={(e) => onChangeProducerName(e)}
                    value={producerName}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label='Dirección' fullWidth>
                  <EuiFieldText
                    compressed
                    fullWidth
                    name='producerAddress'
                    onChange={(e) => onChangeProducerAddress(e)}
                    value={producerAddress}
                  />
                </EuiFormRow>
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiFormRow label='Ciudad' fullWidth>
                  <EuiFieldText
                    compressed
                    fullWidth
                    name='producerCity'
                    onChange={(e) => onChangeProducerCity(e)}
                    value={producerCity}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label='Comuna' fullWidth>
                  <EuiFieldText
                    compressed
                    fullWidth
                    name='producerCommune'
                    onChange={(e) => onChangeProducerCommune(e)}
                    value={producerCommune}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label='Emails' fullWidth>
                  <EuiFieldText
                    compressed
                    fullWidth
                    name='producerEmails'
                    onChange={(e) => onChangeProducerEmails(e)}
                    value={producerEmails}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </>
      ) : null}
      {/***
       * EXPORTADOR **************************************************************************************
       */}
      <EuiSpacer size='s' />
      {exporter?.rut ? (
        <>
          <EuiText textAlign='center'>Perfil Exportador</EuiText>
          <EuiPanel paddingSize='s'>
            <EuiFlexGroup direction='column' gutterSize='xs' justifyContent='spaceAround'>
              <EuiFlexItem>
                <EuiFormRow label='Razón social' fullWidth>
                  <EuiFieldText
                    compressed
                    fullWidth
                    name='exporterName'
                    onChange={(e) => onChangeExporterName(e)}
                    value={exporterName}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem grow={2}>
                <EuiFormRow label='Dirección' fullWidth>
                  <EuiFieldText
                    compressed
                    fullWidth
                    name='exporterAddress'
                    onChange={(e) => onChangeExporterAddress(e)}
                    value={exporterAddress}
                  />
                </EuiFormRow>
              </EuiFlexItem>

              <EuiFlexItem grow={1}>
                <EuiFormRow label='Ciudad' fullWidth>
                  <EuiFieldText
                    compressed
                    fullWidth
                    name='exporterCity'
                    onChange={(e) => onChangeExporterCity(e)}
                    value={exporterCity}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem grow={1}>
                <EuiFormRow label='Comuna' fullWidth>
                  <EuiFieldText
                    compressed
                    fullWidth
                    name='exporterCommune'
                    onChange={(e) => onChangeExporterCommune(e)}
                    value={exporterCommune}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label='Emails' fullWidth>
                  <EuiFieldText
                    compressed
                    fullWidth
                    name='exporterEmails'
                    onChange={(e) => onChangeExporterEmails(e)}
                    value={exporterEmails}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </>
      ) : null}
      <EuiSpacer size='s' />
      <EuiFlexItem>
        <EuiButton isLoading={loading} fullWidth={true} type='submit' fill onClick={update}>
          Actualizar
        </EuiButton>
      </EuiFlexItem>
    </EuiForm>
  )
}

export default EditProfile
