import React from 'react'
import {Text, View, Image, StyleSheet} from '@react-pdf/renderer'
import LogoImg from './../../../assets/images/logo-green.png'
import moment from 'moment'
import momenttz from 'moment-timezone'

const Quality = ({data, qualityPhotos}) => {
  const {quality, number, reception, exporter, products, producer} = data
  const styles = StyleSheet.create({
    container: {
      paddingTop: 15,
      display: 'flex',
      flexDirection: 'column',
    },
    row: {
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 10,
    },
    rowObs: {
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 8,
      marginLeft: 50,
      marginBottom: 10,
    },
    rowDetails: {display: 'inline-flex', flexDirection: 'row', fontSize: 8, marginLeft: 50, marginBottom: 18},
    rowTitle: {
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 10,
      paddingTop: 40,
    },
    colA: {
      width: '20%',
      fontFamily: 'Helvetica-Bold',
    },
    colB: {
      width: '30%',
      marginBottom: 5,
    },
    col1: {
      width: '25%',
      fontSize: 10,
    },
    col2: {
      width: '30%',
      fontSize: 10,
      borderWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
    },
    col3: {
      width: '30%',
      fontSize: 10,
      borderWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
    },
    image: {
      marginVertical: 25,
      marginHorizontal: 190,
    },
    photos: {
      marginTop: 10,
    },
    title: {
      fontSize: 14,
      textAlign: 'center',
      fontWeight: 'bold',
      borderWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      marginBottom: 20,
    },
    text: {
      margin: 3,
    },

    lot: {
      borderBottomWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      fontWeight: 'bold',
      fontSize: 14,
    },
    total: {
      borderBottomWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      fontSize: 12,
      paddingTop: 20,
    },
    colTitle: {
      width: '25%',
      fontSize: 10,
    },
    colTitle2: {
      width: '30%',
      fontSize: 12,
      marginBottom: 5,
    },
    colTitle3: {
      width: '30%',
      fontSize: 12,
      marginBottom: 5,
    },
  })

  return (
    <>
      <Image style={styles.image} src={LogoImg} />
      <Text style={styles.title}>Analisis de calidad</Text>

      <View style={styles.container}>
        <View style={styles.rowDetails}>
          <View style={styles.colA}>
            <Text>Exportadora:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{exporter?.name ? exporter.name : '-'}</Text>
          </View>
          <View style={styles.colA}>
            <Text>N° Guía origen:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{number}</Text>
          </View>
        </View>

        <View style={styles.rowDetails}>
          <View style={styles.colA}>
            <Text>Productor:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{producer.name}</Text>
          </View>
          <View style={styles.colA}>
            <Text>Fecha de Recepción:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{moment(momenttz(reception.updatedAt).tz('America/Santiago')).format('DD-MM-YYYY HH:mm')}</Text>
          </View>
        </View>

        <View style={styles.rowDetails}>
          <View style={styles.colA}>
            <Text>Variedad:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{products.name}</Text>
          </View>
          <View style={styles.colA}>
            <Text>Total de nueces:</Text>
          </View>
          <View style={styles.colB}>
            <Text>{quality.totalSample ? quality.totalSample : '-'}</Text>
          </View>
        </View>

        <View style={styles.rowObs}>
          <View style={styles.colA}>
            <Text>Observación:</Text>
          </View>
        </View>

        <View style={styles.rowObs}>
          <View>
            <Text>{quality.commentary}</Text>
          </View>
        </View>

        <View style={styles.rowTitle}>
          <View style={styles.colTitle}>
            <Text></Text>
          </View>
          <View style={styles.colTitle2}>
            <Text>N° de nueces</Text>
          </View>
          <View style={styles.colTitle3}>
            <Text>Porcentaje</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col1}>
            <Text style={styles.text}>Pelón adherido:</Text>
          </View>
          <View style={styles.col2}>
            <Text style={styles.text}>{quality.samples.s1}</Text>
          </View>
          <View style={styles.col3}>
            <Text style={styles.text}>{`${quality.samples.p1}%`}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col1}>
            <Text style={styles.text}>Daño por insecto:</Text>
          </View>
          <View style={styles.col2}>
            <Text style={styles.text}>{quality.samples.s2}</Text>
          </View>
          <View style={styles.col3}>
            <Text style={styles.text}>{`${quality.samples.p2}%`}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col1}>
            <Text style={styles.text}>Hongo en cascara:</Text>
          </View>
          <View style={styles.col2}>
            <Text style={styles.text}>{quality.samples.s3}</Text>
          </View>
          <View style={styles.col3}>
            <Text style={styles.text}>{`${quality.samples.p3}%`}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col1}>
            <Text style={styles.text}>Nueces partidas y trizadas:</Text>
          </View>
          <View style={styles.col2}>
            <Text style={styles.text}>{quality.samples.s4}</Text>
          </View>
          <View style={styles.col3}>
            <Text style={styles.text}>{`${quality.samples.p4}%`}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col1}>
            <Text style={styles.text}>Nueces vanas:</Text>
          </View>
          <View style={styles.col2}>
            <Text style={styles.text}>{quality.samples.s5}</Text>
          </View>
          <View style={styles.col3}>
            <Text style={styles.text}>{`${quality.samples.p5}%`}</Text>
          </View>
        </View>

        {Object.keys(qualityPhotos).map((item, i) => {
          return <Image style={styles.photos} source={{uri: `${qualityPhotos[item]}`}} />
        })}
      </View>
    </>
  )
}

export default Quality
