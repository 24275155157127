import React, {useState} from 'react'

import {EuiPage, EuiPageBody, EuiPageContent, EuiPageContentBody} from '@elastic/eui'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Header from './../../components/Header'
import Guides from './../../components/Guides'
import Users from './../Users'
import Configurations from './../Configurations'
import Container from './../Configurations/Container'
import Variable from './../Configurations/Variable'
import DataContextProvider from './../../context/DataContext'
import Reports from '../Reports'

const Home = () => {
  const [navIsDocked, setNavIsDocked] = useState(false)

  return (
    <Router>
      <DataContextProvider>
        <Header setNavIsDocked={setNavIsDocked} />
        <EuiPage style={{width: '100%', height: '100vh', overflowY: 'scroll', position: 'fixed'}}>
          <EuiPageBody style={navIsDocked ? {marginRight: 300} : {}}>
            <EuiPageContent style={{marginTop: 44}}>
              <EuiPageContentBody>
                <Switch>
                  <Route key={1} path='/' exact={true} children={<Guides />} />
                  <Route key={2} path='/users' exact={true} children={<Users />} />
                  <Route key={3} path='/configurations' exact={true} children={<Configurations />} />
                  <Route key={4} path='/containers' exact={true} children={<Container />} />
                  <Route key={5} path='/variables' exact={true} children={<Variable />} />
                  <Route key={6} path='/reports' exact={true} children={<Reports />} />
                </Switch>
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>
      </DataContextProvider>
    </Router>
  )
}

export default Home
