import API from './constants'

var TRIPS_SERVICES = {
  create: async (token, data) => {
    return await API.post(`trip`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  update: async (token, data, id, seat) => {
    return await API.put(`trip/${id}/seat/${seat}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  updateGuide: async (token, data, id) => {
    return await API.put(`trip/guides/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  cancelGuide: async (token, data, id) => {
    return await API.put(`trip/cancel/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  updateState: async (token, data, id) => {
    return await API.put(`trip/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  updateCommentary: async (token, data, id) => {
    return await API.put(`trip/updateCommentary/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  getOne: async (token, id) => {
    return await API.get(`trip/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  getByPatent: async (token, patent) => {
    return await API.get(`trip/patent/${patent}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
}

export default TRIPS_SERVICES
