import React, {useState, useEffect, useContext} from 'react'
import {
  EuiCard,
  EuiFlexGrid,
  EuiFlexItem,
  EuiButton,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiFlexGroup,
  EuiPageContentBody,
  EuiProgress,
  EuiPanel,
  EuiStat,
  EuiOverlayMask,
  EuiModalBody,
  EuiButtonIcon,
  EuiTextArea,
  EuiSpacer,
} from '@elastic/eui'
import QrReader from 'react-qr-reader'
import TRIPS_SERVICES from '../../services/trips'
import DRYING_SERVICES from '../../services/drying'
import DISPATCH_SERVICES from '../../services/dispatch'
import {DataContext} from './../../context/DataContext'
import ApproveButton from './../ApproveButton'
import Pdf from './../Pdf'

const Template = ({guide, tripId, setLoad, handleAllLoad}) => {
  const {drying} = guide
  const {user, dispatch, actionsUI, updateGuide, getGuides, isMobile, useDebounce} = useContext(DataContext)
  const [showCam, setShowCam] = useState(false)
  const [generateButton, setGenerateButton] = useState(false)
  const [seats, setSeats] = useState([])
  const [seat, setSeat] = useState(0)
  const [loading, setLoading] = useState(false)
  const [inProgress, setInProgress] = useState(true)
  const [status, setStatus] = useState('')
  const [patent, setPatent] = useState('')
  const [camera, setCamera] = useState('environment')
  const [exitGuide, setExitGuide] = useState(0)
  const [disable, setDisable] = useState(false)
  const [disableCancelGuide, setDisableCancelGuide] = useState(false)
  const [disableDismount, setDisableDismount] = useState(false)
  const [codesLoaded, setCodesLoaded] = useState([])
  const [maskOpen, changeMask] = useState(false)
  const dataContainer = [].concat.apply([], drying.containers)
  const [commentary, setCommentary] = useState('')

  const debounce = useDebounce((val) => updateCommentary(val), 1000)

  const onChangeCommentary = (e) => {
    setCommentary(e.target.value)
    debounce(e.target.value)
  }

  useEffect(() => {
    getTrip()
    if (seats.length === 0) {
      //setSeats(seatsFound)

      let notLoaded = dataContainer.filter((c) => c.stateLoad === false)

      if (notLoaded.length === 0) {
        handleAllLoad(true)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId])

  const checkCode = (code, seat) => {
    if (!code && status === 'load') {
      setShowCam(true)
      setSeat(seat)
    }
  }

  const modal = (
    <React.Fragment>
      <EuiOverlayMask
        onClick={() => {
          changeMask(false)
        }}
      >
        <EuiModalBody
          style={isMobile ? {position: 'fixed', overflow: 'scroll', height: '90vh', margin: 'auto'} : {margin: 'auto'}}
        >
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon onClick={() => changeMask(false)} iconType='cross' iconSize='l' color='danger' />
            </EuiFlexItem>
          </EuiFlexGroup>

          <Pdf guide={guide} type='trip' tripId={tripId} />
        </EuiModalBody>
      </EuiOverlayMask>
    </React.Fragment>
  )
  const handleScan = async (dataQr) => {
    if (dataQr) {
      const container = dataContainer.filter((c) => c.code === Number(dataQr))
      if (container[0].stateLoad) {
        dispatch(
          actionsUI.addAlert({
            id: '1',
            title: 'El codigo escaneado ya se encuetra cargado',
            color: 'danger',
            iconType: 'alert',
          }),
        )
        return null
      }

      //setQr(dataQr)

      await TRIPS_SERVICES.update(
        user.token,
        {code: Number(dataQr), exitGuide, state: 'full'},
        tripId,
        seat ? seat : undefined,
      )
        .then((data) => {
          const findCodes = [].concat.apply(
            [],
            dataContainer.map((container) => {
              return data.data.load.filter((code) => {
                return container.code === code.code
              })
            }),
          )

          setCodesLoaded(
            findCodes.map((code) => {
              return code.code
            }),
          )
          setSeats(data.data.load)
          //const dataCODES = data.data.load.map((b) =>  {if (b.code > 0) return b.code})

          const guideFound = data.data.guides.find((g) => g.entryGuide === guide.number)
          setExitGuide(guideFound !== undefined ? guideFound.exitGuide : 0)

          setDisable(false)
          setShowCam(false)
          //updateGuide(Object.assign(guide, {dispatch: {trip: data.data}}))
          DISPATCH_SERVICES.update(user.token, {trip: data.data._id}, guide.dispatch._id)
            .then((g) => {
              updateGuide(Object.assign(guide, {dispatch: g.data}))
            })
            .catch((err) => {
              console.log({err})
            })
        })
        .catch((err) => {
          console.log({err})
        })

      await DRYING_SERVICES.statesContainers(user.token, {code: dataQr, type: 'stateLoad'}, drying._id)
        .then((data) => {
          const dataContainer = [].concat.apply([], data.data.containers)
          let notLoaded = dataContainer.filter((c) => c.stateLoad === false)

          if (notLoaded.length === 0) {
            handleAllLoad(true)
          }
          setShowCam(false)
          updateGuide(Object.assign(guide, {drying: data.data}))
          dispatch(actionsUI.addAlert({id: '1', title: 'Codigo validado', color: 'success', iconType: 'check'}))
        })
        .catch((error) => {
          dispatch(
            actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
          )
        })
    }
  }
  //console.log({exitGuide})

  const handleError = (err) => {
    console.error(err)
  }

  const newTrip = () => {
    setSeats([])
    setLoad(false)
  }

  const getTrip = async () => {
    setLoading(true)

    await TRIPS_SERVICES.getOne(user.token, tripId)
      .then((data) => {
        const guideFound = data.data.guides.find((g) => g.entryGuide === guide.number)
        guideFound !== undefined ? setGenerateButton(false) : setGenerateButton(true)
        setCommentary(guideFound?.commentary)
        const findCodes = [].concat.apply(
          [],
          dataContainer.map((container) => {
            return data.data.load.filter((code) => {
              return container.code === code.code
            })
          }),
        )

        if (codesLoaded.length === 0 && exitGuide) {
          setDisableCancelGuide(false)
        }

        setCodesLoaded(
          findCodes.map((code) => {
            return code.code
          }),
        )

        // eslint-disable-next-line array-callback-return
        // const dataCODES = data.data.load.map((b) => {if (b.code > 0) return b.code})

        setExitGuide(guideFound !== undefined ? guideFound.exitGuide : 0)

        setInProgress(data.data.status === 'progress' ? true : false)
        setStatus(data.data.status)

        setSeats(data.data.load)
        setPatent(data.data.patent)
        findCodes.length === 0 ? setDisable(true) : setDisable(false)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  const deleteTrip = async () => {
    setLoading(true)

    await DISPATCH_SERVICES.deleteTrip(user.token, {trip: tripId}, guide.dispatch._id)
      .then((data) => {
        updateGuide(Object.assign(guide, {dispatch: data.data}))
        setLoading(false)
        setLoad(false)
        dispatch(
          actionsUI.addAlert({
            id: '1',
            title: `Camión eliminado de la guía`,
            color: 'success',
            iconType: 'check',
          }),
        )
      })
      .catch((e) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: e.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const generateGuide = async () => {
    setDisable(true)
    setLoading(true)
    await TRIPS_SERVICES.updateGuide(user.token, {entryGuide: guide.number, codes: codesLoaded}, tripId)
      .then((data) => {
        setGenerateButton(false)
        const guideFound = data.data.guides.find((g) => g.entryGuide === guide.number)
        setExitGuide(guideFound !== undefined ? guideFound.exitGuide : 0)
        DISPATCH_SERVICES.update(user.token, {trip: data.data._id}, guide.dispatch._id)
          .then((g) => {
            updateGuide(Object.assign(guide, {dispatch: g.data}))
            setDisable(false)
            setLoading(false)
          })
          .catch((err) => {
            console.log({err})
          })
        dispatch(
          actionsUI.addAlert({
            id: '1',
            title: `Guía #${guide.number} asignado a los códigos ingresados`,
            color: 'success',
            iconType: 'check',
          }),
        )
      })
      .catch((e) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: e.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const cancelExitGuide = async () => {
    setDisable(true)
    setLoading(true)
    await TRIPS_SERVICES.cancelGuide(user.token, {exitGuide, codes: codesLoaded}, tripId)
      .then((data) => {
        setGenerateButton(true)
        const guideFound = data.data.guides.find((g) => g.entryGuide === guide.number)
        setExitGuide(guideFound !== undefined ? guideFound.exitGuide : 0)
        setCommentary('')
        DISPATCH_SERVICES.update(user.token, {trip: data.data._id}, guide.dispatch._id)
          .then((g) => {
            updateGuide(Object.assign(guide, {dispatch: g.data}))
            codesLoaded.length === 0 ? setDisable(true) : setDisable(false)
            setLoading(false)
          })
          .catch((err) => {
            console.log({err})
          })

        dispatch(
          actionsUI.addAlert({
            id: '1',
            title: `Guía #${guide.number} asignado a los códigos ingresados`,
            color: 'success',
            iconType: 'check',
          }),
        )
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }
  // , commentary, entryGuide: guide.number

  const updateCommentary = async (val) => {
    setLoading(true)
    await TRIPS_SERVICES.updateCommentary(user.token, {commentary: val, entryGuide: guide.number}, tripId)
      .then(async (data) => {
        setLoading(false)
        //await updateGuide(Object.assign(guide, {dispatch: {trip: data.data}}))
        dispatch(actionsUI.addAlert({id: '1', title: 'Observación ingresada', color: 'success', iconType: 'check'}))
      })
      .catch((e) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: e.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const changeStatusTrip = async (operation) => {
    setDisable(true)
    const newStatus = operation === 'close' ? 'progress' : 'load'
    setLoading(true)
    await TRIPS_SERVICES.updateState(user.token, {status: newStatus}, tripId)
      .then(async (data) => {
        setLoading(false)
        setLoad(false)
        setStatus(newStatus)
        getGuides()
        setDisable(false)
        //await updateGuide(Object.assign(guide, {dispatch: {trip: data.data}}))
        dispatch(actionsUI.addAlert({id: '1', title: 'Viaje actualizado', color: 'success', iconType: 'check'}))
      })
      .catch((e) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: e.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const disassemble = async (code, nSeat) => {
    setDisableDismount(true)
    await TRIPS_SERVICES.update(user.token, {code: 0, exitGuide: 0, state: 'empty'}, tripId, nSeat)
      .then((data) => {
        getGuides()
        const findCodes = [].concat.apply(
          [],
          dataContainer.map((container) => {
            return data.data.load.filter((code) => {
              return container.code === code.code
            })
          }),
        )
        setCodesLoaded(findCodes.map((code) => code.code))
        setSeats(data.data.load)

        const guideFound = data.data.guides.find((g) => g.entryGuide === guide.number)
        setExitGuide(guideFound !== undefined ? guideFound.exitGuide : 0)
        findCodes.length === 0 ? setDisable(true) : setDisable(false)
        DRYING_SERVICES.statesContainers(user.token, {code: code, type: 'disassemble'}, drying._id)
          .then((data) => {
            handleAllLoad(false)
            updateGuide(Object.assign(guide, {drying: data.data}))
            dispatch(actionsUI.addAlert({id: '1', title: 'Carga desmontada', color: 'success', iconType: 'check'}))
            setDisableDismount(false)
          })
          .catch((error) => {
            dispatch(
              actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
            )
          })
      })
      .catch((err) => {
        console.log({err})
      })
  }

  return showCam ? (
    <>
      <EuiButton iconType='arrowLeft' onClick={() => setShowCam(false)}>
        Volver
      </EuiButton>
      <EuiButton
        iconType='editorRedo'
        onClick={() => (camera === 'user' ? setCamera('environment') : setCamera('user'))}
      >
        Cambiar cámara
      </EuiButton>

      <QrReader delay={800} onError={handleError} onScan={handleScan} style={{width: '100%'}} facingMode={camera} />
    </>
  ) : (
    <>
      {maskOpen ? modal : undefined}
      <EuiFlexGroup justifyContent='spaceBetween' style={{marginBottom: 20}}>
        <EuiFlexItem grow={false}>
          <EuiButton iconType='arrowLeft' onClick={() => setLoad(false)}>
            Volver
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {status === 'progress' || status === 'delivery' ? (
            <EuiButtonIcon
              iconSize='xl'
              color='danger'
              onClick={() => {
                changeMask(true)
              }}
              iconType='document'
            />
          ) : null}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {status === 'progress' || status === 'delivery' ? (
            <ApproveButton approve={changeStatusTrip} size={'s'} text={'Re abrir viaje'} />
          ) : null}
        </EuiFlexItem>
        {inProgress || status === 'delivery' ? null : (
          <>
            {exitGuide ? (
              <EuiFlexItem grow={false}>
                <EuiButton fill onClick={() => changeStatusTrip('close')} isDisabled={disable}>
                  Cerrar viaje
                </EuiButton>
              </EuiFlexItem>
            ) : null}
            {codesLoaded.length === 0 && !exitGuide ? (
              <EuiFlexItem grow={false}>
                <EuiButton color='danger' fill onClick={() => deleteTrip()}>
                  Eliminar viaje
                </EuiButton>
              </EuiFlexItem>
            ) : null}

            {generateButton ? (
              <EuiFlexItem grow={false}>
                <EuiButton fill onClick={() => generateGuide()} isDisabled={disable}>
                  Generar guía de despacho
                </EuiButton>
              </EuiFlexItem>
            ) : (
              <EuiFlexItem grow={false}>
                <EuiButton color='danger' fill onClick={() => cancelExitGuide()} isDisabled={disableCancelGuide}>
                  Anular guía de despacho
                </EuiButton>
              </EuiFlexItem>
            )}
            <EuiFlexItem grow={false}>
              <EuiButton fill onClick={() => newTrip()}>
                Nuevo viaje
              </EuiButton>
            </EuiFlexItem>
          </>
        )}
      </EuiFlexGroup>
      {status !== 'progress' || status !== 'delivery' ? (
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTextArea
              disabled={!exitGuide ? true : false}
              placeholder='Observación'
              fullWidth
              compressed
              name='commentary'
              onChange={(e) => onChangeCommentary(e)}
              value={commentary}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : null}
      <EuiSpacer size='s' />
      <EuiFlexGroup gutterSize='s'>
        <EuiFlexItem>
          <EuiPanel paddingSize='s' color='subdued' grow={false} style={{marginBottom: 10}}>
            <EuiStat
              title={
                status === 'load' ? 'Carga' : status === 'progress' ? 'Progreso' : status === 'delivery' ? 'Viaje' : ''
              }
              titleSize={'m'}
              titleColor='secondary'
              description='Estado'
              textAlign='left'
              isLoading={!status}
            ></EuiStat>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel paddingSize='s' color='subdued' grow={false} style={{marginBottom: 10}}>
            <EuiStat
              title={patent}
              titleSize={'m'}
              titleColor='secondary'
              description='Patente'
              textAlign='left'
              isLoading={!patent}
            ></EuiStat>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel paddingSize='s' color='subdued' grow={false} style={{marginBottom: 10}}>
            <EuiStat
              title={exitGuide}
              titleSize={'m'}
              titleColor={exitGuide ? 'secondary' : 'danger'}
              description='Guía de depacho'
              textAlign='left'
              isLoading={exitGuide === 0}
            ></EuiStat>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiPage>
        <EuiPageBody component='div'>
          {loading ? <EuiProgress size='xs' /> : null}
          <EuiPageContent
            horizontalPosition='center'
            style={{width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
          >
            <EuiPageContentBody style={{width: 300}}>
              <EuiFlexGrid columns={2} gutterSize='s'>
                {seats
                  ? seats.map((seat) => {
                      return (
                        <EuiFlexItem>
                          <EuiCard
                            style={seat.code !== 0 ? {backgroundColor: '#54B399'} : {backgroundColor: '#69707D'}}
                            title={seat.code}
                            description={`#${seat.seat}`}
                            onClick={() => checkCode(seat.code, seat.seat)}
                            footer={
                              status === 'load' && codesLoaded.find((c) => c === seat.code) ? (
                                <EuiButton
                                  fill
                                  color='warning'
                                  size='s'
                                  onClick={() => disassemble(seat.code, seat.seat)}
                                  isDisabled={disableDismount}
                                >
                                  Desmontar
                                </EuiButton>
                              ) : null
                            }
                          />
                        </EuiFlexItem>
                      )
                    })
                  : null}
              </EuiFlexGrid>
            </EuiPageContentBody>
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    </>
  )
}

export default Template
