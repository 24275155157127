import React, {useState} from 'react'
import {
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiFlexGroup,
  EuiFlexItem,
  EuiCard,
  EuiButtonIcon,
  EuiIcon,
} from '@elastic/eui'
import CheckContainers from './../CheckContainers'
import LoadContainers from './../LoadContainers'

const DispatchForm = ({guide}) => {
  const {drying} = guide
  const dataContainers = [].concat.apply([], drying.containers)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selected, setSelected] = useState('start')
  const [allChecked, setAllChecked] = useState(true)

  const closeModal = () => {
    setIsModalVisible(false)
    setSelected('start')
  }

  const showModal = async () => {
    setIsModalVisible(true)
    const checked = dataContainers.filter((item) => item.stateCheck === false)

    setAllChecked(checked.length === 0 ? false : true)
  }

  const handleSelected = (option) => {
    setSelected(option)
  }

  const handleAllChecked = (option) => {
    setAllChecked(option)
  }

  const formSample = (
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiCard
          icon={<EuiIcon size='xxl' type='sqlApp' />}
          title='Check'
          description='Revision de codigos para cada envase'
          onClick={() => handleSelected('check')}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          isDisabled={allChecked}
          icon={<EuiIcon size='xxl' type='spacesApp' />}
          title='Carga'
          description={'Ingreso de carga'}
          onClick={() => handleSelected('load')}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  const checkContainers = (
    <CheckContainers
      guide={guide}
      dataContainers={dataContainers}
      handleSelected={handleSelected}
      handleAllChecked={handleAllChecked}
    />
  )
  const loadContainers = (
    <LoadContainers
      guide={guide}
      dataContainers={dataContainers}
      handleSelected={handleSelected}
      closeModal={closeModal}
    />
  )

  let modal
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} maxWidth>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              {selected === 'check' ? 'Revisión de códigos' : selected === 'load' ? 'Carga' : ''}
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {selected === 'check' ? checkContainers : selected === 'load' ? loadContainers : formSample}
          </EuiModalBody>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <>
      {modal}

      <EuiButtonIcon onClick={showModal} iconType='reportingApp' iconSize='l' />
    </>
  )
}

export default DispatchForm
