import React, {useState, Fragment, useContext, useEffect} from 'react'
import {
  EuiBasicTable,
  EuiPagination,
  EuiSpacer,
  EuiText,
  EuiSuperSelect,
  EuiOverlayMask,
  EuiModalBody,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonIcon,
  EuiTitle,
  EuiHorizontalRule,
  EuiLoadingSpinner,
} from '@elastic/eui'
import moment from 'moment'
import objets from './objets'
import GUIDES_SERVICES from '../../../services/guides'
import QUALITY_SERVICES from '../../../services/quality'
import {DataContext} from './../../../context/DataContext'
import Excel from './Excel'
import Pdf from './../../Pdf'
import Filters from './../Filters'

const ControlProcess = () => {
  const {dispatch, actionsUI, user, useDebounce, isMobile} = useContext(DataContext)
  const [isResponsive] = useState(true)
  const [customHeader] = useState(true)
  const [sortField, setSortField] = useState('lot')
  const [sortDirection, setSortDirection] = useState('asc')
  const [pageOfItems, setPageOfItems] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [pageIndexPrevious, setPageIndexPrevious] = useState(0)
  const [pagePrevius, setPagePrvevius] = useState('')
  const [pageCount, setPageCount] = useState(0)
  const [activePage, setActivePage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [excelData, setExcelData] = useState([])
  const [pageNext, setPagenext] = useState('')
  const [maskOpen, changeMask] = useState(false)
  const [guides, setGuides] = useState([])
  const [guide, setGuide] = useState([])
  const [numberGuideFilter, setNumberGuideFilter] = useState('')
  const [exitNumberGuideFilter, setExitNumberGuideFilter] = useState('')
  const [selectedProducer, setSelectedProducer] = useState([])
  const [selectedExporter, setSelectedExporter] = useState([])
  const [startDate, setStartDate] = useState(moment().startOf('year'))
  const [endDate, setEndDate] = useState(moment().add(1, 'd'))
  const [loading, setLoading] = useState(false)
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [totals, setTotals] = useState([{totalWeightSum: 0, totalWeightWithContainer: 0}])
  const [guidePhotos, setGuidePhotos] = useState([])
  const [producerId, setProducerId] = useState('')
  const [exporterId, setExporterId] = useState('')
  const [qualityPhotos, setQualityPhotos] = useState([])
  let idProducer = ''
  let idExporter = ''
  useEffect(() => {
    getGuides('', numberGuideFilter, exitNumberGuideFilter, producerId, exporterId, startDate, endDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex])

  useEffect(() => {
    getGuides('*', numberGuideFilter, exitNumberGuideFilter, producerId, exporterId, startDate, endDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  const openPdf = (e) => {
    const guideFound = guides.filter((guide) => guide.lot === e.lot)
    if (guideFound) {
      getQualityPhotos(guideFound, e)
    }
  }

  /* const cleanIds = (producer, exporter) => {
    setProducerId(producer.length > 0 ? producer[0].id : '')
    setExporterId(exporter.length > 0 ? exporter[0].id : '')
  } */

  const debounceNumberGuide = useDebounce(
    (val, prod, exp, start, end) => getGuides('', val, exitNumberGuideFilter, prod, exp, start, end),
    1000,
  )

  const debounceExitNumber = useDebounce(
    (val, prod, exp, start, end) => getGuides('', numberGuideFilter, val, prod, exp, start, end),
    1000,
  )

  const onChangeNumberGuide = (e) => {
    setNumberGuideFilter(Number(e.target.value))
    //cleanIds(selectedProducer, selectedProducer)
    debounceNumberGuide(Number(e.target.value), producerId, exporterId, startDate, endDate)
  }

  const onChangeExitNumberGuide = (e) => {
    setExitNumberGuideFilter(Number(e.target.value))
    // cleanIds(selectedProducer, selectedProducer)
    debounceExitNumber(Number(e.target.value), producerId, exporterId, startDate, endDate)
  }

  const onChangeProducer = (itemSelected) => {
    setSelectedProducer(itemSelected)
    idProducer = itemSelected.length > 0 ? itemSelected[0].id : ''
    idExporter = selectedExporter.length > 0 ? selectedExporter[0].id : ''
    setProducerId(idProducer)
    getGuides('', numberGuideFilter, exitNumberGuideFilter, idProducer, idExporter, startDate, endDate)
  }

  const onChangeExporter = (itemSelected) => {
    setSelectedExporter(itemSelected)
    idProducer = selectedProducer.length > 0 ? selectedProducer[0].id : ''
    idExporter = itemSelected.length > 0 ? itemSelected[0].id : ''
    setExporterId(idExporter)
    getGuides('', numberGuideFilter, exitNumberGuideFilter, idProducer, idExporter, startDate, endDate)
  }

  const handleChangeStart = (date) => {
    setStartDate(date)
    idProducer = selectedProducer.length > 0 ? selectedProducer[0].id : ''
    idExporter = selectedExporter.length > 0 ? selectedExporter[0].id : ''
    getGuides('', numberGuideFilter, exitNumberGuideFilter, idProducer, idExporter, date, endDate)
  }

  const handleChangeEnd = (date) => {
    setEndDate(date)
    idProducer = selectedProducer.length > 0 ? selectedProducer[0].id : ''
    idExporter = selectedExporter.length > 0 ? selectedExporter[0].id : ''
    getGuides('', numberGuideFilter, exitNumberGuideFilter, idProducer, idExporter, startDate, date)
  }

  const closeMask = () => {
    changeMask(false)
    setGuidePhotos([])
    setQualityPhotos([])
  }

  const modal = (
    <React.Fragment>
      <EuiOverlayMask
        onClick={() => {
          closeMask()
        }}
      >
        <EuiModalBody
          style={isMobile ? {position: 'fixed', overflow: 'scroll', height: '90vh', margin: 'auto'} : {margin: 'auto'}}
        >
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon onClick={() => closeMask()} iconType='cross' iconSize='l' color='danger' />
            </EuiFlexItem>
          </EuiFlexGroup>
          <Pdf guide={guide} type={'final'} guidePhotos={guidePhotos} qualityPhotos={qualityPhotos} />
        </EuiModalBody>
      </EuiOverlayMask>
    </React.Fragment>
  )

  const columns = [
    // RECEPCION GD
    {
      name: '',
      width: '1.5%',
      render: (g) =>
        loadingPdf ? (
          <EuiLoadingSpinner size='m' />
        ) : (
          <EuiButtonIcon color='danger' iconType={'document'} onClick={() => openPdf(g)} />
        ),
    },
    {
      field: 'receptionDate',
      name: 'Fecha recepción',
      truncateText: true,
      sortable: true,
      mobileOptions: {
        show: !isResponsive || !customHeader,
      },
    },
    {
      field: 'number',
      name: 'Guía despacho',
      truncateText: true,
      dataType: 'number',
    },
    {
      field: 'exporter',
      name: 'Exportadora',
      truncateText: true,
      dataType: 'string',
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'producer',
      name: 'Productor',
      truncateText: true,
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'containerType',
      name: 'Tipo envase',
      truncateText: true,
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'containerTare',
      name: 'Tara',
      truncateText: true,
      dataType: 'number',
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'nContainer',
      name: '# Envase',
      truncateText: true,
      dataType: 'number',
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'weightGuide',
      name: 'Peso según GD',
      truncateText: true,
      dataType: 'number',
      mobileOptions: {
        show: true,
      },
    },
    // RECEPCION PLANTA
    {
      field: 'grossWeight',
      name: 'Peso bruto planta',
      truncateText: true,
      dataType: 'number',
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'receptionTare',
      name: 'Tara',
      truncateText: true,
      dataType: 'number',
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'netWeight',
      name: 'Peso neto verde',
      dataType: 'number',
      truncateText: true,
    },
    {
      field: 'weightDiff',
      name: 'Diferencia peso neto GD- Planta',
      dataType: 'number',
      truncateText: true,
    },
    {
      field: 'averageWeight',
      name: 'Peso neto promedio por envase',
      dataType: 'number',
      truncateText: true,
    },
    {
      field: 'nContainersDrying',
      name: 'Cantidad de envases',
      truncateText: true,
      dataType: 'number',
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'typeContainer',
      name: 'Tipo de envases',
      truncateText: true,
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'weightWithContainer',
      name: 'Peso c/envase',
      truncateText: true,
      dataType: 'number',
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'tareDrying',
      name: 'Tara',
      truncateText: true,
      dataType: 'number',
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'netWeightDrying',
      name: 'Peso neto',
      truncateText: true,
      dataType: 'number',
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'performance',
      name: 'Rendimiento',
      truncateText: true,
      dataType: 'number',
      render: (item) => `${item}%`,
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'endHumidity',
      name: 'Humedad de término',
      truncateText: true,
      dataType: 'number',
      render: (item) => `${item}%`,
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'dispatchContainers',
      name: 'Cantidad de envases',
      truncateText: true,
      dataType: 'number',
      mobileOptions: {
        show: true,
      },
    },

    {
      field: 'dispatchTypeContainer',
      name: 'Tipo de envases',
      truncateText: true,
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'dispatchNetWeightDrying',
      name: 'Peso neto',
      dataType: 'number',
      truncateText: true,
      mobileOptions: {
        show: true,
      },
    },
    {
      field: 'dispatchNumber',
      name: 'Guía despacho',
      truncateText: true,
      dataType: 'number',
    },
  ]

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  }

  const columnsTotal = [
    {
      field: 'totalWeightSum',
      name: 'Peso neto Total',
      truncateText: true,
      sortable: true,
      width: '10%',
      mobileOptions: {
        show: !isResponsive || !customHeader,
      },
    },
    {
      field: 'totalWeightWithContainer',
      name: 'Peso Total con envase',
      truncateText: true,
      sortable: true,
      width: '90%',
      mobileOptions: {
        show: !isResponsive || !customHeader,
      },
    },
  ]

  const goToPage = (pageNumber) => {
    setPageIndexPrevious(activePage)
    setPageIndex(pageNumber)
    setActivePage(pageNumber)
  }

  const onTableChange = ({page = {}, sort = {}}) => {
    const {index: pageIndex, size: pageSize} = page
    const {field: sortField, direction: sortDirection} = sort
    setPageIndex(pageIndex)
    setPageSize(pageSize)
    setSortField(sortField)
    setSortDirection(sortDirection)
  }

  const getPhotos = async (guideFound, id) => {
    setLoadingPdf(true)
    await GUIDES_SERVICES.getPhotos(user.token, id)
      .then((data) => {
        setGuidePhotos(data.data.guidePhoto)
        setGuide(guideFound[0])
        changeMask(true)
        setLoadingPdf(false)
      })
      .catch((err) => {
        console.log(err)
        setLoadingPdf(false)
      })
  }

  const getQualityPhotos = async (guideFound, e) => {
    setLoadingPdf(true)
    await QUALITY_SERVICES.getPhotos(user.token, e.qualityId)
      .then((data) => {
        setQualityPhotos(data.data.photos)
        getPhotos(guideFound, e.id)
      })
      .catch((err) => {
        console.log(err)
        setLoadingPdf(false)
      })
  }

  const getGuides = async (type, number, exitNumber, producer, exporter, start, end) => {
    setLoading(true)
    let next = ''
    let previous = ''
    if (type === '') {
      next = pageIndex > pageIndexPrevious ? pageNext : ''
      previous = pageIndex < pageIndexPrevious ? pagePrevius : ''
      setPageIndexPrevious(pageIndex)
    } else {
      setPageIndexPrevious(0)
      setPageIndex(0)
      setActivePage(0)
      next = ''
      previous = ''
    }
    await GUIDES_SERVICES.getAll(
      user.token,
      {types: ['admin', 'user', 'operator'], user: user.user, state: 'attended'},
      exporter ? exporter : '',
      producer ? producer : '',
      Number(number),
      Number(exitNumber),
      start ? start : '',
      end ? end : '',
      pageSize,
      next,
      previous,
      sortField,
    )
      .then((data) => {
        let datos = data.data
        let totalWeightSum = 0
        let totalWeightWithContainer = 0
        setGuides(datos.docs)

        const dataFormat = datos.docs.map((data) => {
          const dataContainers = [].concat.apply([], data.drying.containers)
          const measurements = [].concat.apply([], data.drying.measurements)
          const boxes = [].concat.apply([], data.drying?.boxes)
          const measureBox = boxes.map((box) => {
            return measurements.filter((measure) => measure.box === box.box)
          })

          let promMeasure = 0
          const lastMeasure = measureBox.map((t) => {
            return t.length === 0 ? 0 : (promMeasure = promMeasure + t.pop().value)
          })

          let weightSum = 0
          let measureSum = 0
          dataContainers.map((c) => (weightSum = weightSum + c.weightWithContainer - c.tare))
          measurements.map((c) => (measureSum = measureSum + c.value))
          const performance = (weightSum / (data.reception.grossWeight - data.reception.container.tare)) * 100
          let exGuides = ''
          data.dispatch.trip.map((doc) => {
            // eslint-disable-next-line array-callback-return
            return doc.guides.map((guide) => {
              if (data.number === guide.entryGuide) {
                return (exGuides = exGuides + ', ' + guide.exitGuide)
              }
             })
          })

          totalWeightSum = totalWeightSum + weightSum
          totalWeightWithContainer =
            totalWeightWithContainer + weightSum + dataContainers.length * dataContainers[0].container[0].tare
          setTotals([{totalWeightSum, totalWeightWithContainer}])

          return {
            receptionDate: moment(data.reception.createdAt).format('L'),
            number: data.number,
            receptionHour: moment(data.reception.createdAt).format('LT'),
            lot: data.lot,
            exporter: data.exporter ? data.exporter.name : null,
            producer: data.producer.name,
            containerType: data.reception.container.name,
            containerTare: data.reception.container.tare,
            nContainer: data.reception.numberContainer,
            weightGuide: data.products.quantity,
            grossWeight: data.reception.grossWeight,
            receptionTare: data.reception.numberContainer * data.reception.container.tare,
            netWeight: data.reception.grossWeight - data.reception.container.tare * data.reception.numberContainer,
            weightDiff:
              data.reception.grossWeight -
              data.reception.container.tare * data.reception.numberContainer -
              data.products.quantity,
            averageWeight:
              (data.reception.grossWeight - data.reception.container.tare) / data.reception.numberContainer,
            //Cantidad de envases
            nContainersDrying: dataContainers.length,
            //Tipo de envases
            typeContainer: dataContainers[0].container[0].type,
            //Peso c/envase
            weightWithContainer: weightSum + dataContainers.length * dataContainers[0].container[0].tare,
            //Drying tara
            tareDrying: dataContainers[0].container[0].tare,
            //Peso neto
            netWeightDrying: weightSum,
            //Rendimiento
            performance: performance.toFixed(2),
            //Humedad de término
            endHumidity: (promMeasure / lastMeasure.length).toFixed(1),
            //Cantidad de envases
            dispatchContainers: dataContainers.length,
            //Tipo de envases
            dispatchTypeContainer: dataContainers[0].container[0].type,
            //Peso neto
            dispatchNetWeightDrying: weightSum,
            dispatchNumber: exGuides.substring(1),
            isGuidePhoto: data.isGuidePhoto,
            id: data._id,
            qualityId: data.quality._id,
          }
        })
        setPageOfItems(dataFormat)
        setExcelData(dataFormat)
        if (dataFormat.length === 0) setTotals([{totalWeightSum: 0, totalWeightWithContainer: 0}])
        setPagenext(data.data.hasNext ? data.data.next : '')
        setPagePrvevius(data.data.hasPrevious ? data.data.previous : '')
        setPageCount(Math.ceil(datos.totalDocs / pageSize))
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  return (
    <Fragment>
      {maskOpen ? modal : undefined}
      <EuiSpacer size='m' />
      <Filters
        loading={loading}
        getGuides={getGuides}
        onChangeNumberGuide={onChangeNumberGuide}
        numberGuideFilter={numberGuideFilter}
        onChangeProducer={onChangeProducer}
        selectedProducer={selectedProducer}
        onChangeExporter={onChangeExporter}
        selectedExporter={selectedExporter}
        startDate={startDate}
        handleChangeStart={handleChangeStart}
        endDate={endDate}
        handleChangeEnd={handleChangeEnd}
        exitNumberGuideFilter={exitNumberGuideFilter}
        onChangeExitNumberGuide={onChangeExitNumberGuide}
      />
      <EuiSpacer size='m' />
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexItem grow={false}></EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Excel data={excelData} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size='m' />
      <EuiBasicTable
        loading={loading}
        items={pageOfItems}
        itemId='id'
        columns={columns}
        //pagination={pagination}
        sorting={sorting}
        //selection={selection}
        isSelectable={true}
        hasActions={true}
        responsive={true}
        onChange={onTableChange}
        noItemsMessage='Sin resultados'
      />

      <EuiSpacer size='m' />
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', alignItems: 'center', width: '50%'}}>
          <EuiText size='m'>Lineas por página</EuiText>
          <EuiSuperSelect
            options={objets.options}
            valueOfSelected={pageSize}
            onChange={(value) => {
              setPageSize(value)
            }}
            style={{marginLeft: 10}}
            compressed
          />
        </div>
        <div style={{width: '50%', justifyContent: 'flex-end'}}>
          <EuiPagination
            pageCount={pageCount}
            activePage={activePage}
            onPageClick={(activePage) => goToPage(activePage)}
            compressed
            style={{justifyContent: 'flex-end'}}
          />
        </div>
      </div>
      <EuiSpacer size='m' />
      <EuiHorizontalRule margin='xs' />
      <EuiTitle size='s'>
        <h1>Totales despachados</h1>
      </EuiTitle>
      <EuiBasicTable
        loading={loading}
        items={totals}
        itemId='id2'
        columns={columnsTotal}
        //pagination={pagination}
        sorting={sorting}
        //selection={selection}
        isSelectable={true}
        hasActions={true}
        responsive={true}
        onChange={onTableChange}
      />
    </Fragment>
  )
}

export default ControlProcess
