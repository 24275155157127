import { AUTH_TYPES } from '../actions/authActions';

const INITIAL_STATE = {
    logged: false,
    user: null,
};

export const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH_TYPES.AUTH_LOGIN:
            return {
                ...state,
                user: null,
            }
        case AUTH_TYPES.AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                logged: true,
                ...action
            };
        case AUTH_TYPES.AUTH_LOGIN_OFF:
            return {
                ...state,
                user: null,
                logged: false,
            }
        default:
            return state;
    }
}