import API from './constants'

var USERS_SERVICES = {
  getAll: async (token, limit, next, previous, sortField, types, searchName, searchEmail, searchRut) => {
    return await API.post(
      `user/all?limit=${limit}&next=${next}&previous=${previous}&sortField=${sortField}&filterName=${searchName}&filterEmail=${searchEmail}&filterRut=${searchRut}`,
      {types},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        contentType: true,
      },
    )
  },
  getOne: async (token, id) => {
    return await API.get(`user/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  sendMail: async (token, id) => {
    return await API.post(
      `user/${id}/send-mail`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        contentType: true,
      },
    )
  },
  create: async (token, data) => {
    return await API.post(`user`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  update: async (token, data, id) => {
    return await API.put(`user/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  edit: async (token, data, id) => {
    return await API.put(`user/edit/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  delete: async (id, token) => {
    return await API.delete(`user/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
}

export default USERS_SERVICES
