import React from 'react'
import {Chart, Partition} from '@elastic/charts'
const PieChart = ({data, euiChartTheme, euiPartitionConfig}) => {
  return (
    <Chart size={{height: 250}}>
      <Partition
        id={1}
        data={data}
        valueAccessor={(d) => Number(d.total)}
        valueFormatter={(d) => `${d.toFixed(1)}%`}
        layers={[
          {
            groupByRollup: (d) => d.name,
            shape: {
              fillColor: (d) => euiChartTheme.colors.vizColors[d.sortIndex],
            },
          },
        ]}
        config={{
          ...euiPartitionConfig,
          linkLabel: {maximumSection: Infinity},
          clockwiseSectors: false,
        }}
      />
    </Chart>
  )
}

export default PieChart
