import API from './constants'

var DRYING_SERVICES = {
  create: async (token, data) => {
    return await API.post(`drying`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  addMeasure: async (token, data, id) => {
    return await API.post(`drying/measure/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  addBox: async (token, data, id) => {
    return await API.put(`drying/box/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  editBox: async (token, data, id) => {
    return await API.put(`drying/editBox/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  deleteBox: async (token, data, id) => {
    return await API.put(`drying/deleteBox/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  statesContainers: async (token, data, id) => {
    return await API.put(`drying/states-containers/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  update: async (token, data, id, operation, containerId) => {
    return await API.put(`drying/${id}/${operation}/${containerId}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  updateState: async (token, data, id) => {
    return await API.put(`drying/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  getData: async (token, types) => {
    return await API.post(`drying/data`, types, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
}

export default DRYING_SERVICES
