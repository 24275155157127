import React, {useState, useContext, useEffect} from 'react'
import {
  EuiBasicTable,
  EuiPagination,
  EuiSpacer,
  EuiText,
  EuiSuperSelect,
  EuiOverlayMask,
  EuiModalBody,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonIcon,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiButton,
  EuiForm,
  EuiProgress,
  EuiCheckableCard,
} from '@elastic/eui'
import moment from 'moment'
import objets from './objets'
import GUIDES_SERVICES from '../../../services/guides'
import QUALITY_SERVICES from '../../../services/quality'
import {DataContext} from './../../../context/DataContext'
import Pdf from './../../Pdf'
import Filters from './../Filters'

const PdfReport = () => {
  const {dispatch, actionsUI, user, useDebounce, isMobile} = useContext(DataContext)
  const [loading, setLoading] = useState(false)
  const [pageOfItems, setPageOfItems] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [pageIndexPrevious, setPageIndexPrevious] = useState(0)
  const [pagePrevius, setPagePrvevius] = useState('')
  const [pageCount, setPageCount] = useState(0)
  const [activePage, setActivePage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [isResponsive] = useState(true)
  const [customHeader] = useState(true)
  const [sortField, setSortField] = useState('lot')
  const [sortDirection, setSortDirection] = useState('asc')
  const [pageNext, setPagenext] = useState('')
  const [guide, setGuide] = useState([])
  const [maskOpen, changeMask] = useState(false)
  const [type, setType] = useState('initialState')
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false)
  const [checkReception, setCheckReception] = useState(false)
  const [checkQuality, setCheckQuality] = useState(false)
  const [checkDrying, setCheckDrying] = useState(false)
  const [checkDispatch, setCheckDispatch] = useState(false)
  const [state, setState] = useState(0)
  const [exitNumberGuideFilter, setExitNumberGuideFilter] = useState('')
  const [selectedProducer, setSelectedProducer] = useState([])
  const [selectedExporter, setSelectedExporter] = useState([])
  const [startDate, setStartDate] = useState(moment().startOf('year'))
  const [endDate, setEndDate] = useState(moment().add(1, 'd'))
  const [producerId, setProducerId] = useState('')
  const [exporterId, setExporterId] = useState('')
  const [numberGuideFilter, setNumberGuideFilter] = useState('')
  const [guidePhotos, setGuidePhotos] = useState([])
  const [qualityPhotos, setQualityPhotos] = useState([])
  let idProducer = ''
  let idExporter = ''

  useEffect(() => {
    getGuides('', numberGuideFilter, exitNumberGuideFilter, producerId, exporterId, startDate, endDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex])

  useEffect(() => {
    getGuides('*', numberGuideFilter, exitNumberGuideFilter, producerId, exporterId, startDate, endDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  }

  /* const cleanIds = (producer, exporter) => {
    setProducerId(producer.length > 0 ? producer[0].id : '')
    setExporterId(exporter.length > 0 ? exporter[0].id : '')
  } */

  const debounceNumberGuide = useDebounce(
    (val, prod, exp, start, end) => getGuides('', val, exitNumberGuideFilter, prod, exp, start, end),
    1000,
  )

  const debounceExitNumber = useDebounce(
    (val, prod, exp, start, end) => getGuides('', numberGuideFilter, val, prod, exp, start, end),
    1000,
  )

  const onChangeNumberGuide = (e) => {
    setNumberGuideFilter(Number(e.target.value))
    //cleanIds(selectedProducer, selectedProducer)
    debounceNumberGuide(Number(e.target.value), producerId, exporterId, startDate, endDate)
  }

  const onChangeExitNumberGuide = (e) => {
    setExitNumberGuideFilter(Number(e.target.value))
    // cleanIds(selectedProducer, selectedProducer)
    debounceExitNumber(Number(e.target.value), producerId, exporterId, startDate, endDate)
  }

  const onChangeProducer = (itemSelected) => {
    setSelectedProducer(itemSelected)
    idProducer = itemSelected.length > 0 ? itemSelected[0].id : ''
    idExporter = selectedExporter.length > 0 ? selectedExporter[0].id : ''
    setProducerId(idProducer)
    getGuides('', numberGuideFilter, exitNumberGuideFilter, idProducer, idExporter, startDate, endDate)
  }

  const onChangeExporter = (itemSelected) => {
    setSelectedExporter(itemSelected)
    idProducer = selectedProducer.length > 0 ? selectedProducer[0].id : ''
    idExporter = itemSelected.length > 0 ? itemSelected[0].id : ''
    setExporterId(idExporter)
    getGuides('', numberGuideFilter, exitNumberGuideFilter, idProducer, idExporter, startDate, endDate)
  }

  const handleChangeStart = (date) => {
    setStartDate(date)
    idProducer = selectedProducer.length > 0 ? selectedProducer[0].id : ''
    idExporter = selectedExporter.length > 0 ? selectedExporter[0].id : ''
    getGuides('', numberGuideFilter, exitNumberGuideFilter, idProducer, idExporter, date, endDate)
  }

  const handleChangeEnd = (date) => {
    setEndDate(date)
    idProducer = selectedProducer.length > 0 ? selectedProducer[0].id : ''
    idExporter = selectedExporter.length > 0 ? selectedExporter[0].id : ''
    getGuides('', numberGuideFilter, exitNumberGuideFilter, idProducer, idExporter, startDate, date)
  }

  const onTableChange = ({page = {}, sort = {}}) => {
    const {index: pageIndex, size: pageSize} = page
    const {field: sortField, direction: sortDirection} = sort
    setPageIndex(pageIndex)
    setPageSize(pageSize)
    setSortField(sortField)
    setSortDirection(sortDirection)
  }

  const goToPage = (pageNumber) => {
    setPageIndexPrevious(activePage)
    setPageIndex(pageNumber)
    setActivePage(pageNumber)
  }
  const openReception = (e) => {
    setType('reception')
    if (e.isGuidePhoto) {
      getPhotos(e)
    } else {
      setGuide(e)
      changeMask(true)
    }
  }

  const openQuality = (e) => {
    setType('quality')
    setGuide(e)
    getQualityPhotos(e, 'quality')
  }

  const openDrying = (e) => {
    setType('dispatch')
    setGuide(e)
    changeMask(true)
  }

  const openDispatch = (e) => {
    setType('attended')
    setGuide(e)
    changeMask(true)
  }

  const openFinal = (e) => {
    setType('final')
    setGuide(e)
    getQualityPhotos(e, 'final')
  }

  const sendEmail = (e) => {
    setIsEmailModalVisible(true)
    setGuide(e)
    setState(e.state)
  }

  const actions = [
    {
      name: 'Recepcion',
      description: 'reception',
      icon: 'email',
      type: 'icon',
      onClick: sendEmail,
    },
  ]

  const columns = [
    {
      field: 'number',
      name: 'N° Guía',
      truncateText: true,
      sortable: true,
      mobileOptions: {
        show: !isResponsive || !customHeader,
      },
    },

    {
      field: 'producer.name',
      name: 'Productor',
      truncateText: true,
      sortable: true,
      mobileOptions: {
        show: !isResponsive || !customHeader,
      },
    },
    {
      field: 'exporter.name',
      name: 'Exportador',
      truncateText: true,
      sortable: true,
      mobileOptions: {
        show: !isResponsive || !customHeader,
      },
    },
    {
      name: 'Recepción',
      render: (g) =>
        g.state === 'quality' || g.state === 'drying' || g.state === 'dispatch' || g.state === 'attended' ? (
          <EuiButtonIcon color='danger' iconType={'document'} onClick={() => openReception(g)} />
        ) : null,
      mobileOptions: {
        show: true,
      },
    },
    {
      name: 'Calidad',
      render: (g) =>
        g.state === 'drying' || g.state === 'dispatch' || g.state === 'attended' ? (
          <EuiButtonIcon color='danger' iconType={'document'} onClick={() => openQuality(g)} />
        ) : null,
    },
    {
      name: 'Secado',
      render: (g) =>
        g.state === 'dispatch' || g.state === 'attended' ? (
          <EuiButtonIcon color='danger' iconType={'document'} onClick={() => openDrying(g)} />
        ) : null,
    },
    {
      name: 'Despacho',
      render: (g) =>
        g.state === 'attended' ? (
          <EuiButtonIcon color='danger' iconType={'document'} onClick={() => openDispatch(g)} />
        ) : null,
    },
    {
      name: 'Final',
      render: (g) =>
        g.state === 'attended' ? (
          <EuiButtonIcon color='danger' iconType={'document'} onClick={() => openFinal(g)} />
        ) : null,
    },
    {
      actions,
    },
  ]

  const modal = (
    <React.Fragment>
      <EuiOverlayMask
        onClick={() => {
          closeMask()
        }}
      >
        <EuiModalBody
          style={isMobile ? {position: 'fixed', overflow: 'scroll', height: '90vh', margin: 'auto'} : {margin: 'auto'}}
        >
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon onClick={() => closeMask()} iconType='cross' iconSize='xl' color='danger' />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='s' />
            <Pdf guide={guide} type={type} guidePhotos={guidePhotos} qualityPhotos={qualityPhotos} />
          </EuiModalBody>
      </EuiOverlayMask>
    </React.Fragment>
  )

  const closeMask = () => {
    changeMask(false)
    setGuidePhotos([])
    setQualityPhotos([])
  }

  const getPhotos = async (e) => {
    setLoading(true)
    await GUIDES_SERVICES.getPhotos(user.token, e.id)
      .then((data) => {
        setGuidePhotos(data.data.guidePhoto)
        setGuide(e)
        changeMask(true)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getQualityPhotos = async (e, t) => {
    setLoading(true)
    await QUALITY_SERVICES.getPhotos(user.token, e.quality._id)
      .then((data) => {
        setQualityPhotos(data.data.photos)
        if (t === 'quality') {
          setType('quality')
          setLoading(false)
          changeMask(true)
        } else if (t === 'final') {
          getPhotos(e)
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const getGuides = async (type, number, exitNumber, producer, exporter, start, end) => {
    setLoading(true)
    let next = ''
    let previous = ''
    if (type === '') {
      next = pageIndex > pageIndexPrevious ? pageNext : ''
      previous = pageIndex < pageIndexPrevious ? pagePrevius : ''

      setPageIndexPrevious(pageIndex)
    } else {
      setPageIndexPrevious(0)
      setPageIndex(0)
      setActivePage(0)
      next = ''
      previous = ''
    }
    await GUIDES_SERVICES.getAll(
      user.token,
      {types: ['admin'], user: user.user},
      exporter ? exporter : '',
      producer ? producer : '',
      Number(number),
      Number(exitNumber),
      start ? start : '',
      end ? end : '',
      pageSize,
      next,
      previous,
      sortField,
    )
      .then((data) => {
        setPageOfItems(data.data.docs)
        setPagenext(data.data.hasNext ? data.data.next : '')
        setPagePrvevius(data.data.hasPrevious ? data.data.previous : '')
        setPageCount(Math.ceil(data.data.totalDocs / pageSize))
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const send = async () => {
    setLoading(true)
    const reports = {reception: checkReception, quality: checkQuality, drying: checkDrying, dispatch: checkDispatch}

    await GUIDES_SERVICES.reports(user.token, reports, guide._id)
      .then((data) => {
        setLoading(false)
        setCheckReception(false)
        setCheckQuality(false)
        setCheckDrying(false)
        setCheckDispatch(false)
        closeModal(true)
        dispatch(actionsUI.addAlert({id: '1', title: 'Reportes enviados', color: 'success', iconType: 'check'}))
      })
      .catch((e) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: 1, title: e.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const closeModal = () => {
    setIsEmailModalVisible(false)
    setCheckReception(false)
    setCheckQuality(false)
    setCheckDrying(false)
    setCheckDispatch(false)
  }

  const formSample = (
    <EuiForm style={{maxWidth: 340}}>
      <EuiCheckableCard
        id={1}
        label='Recepción'
        checkableType='checkbox'
        value='reception'
        checked={checkReception}
        onChange={() => setCheckReception(!checkReception)}
        disabled={
          state === 'quality' || state === 'drying' || state === 'dispatch' || state === 'attended' ? false : true
        }
      />
      <EuiCheckableCard
        id={2}
        label='Calidad'
        checkableType='checkbox'
        value='quality'
        checked={checkQuality}
        onChange={() => setCheckQuality(!checkQuality)}
        disabled={state === 'drying' || state === 'dispatch' || state === 'attended' ? false : true}
      />
      <EuiCheckableCard
        id={3}
        label='Secado'
        checkableType='checkbox'
        value='drying'
        checked={checkDrying}
        onChange={() => setCheckDrying(!checkDrying)}
        disabled={state === 'dispatch' || state === 'attended' ? false : true}
      />
      <EuiCheckableCard
        id={4}
        label='Despacho'
        checkableType='checkbox'
        value='dispatch'
        checked={checkDispatch}
        onChange={() => setCheckDispatch(!checkDispatch)}
        disabled={state === 'attended' ? false : true}
      />
    </EuiForm>
  )

  let emailModal
  if (isEmailModalVisible) {
    emailModal = (
      <EuiOverlayMask>
        <EuiModal maxWidth={false} onClose={closeModal} initialFocus='[name=popswitch]'>
          <EuiModalHeader>
            {loading ? <EuiProgress size='xs' position='absolute' /> : null}
            <EuiModalHeaderTitle>Envio de reportes - Guía #{guide.number}</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>{formSample}</EuiModalBody>

          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeModal}>Salir</EuiButtonEmpty>

            <EuiButton onClick={send} fill>
              Enviar
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <>
      {maskOpen ? modal : undefined}
      {emailModal}
      <EuiSpacer size='m' />
      <Filters
        loading={loading}
        getGuides={getGuides}
        onChangeNumberGuide={onChangeNumberGuide}
        numberGuideFilter={numberGuideFilter}
        onChangeProducer={onChangeProducer}
        selectedProducer={selectedProducer}
        onChangeExporter={onChangeExporter}
        selectedExporter={selectedExporter}
        startDate={startDate}
        handleChangeStart={handleChangeStart}
        endDate={endDate}
        handleChangeEnd={handleChangeEnd}
        exitNumberGuideFilter={exitNumberGuideFilter}
        onChangeExitNumberGuide={onChangeExitNumberGuide}
      />
      <EuiSpacer size='m' />
      <EuiBasicTable
        loading={loading}
        items={pageOfItems}
        itemId='id'
        columns={columns}
        //pagination={pagination}
        sorting={sorting}
        //selection={selection}
        isSelectable={true}
        hasActions={true}
        responsive={true}
        onChange={onTableChange}
      />
      <EuiSpacer size='m' />
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', alignItems: 'center', width: '50%'}}>
          <EuiText size='m'>Lineas por página</EuiText>
          <EuiSuperSelect
            options={objets.options}
            valueOfSelected={pageSize}
            onChange={(value) => {
              setPageSize(value)
            }}
            style={{marginLeft: 10}}
            compressed
          />
        </div>
        <div style={{width: '50%', justifyContent: 'flex-end'}}>
          <EuiPagination
            pageCount={pageCount}
            activePage={activePage}
            onPageClick={(activePage) => goToPage(activePage)}
            compressed
            style={{justifyContent: 'flex-end'}}
          />
        </div>
      </div>
    </>
  )
}

export default PdfReport
