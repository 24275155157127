import React, {useMemo, useContext} from 'react'
import {Page, Document, StyleSheet, PDFViewer, View, Image, BlobProvider} from '@react-pdf/renderer'
import {Document as Doc, Page as Pag} from 'react-pdf/dist/umd/entry.webpack'
import {DataContext} from './../../context/DataContext'
import Reception from './Reception'
import Quality from './Quality'
import Drying from './Drying'
import Dispatch from './Dispatch'
import Trip from './Trip'
import OneTrip from './OneTrip'

const Pdf = ({guide, type, tripId, guidePhotos, qualityPhotos}) => {
  const {isMobile} = useContext(DataContext)
  const {quality, dispatch} = guide
  const styles = StyleSheet.create({
    guidePhoto: {
      width: '90%',
      padding: 10,
    },
  })

  const MyDocument = () => (
    <Document>
      {type === 'reception' || type === 'user' || type === 'final' ? (
        <>
          <Page>
            <Reception data={guide} />
          </Page>

          {guidePhotos
            ? Object.keys(guidePhotos).map((item, i) => {
                return (
                  <Page>
                    <View>
                      <Image style={styles.guidePhoto} source={{uri: `${guidePhotos}`}} />
                    </View>
                  </Page>
                )
              })
            : null}
        </>
      ) : null}

      {type === 'quality' || (type === 'user' && guide.quality) || type === 'final' ? (
        <Page>
          <Quality data={guide} qualityPhotos={qualityPhotos} />
        </Page>
      ) : null}

      {type === 'dispatch' || (type === 'user' && guide.dispatch) || type === 'final' ? (
        <>
          <Page>
            <Drying data={guide} />
          </Page>
        </>
      ) : null}

      {type === 'attended' || (type === 'user' && guide.dispatch) || type === 'final' ? (
        <>
          <Page>
            <Dispatch data={guide} />
          </Page>

          <Trip data={guide} />
        </>
      ) : null}

      {type === 'trip' ? (
        <>
          <OneTrip data={guide} tripId={tripId} />
        </>
      ) : null}
    </Document>
  )

  /* <PDFDownloadLink document={<MyDocument />} fileName='informe.pdf'>
          {({_blob, _url, loading, _error}) =>
            loading ? (
              'Cargando documento...'
            ) : (
              <EuiButton style={{color: '#006636'}} size='xs' iconType='documents'>
                Descargar PDF
              </EuiButton>
            )
          }
        </PDFDownloadLink> */

  return useMemo(
    () =>
      isMobile ? (
        <BlobProvider document={<MyDocument />}>
          {({blob, url, loading}) => {
            let r = 0
            let q = 0
            let d = 0
            if (type === 'reception' || type === 'user' || type === 'final') r = guidePhotos.length + 1
            if (type === 'quality' || (type === 'user' && quality) || type === 'final') q = qualityPhotos.length + 1
            if (type === 'attended' || (type === 'user' && dispatch) || type === 'final') d = dispatch.trip.length + 1

            return loading ? (
              'Cargando...'
            ) : (
              <Doc file={url} renderMode='svg'>
                {type === 'reception' ? (
                  Array.from(new Array(r), (el, index) => (
                    <Pag key={`page_${index + 1}`} pageNumber={index + 1} width={window.innerWidth * 0.8} />
                  ))
                ) : type === 'quality' ? (
                  Array.from(new Array(q), (el, index) => (
                    <Pag key={`page_${index + 1}`} pageNumber={index + 1} width={window.innerWidth * 0.8} />
                  ))
                ) : type === 'dispatch' ? (
                  <Pag key={`page_1`} pageNumber={1} width={window.innerWidth} />
                ) : type === 'attended' ? (
                  Array.from(new Array(d), (el, index) => (
                    <Pag key={`page_${index + 1}`} pageNumber={index + 1} width={window.innerWidth * 0.8} />
                  ))
                ) : type === 'trip' ? (
                  <Pag key={`page_1`} pageNumber={1} width={window.innerWidth} />
                ) : type === 'final' ? (
                  Array.from(new Array(r + q + d + 1), (el, index) => (
                    <Pag key={`page_${index + 1}`} pageNumber={index + 1} width={window.innerWidth * 0.8} />
                  ))
                ) : type === 'user' ? (
                  Array.from(new Array(r + q + d), (el, index) => (
                    <Pag key={`page_${index + 1}`} pageNumber={index + 1} width={window.innerWidth * 0.8} />
                  ))
                ) : null}
              </Doc>
            )
          }}
        </BlobProvider>
      ) : (
        <PDFViewer style={{width: '100%', height: ' 100vh'}}>
          <MyDocument />
        </PDFViewer>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
}

export default Pdf
