import React, {useState, useContext} from 'react'
import {EuiButtonIcon, EuiOverlayMask, EuiImage, EuiLoadingSpinner} from '@elastic/eui'
import 'react-html5-camera-photo/build/css/index.css'
import {DataContext} from './../../context/DataContext'
import GUIDES_SERVICES from './../../services/guides'

const ShowGuidePhoto = ({id, state}) => {
  const {user} = useContext(DataContext)
  const [maskOpenPhotos, changeMaskPhotos] = useState(false)
  const [guidePhotos, setGuidePhotos] = useState([])
  const [loading, setLoading] = useState(false)

  const modalPhotos = (
    <EuiOverlayMask
      onClick={() => {
        changeMaskPhotos(false)
      }}
    >
      {Object.keys(guidePhotos).map((item, i) => {
        return (
          <>
            <EuiImage size='s' hasShadow allowFullScreen url={guidePhotos[item]} />
          </>
        )
      })}
    </EuiOverlayMask>
  )

  const getPhotos = async () => {
    setLoading(true)
    await GUIDES_SERVICES.getPhotos(user.token, id)
      .then((data) => {
        setGuidePhotos(data.data.guidePhoto)
        openPhotos()
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const openPhotos = () => {
    changeMaskPhotos(true)
  }
  console.log(state)

  return (
    <>
      {maskOpenPhotos ? modalPhotos : undefined}
      {loading ? (
        <EuiLoadingSpinner size='m' />
      ) : (
        <EuiButtonIcon color='#1D1E24' onClick={getPhotos} iconType='notebookApp' iconSize='l' />
      )}
    </>
  )
}

export default ShowGuidePhoto
