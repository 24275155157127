import React, {useState, useEffect, useContext} from 'react'
import {EuiBeacon, EuiPopover, EuiText} from '@elastic/eui'
import {DataContext} from './../../context/DataContext'

const AlertBeacon = ({drying, alertHumidity, handleAlertHumidity}) => {
  const {humidity} = useContext(DataContext)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen)
  const closePopover = () => setIsPopoverOpen(false)

  const measurements = [].concat.apply([], drying?.measurements)
  const boxes = [].concat.apply([], drying?.boxes)

  useEffect(() => {
    if (drying.boxes.length > 0) {
      const test = boxes.map((box) => {
        return measurements.filter((measure) => measure.box === box.box)
      })

      const test2 = test.map((t) => {
        return t.pop()
      })

      test2.filter((t) => t?.value && t.value < humidity).length > 0
        ? handleAlertHumidity(true)
        : handleAlertHumidity(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const button = <EuiBeacon style={{backgroundColor: 'red', marginLeft: 5}} onClick={onButtonClick} />

  return alertHumidity ? (
    <EuiPopover ownFocus button={button} isOpen={isPopoverOpen} closePopover={closePopover}>
      <EuiText>
        <p>Niveles de humedad bajo</p>
      </EuiText>
    </EuiPopover>
  ) : null
}

export default AlertBeacon
