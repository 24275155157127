import React, {useState, useContext} from 'react'
import {
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiCollapsibleNavGroup,
  EuiFieldNumber,
  EuiTextArea,
  EuiDatePicker,
  EuiHorizontalRule,
  EuiSuperSelect,
  EuiButtonIcon,
  EuiProgress,
} from '@elastic/eui'
import moment from 'moment'
import {validate} from 'validate.js'
import {constraintsEs} from './validate'
import 'moment/locale/es'
import * as actionsUI from '../../store/actions/uiActions'
import RECEPTIONS_SERVICES from './../../services/receptions'
import CONTAINERS_SERVICES from './../../services/containers'
import GUIDES_SERVICES from './../../services/guides'
import {DataContext} from './../../context/DataContext'
import FooterFormReport from './../FooterFormReport'

const ReceptionReport = ({guide}) => {
  const {updateGuide, deleteGuide, addGuide, user, dispatch} = useContext(DataContext)
  const {exporter, number, createdAt, lot, producer, reception, _id} = guide
  const [isModalVisibleReport, setIsModalVisibleReport] = useState(false)
  const [errors, setErrors] = useState({})
  const [container, setContainer] = useState([])
  const [tare, setTare] = useState('')
  const [receptionId, setReceptionId] = useState('')
  const [numberContainer, setNumberContainer] = useState('')
  const [grossWeight, setGrossWeight] = useState('')
  const [commentary, setCommentary] = useState('')
  const [selectedOptionsContainerType, setSelectedOptionsContainerType] = useState([])
  const [isDisabled, setIsDisabled] = useState(true)
  const [showApprovalPanel, setShowApprovalPanel] = useState(false)
  const [loading, setLoading] = useState(false)

  let containerType = []
  const showModalReport = async () => {
    setIsModalVisibleReport(true)
    if (user.user.type !== 'user') {
      setIsDisabled(false)
    }

    if (reception) {
      setShowApprovalPanel(true)
      setReceptionId(reception._id)
      setTare(reception.container.tare)
      setNumberContainer(reception.numberContainer)
      setGrossWeight(reception.grossWeight)
      setCommentary(reception.commentary)
      setSelectedOptionsContainerType(reception.container.name)
    } else {
      console.log('no tiene reception report')
    }

    getContainers()
  }

  const getContainers = async () => {
    await CONTAINERS_SERVICES.getAllList(user.token)
      .then((data) => {
        containerType = data.data.map((c) => ({tare: c.tare, value: c.name, inputDisplay: c.name, disabled: false}))

        setContainer(containerType)
      })
      .catch((error) => {
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }
  const closeModal = () => {
    setIsModalVisibleReport(false)
  }

  const onChangeTare = (e) => setTare(e.target.value)
  const onChangeNumberContainer = (e) => setNumberContainer(e.target.value)
  const onChangeGrossWeight = (e) => setGrossWeight(e.target.value <= 0 ? '' : e.target.value)
  const onChangeCommentary = (e) => setCommentary(e.target.value)
  const onChangeContainerType = (selectedOptions) => {
    setSelectedOptionsContainerType(selectedOptions)
    setTare(container.filter((c) => c.value === selectedOptions)[0].tare)
  }
  const create = async () => {
    setLoading(true)
    const form = {
      container: {
        name: selectedOptionsContainerType,
        tare: Number(tare),
      },
      selectedContainer: selectedOptionsContainerType,
      selectedTare: tare,
      numberContainer: Number(numberContainer),
      grossWeight: Number(grossWeight),
      netWeight: grossWeight - tare * numberContainer,
      userId: user.user._id,
      commentary,
      guideId: _id,
    }

    let validationResult
    validationResult = validate(form, constraintsEs)
    if (validationResult !== undefined) {
      setErrors(validationResult)
      setLoading(false)

      return
    }

    setErrors({})
    if (!reception) {
      await RECEPTIONS_SERVICES.create(user.token, form)
        .then((data) => {
          setReceptionId(data.data._id)
          showModalReport(true)
          setIsModalVisibleReport(true)
          setShowApprovalPanel(true)
          updateGuide(Object.assign(guide, {reception: data.data}))
          setLoading(false)

          dispatch(
            actionsUI.addAlert({id: '1', title: 'Reporte de recepción ingresado', color: 'success', iconType: 'check'}),
          )
        })
        .catch((error) => {
          setLoading(false)
          dispatch(
            actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
          )
        })
    } else {
      await RECEPTIONS_SERVICES.update(user.token, form, receptionId)
        .then((data) => {
          setLoading(false)
          updateGuide(Object.assign(guide, {reception: data.data}))

          dispatch(actionsUI.addAlert({id: '1', title: 'Reporte actualizado', color: 'success', iconType: 'check'}))
        })
        .catch((error) => {
          setLoading(false)
          dispatch(
            actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
          )
        })
    }
  }

  const approve = async () => {
    setLoading(true)
    await RECEPTIONS_SERVICES.update(user.token, {state: 'sent', approvalDate: new Date(Date.now())}, receptionId)
      .then((data) => {
        dispatch(actionsUI.addAlert({id: '1', title: 'Reporte aprobado', color: 'success', iconType: 'check'}))
      })
      .catch((err) => {
        setLoading(false)
        console.log({err})
      })

    await GUIDES_SERVICES.update(user.token, {state: 'quality'}, _id)
      .then((data) => {
        deleteGuide(data.data)
        addGuide(data.data)
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: 'Guia enviada a Calidad', color: 'success', iconType: 'check'}))
      })
      .catch((err) => {
        setLoading(false)
        console.log({err})
      })
    setShowApprovalPanel(false)
  }

  const formSample = (
    <EuiForm>
      <EuiFlexGroup responsive={true}>
        <EuiFlexItem>
          <EuiCollapsibleNavGroup background={'light'} titleSize={'xs'} isCollapsible={false} initialIsOpen={true}>
            <EuiFlexGroup>
              {exporter?.name ? (
                <EuiFlexItem>
                  <EuiFormRow label='Exportador' fullWidth>
                    <EuiFieldText readOnly fullWidth value={exporter?.name} />
                  </EuiFormRow>
                </EuiFlexItem>
              ) : null}

              <EuiFlexItem>
                <EuiFormRow label='N° Guia de despacho' fullWidth>
                  <EuiFieldText readOnly fullWidth value={number} />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label='Fecha de recepción' fullWidth>
                  <EuiDatePicker readOnly fullWidth value={moment(createdAt).format('ll')} />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label='Lote' fullWidth>
                  <EuiFieldText readOnly fullWidth value={lot} />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label='Productor' fullWidth>
                  <EuiFieldText readOnly fullWidth value={producer.name} />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiHorizontalRule />

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow
                  label='Tipo de envase'
                  fullWidth
                  isInvalid={!errors.selectedContainer ? false : true}
                  error={!errors.selectedContainer ? '' : errors.selectedContainer[0]}
                >
                  <EuiSuperSelect
                    placeholder='Seleccione una opción'
                    options={container}
                    valueOfSelected={selectedOptionsContainerType}
                    onChange={onChangeContainerType}
                    fullWidth
                  />
                </EuiFormRow>
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiFormRow
                  label='Tara'
                  fullWidth
                  isInvalid={!errors.selectedTare ? false : true}
                  error={!errors.selectedTare ? '' : errors.selectedTare[0]}
                >
                  <EuiFieldNumber readOnly fullWidth name='tare' onChange={(e) => onChangeTare(e)} value={tare} />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow
                  label='Cantidad de envases'
                  fullWidth
                  isInvalid={!errors.numberContainer ? false : true}
                  error={!errors.numberContainer ? '' : errors.numberContainer[0]}
                >
                  <EuiFieldNumber
                    min='0'
                    readOnly={isDisabled}
                    fullWidth
                    name='numberContainer'
                    onChange={(e) => onChangeNumberContainer(e)}
                    value={numberContainer}
                  />
                </EuiFormRow>
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiFormRow
                  label='Peso bruto'
                  fullWidth
                  isInvalid={!errors.grossWeight ? false : true}
                  error={!errors.grossWeight ? '' : errors.grossWeight[0]}
                >
                  <EuiFieldNumber
                    readOnly={isDisabled}
                    fullWidth
                    name='grossWeight'
                    onChange={(e) => onChangeGrossWeight(e)}
                    value={grossWeight}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow
                  label='Observación'
                  fullWidth
                  isInvalid={!errors.commentary ? false : true}
                  error={!errors.commentary ? '' : errors.commentary[0]}
                >
                  <EuiTextArea
                    readOnly={isDisabled}
                    fullWidth
                    compressed
                    name='commentary'
                    onChange={(e) => onChangeCommentary(e)}
                    value={commentary}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiCollapsibleNavGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule margin='xs' />
      <FooterFormReport
        showApprovalPanel={showApprovalPanel}
        {...user}
        approve={approve}
        closeModal={closeModal}
        create={create}
        data={guide.reception}
        loading={loading}
        guide={guide}
      />
    </EuiForm>
  )

  let modal
  if (isModalVisibleReport) {
    modal = (
      <EuiOverlayMask>
        <EuiModal maxWidth={false} onClose={closeModal} initialFocus='[name=popswitch]'>
          <EuiModalHeader>
            {loading ? <EuiProgress size='xs' position='absolute' /> : null}
            <EuiModalHeaderTitle>Informe de Recepción</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>{formSample}</EuiModalBody>

          <EuiModalFooter></EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <>
      <EuiButtonIcon onClick={showModalReport} iconType='reportingApp' iconSize='l' />
      {modal}
    </>
  )
}

export default ReceptionReport
