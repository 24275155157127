import React, {useState, useContext} from 'react'
import {
  EuiButton,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiCollapsibleNavGroup,
  EuiPanel,
  EuiDatePicker,
  EuiFieldNumber,
  EuiCallOut,
  EuiProgress,
  EuiSuperSelect,
} from '@elastic/eui'
import {validate} from 'validate.js'
import momenttz from 'moment-timezone'
import {validate as validateRut, clean, format} from 'rut.js'
import {constraintsEs} from './validates'
import GUIDES_SERVICES from '../../services/guides'
import PRODUCERS_SERVICES from '../../services/producers'
import EXPORTERS_SERVICES from './../../services/exporters'
import DRIVERS_SERVICES from './../../services/drivers'
import CARRIERS_SERVICES from './../../services/carriers'

import {DataContext} from './../../context/DataContext'

const GuideForm = ({setIsPreLoad, edit, guide}) => {
  const {addGuide, deleteGuide, updateGuide, dispatch, user, actionsUI, useDebounce} = useContext(DataContext)
  const [isModalVisibleGuide, setIsModalVisibleGuide] = useState(false)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [guideState, setGuideState] = useState('')
  const [weight, setWeight] = useState('')
  const [exporterAddress, setExporterAddress] = useState('')
  const [exporterCity, setExporterCity] = useState('')
  const [exporterCommune, setExporterCommune] = useState('')
  const [exporterEmail, setExporterEmail] = useState('')
  const [exporterEmails, setExporterEmails] = useState('')
  const [producerEmail, setProducerEmail] = useState('')
  const [producerEmails, setProducerEmails] = useState('')
  const [phone, setPhone] = useState('')
  const [exporterTurn, setExporterTurn] = useState('')
  const [activity, setActivity] = useState('')
  const [date, setDate] = useState(momenttz().tz('America/Santiago'))
  const [exporterNotFound, setExporterNotFound] = useState(false)
  const [exporterId, setExporterId] = useState('')
  const [exporterRut, setExporterRut] = useState('')
  const [exporterName, setExporterName] = useState('')
  const [producerId, setProducerId] = useState('')
  const [producerRut, setProducerRut] = useState('')
  const [producerAddress, setProducerAddress] = useState('')
  const [producerName, setProducerName] = useState('')
  const [producerCity, setProducerCity] = useState('')
  const [producerCommune, setProducerCommune] = useState('')
  const [producerTurn, setProducerTurn] = useState('')
  const [contact, setContact] = useState('')
  const [carrierName, setCarrierName] = useState('')
  const [carrierNotFound, setCarrierNotFound] = useState(false)
  const [carrierId, setCarrierId] = useState()
  const [carrierRut, setCarrierRut] = useState('')
  const [patent, setPatent] = useState('')
  const [driverNotFound, setDriverNotFound] = useState(false)
  const [driverId, setDriverId] = useState()
  const [driverRut, setDriverRut] = useState('')
  const [driverLastName, setDriverLastName] = useState('')
  const [driverName, setDriverName] = useState('')
  const [guideId, setGuideId] = useState('')
  const [isGuidePreLoad, setIsGuidePreLoad] = useState(false)
  const [producerNotFound, setProducerNotFound] = useState(false)
  const [number, setNumber] = useState()
  const [disable, setDisable] = useState(false)
  const [selectedOptionsProductType, setSelectedOptionsProductType] = useState([])
  const [duplicate, setDuplicate] = useState(false)
  const [updateProducer, setUpdateProducer] = useState(false)
  const [updateExporter, setUpdateExporter] = useState(false)
  const [openProducer, setOpenProducer] = useState('open')
  const [openExporter, setOpenExporter] = useState('closed')
  const [openTransporter, setOpenTransporter] = useState('closed')
  const variety = [
    {value: 'Chandler', inputDisplay: 'Chandler'},
    {value: 'Howard', inputDisplay: 'Howard'},
    {value: 'Lara', inputDisplay: 'Lara'},
    {value: 'Semilla', inputDisplay: 'Semilla'},
    {value: 'Serr', inputDisplay: 'Serr'},
    {value: 'Tulare', inputDisplay: 'Tulare'},
  ]

  const onChangeWeight = (e) => {
    setWeight(e.target.value <= 0 ? '' : e.target.value)
  }

  const onChangeProducerEmail = (e) => {
    setProducerEmail(e.target.value)
  }

  const onChangeProducerEmails = (e) => {
    setProducerEmails(e.target.value)
  }

  const onChangeExporterAddress = (e) => {
    setExporterAddress(e.target.value)
  }

  const onChangeExporterCity = (e) => {
    setExporterCity(e.target.value)
  }

  const onChangeExporterCommune = (e) => {
    setExporterCommune(e.target.value)
  }

  const onChangeExporterEmail = (e) => {
    setExporterEmail(e.target.value)
  }

  const onChangeExporterEmails = (e) => {
    setExporterEmails(e.target.value)
  }

  const onChangePhone = (e) => {
    setPhone(e.target.value)
  }

  const onChangeExporterTurn = (e) => {
    setExporterTurn(e.target.value)
  }

  const onChangeActivity = (e) => {
    setActivity(e.target.value)
  }

  const onChangeDate = (date) => {
    setDate(date)
  }

  /*const onChangeTransferType = (e) => {
    setTransferType(e.target.value)
  }*/

  const cleanForm = () => {
    setGuideId('')
    setNumber('')
    setPatent('')
    setProducerName('')
    setContact('')
    setProducerAddress('')
    setProducerCity('')
    setProducerCommune('')
    setProducerTurn('')
    setProducerEmail('')
    setProducerEmails('')
    setProducerId('')
    setProducerRut('')
    setDriverRut('')
    setExporterRut('')
    setWeight('')
    setExporterId('')
    setExporterRut('')
    setExporterName('')
    setPhone('')
    setExporterAddress('')
    setExporterCity('')
    setExporterCommune('')
    setExporterTurn('')
    setExporterEmail('')
    setExporterEmails('')
    //setTransferType('')
    setActivity('')
    setCarrierRut('')
    setCarrierName('')
    setDriverId('')
    setDriverRut('')
    setDriverName('')
    setDriverLastName('')
    setSelectedOptionsProductType('')
    //setDescription('')
    setGuideState('')
    setUpdateProducer(false)
    setUpdateExporter(false)
  }

  /**
   *  CLIENTE
   */

  const onChangeExporterRut = async (e) => {
    setExporterRut(format(e.target.value))
    const cleanrut = clean(e.target.value)

    if (!validateRut(cleanrut)) {
      setDisable(true)
      setErrors({exporterRut: ['Rut invalido']})
    } else {
      setDisable(false)
      delete errors.exporterRut
    }

    if (cleanrut.length >= 8 && validateRut(cleanrut)) {
      setLoading(true)
      await EXPORTERS_SERVICES.getByRut(user.token, cleanrut)
        .then((data) => {
          setExporterName(data.data.name)
          setPhone(data.data.contact)
          setExporterAddress(data.data.address.street)
          setExporterCity(data.data.address.city)
          setExporterCommune(data.data.address.commune)
          setExporterTurn(data.data.turn)
          setExporterEmail(data.data.email)
          setExporterEmails(data.data.emails)
          //setTransferType(data.data.transferType)
          setActivity(data.data.economicActivity)
          setExporterId(data.data._id)
          setExporterNotFound(false)
          setLoading(false)
          setUpdateExporter(true)
        })
        .catch(async (error) => {
          setExporterName('')
          setPhone('')
          setExporterAddress('')
          setExporterCity('')
          setExporterCommune('')
          setExporterTurn('')
          setExporterEmail('')
          setExporterEmails('')
          //setTransferType('')
          setActivity('')
          setExporterId('')
          setExporterNotFound(true)
          setLoading(false)
          setUpdateExporter(false)
        })
    }
  }

  const onChangeExporterName = (e) => {
    setExporterName(e.target.value)
  }

  const onChangeProducerRut = async (e) => {
    setProducerRut(format(e.target.value))

    // Busco si existe productor, si no, lo creo
    const cleanrut = clean(e.target.value)
    if (!validateRut(cleanrut)) {
      setDisable(true)
      setErrors({producerRut: ['Rut invalido']})
    } else {
      setDisable(false)
      delete errors.producerRut
    }

    if (cleanrut.length >= 8 && validateRut(cleanrut)) {
      if (number) debounceNumber(number, cleanrut)

      setLoading(true)
      await PRODUCERS_SERVICES.getByRut(user.token, cleanrut)
        .then((data) => {
          setProducerName(data.data.name)
          setContact(data.data.contact)
          setProducerAddress(data.data.address.street)
          setProducerCity(data.data.address.city)
          setProducerCommune(data.data.address.commune)
          setProducerTurn(data.data.turn)
          setProducerId(data.data._id)
          setProducerEmail(data.data.email)
          setProducerEmails(data.data.emails)
          setProducerNotFound(false)
          setLoading(false)
          setUpdateProducer(true)
        })
        .catch(async (error) => {
          console.log({error})
          setProducerNotFound(true)
          setLoading(false)
          setUpdateProducer(false)
          setProducerName('')
          setContact('')
          setProducerAddress('')
          setProducerCity('')
          setProducerCommune('')
          setProducerTurn('')
          setProducerId('')
          setProducerEmail('')
          setProducerEmails('')
          // ACTUALIZAR
          //await PRODUCERS_SERVICES.createFromPreLoad(user.token, form)
        })
    }
  }

  const onChangeProducerAddress = (e) => {
    setProducerAddress(e.target.value)
  }

  const onChangeProducerName = (e) => {
    setProducerName(e.target.value)
  }

  const onChangeProducerCity = (e) => {
    setProducerCity(e.target.value)
  }

  const onChangeProducerCommune = (e) => {
    setProducerCommune(e.target.value)
  }

  const onChangeProducerTurn = (e) => {
    setProducerTurn(e.target.value)
  }

  /**
   *  TRANSPORTE
   */

  const onChangeCarrierName = (e) => {
    setCarrierName(e.target.value)
  }

  const onChangeCarrierRut = async (e) => {
    setCarrierRut(format(e.target.value))

    const cleanrut = clean(e.target.value)

    if (!validateRut(cleanrut)) {
      setDisable(true)
      setErrors({carrierRut: ['Rut invalido']})
    } else {
      setDisable(false)
      delete errors.carrierRut
    }

    if (cleanrut.length >= 8 && validateRut(cleanrut)) {
      setLoading(true)
      await CARRIERS_SERVICES.getByRut(user.token, cleanrut)
        .then((data) => {
          setCarrierId(data.data._id)
          setCarrierName(data.data.name)
          setCarrierNotFound(false)
        })
        .catch(async (error) => {
          setCarrierNotFound(true)
        })
      setLoading(false)
    }
  }

  const onChangePatent = (e) => {
    setPatent(e.target.value.toUpperCase())
  }

  const onChangeDriverRut = async (e) => {
    setDriverRut(format(e.target.value))

    const cleanrut = clean(e.target.value)

    if (!validateRut(cleanrut)) {
      setDisable(true)
      setErrors({driverRut: ['Rut invalido']})
    } else {
      setDisable(false)
      delete errors.driverRut
    }

    if (cleanrut.length >= 8 && validateRut(cleanrut)) {
      await DRIVERS_SERVICES.getByRut(user.token, cleanrut)
        .then((data) => {
          setDriverLastName(data.data.name)
          setDriverName(data.data.lastName)
          setDriverId(data.data._id)
          setDriverNotFound(false)
        })
        .catch(async (error) => {
          setDriverNotFound(true)
        })
    }
  }

  const onChangeDriverLastName = (e) => {
    setDriverLastName(e.target.value)
  }

  const onChangeDriverName = (e) => {
    setDriverName(e.target.value)
  }

  const onChangeProductType = (selectedOptions) => {
    setSelectedOptionsProductType(selectedOptions)
  }

  const showModalGuide = () => {
    setDisable(false)
    showForm('open', 'closed', 'closed')
    setLoading(true)
    setIsModalVisibleGuide(true)
    if (edit && guide) {
      setProducerId(guide.producer._id)
      setCarrierId(guide.carrier._id)
      setDriverId(guide.driver._id)
      setGuideId(guide._id)
      setGuideState(guide.state)
      setProducerRut(format(guide.producer.rut))
      setProducerName(guide.producer?.name)
      setProducerCity(guide.producer.address?.city)
      setProducerCommune(guide.producer.address?.commune)
      setProducerTurn(guide.producer?.turn)
      setProducerEmail(guide.producer.email)
      setProducerEmails(guide.producer?.emails)
      setContact(guide.producer?.contact)
      setProducerAddress(guide.producer.address?.street)
      setNumber(guide.number)
      setCarrierRut(format(guide.carrier.rut))
      setCarrierName(guide.carrier?.name)
      if (guide.exporter) {
        setExporterRut(format(guide.exporter.rut))
        setExporterName(guide.exporter?.name)
        setPhone(guide.exporter?.contact)
        setExporterAddress(guide.exporter.address?.street)
        setExporterCity(guide.exporter.address?.city)
        setExporterCommune(guide.exporter.address?.commune)
        setExporterTurn(guide.exporter?.turn)
        setExporterEmail(guide.exporter.email)
        setExporterEmails(guide.exporter?.emails)
        // setTransferType(transferType)
        setActivity(guide.exporter?.economicActivity)
        setExporterId(guide.exporter._id)
      }

      setDriverRut(format(guide.driver.rut))
      setDriverName(guide.driver?.name)
      setDriverLastName(guide.driver?.lastName)
      setWeight(guide.weight)
      if (guide.state === 'load') {
        setSelectedOptionsProductType(guide.products?.name)
        setPatent(guide?.patent)
        setWeight(guide.products?.quantity)
      }
    }
    setLoading(false)
  }
  const closeModalGuide = () => {
    cleanForm()
    setIsModalVisibleGuide(false)
  }

  const updatedExporter = async () => {
    setLoading(true)
    const form = {
      rut: clean(exporterRut),
      name: exporterName,
      address: {
        street: exporterAddress,
        city: exporterCity,
        commune: exporterCommune,
      },
      email: exporterEmail,
      contact: Number(phone),
      turn: exporterTurn,
      economicActivity: activity,
      transferType: '-',
      emails: exporterEmails,
    }

    await EXPORTERS_SERVICES.update(user.token, form, exporterId)
      .then((data) => {
        setExporterNotFound(false)
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: 'Exportador actualizado', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const updatedProducer = async () => {
    setLoading(true)
    const newProducer = {
      rut: clean(producerRut),
      name: producerName,
      email: producerEmail,
      contact,
      address: {
        street: producerAddress,
        city: producerCity,
        commune: producerCommune,
      },
      turn: producerTurn,
      emails: producerEmails,
    }
    await PRODUCERS_SERVICES.update(user.token, newProducer, producerId)
      .then((data) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: 'Productor actualizado', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const saveProducer = async (from) => {
    setLoading(true)
    const newProducer = {
      rut: clean(producerRut),
      name: producerName,
      email: producerEmail,
      contact,
      address: {
        street: producerAddress,
        city: producerCity,
        commune: producerCommune,
      },
      turn: producerTurn,
      emails: producerEmails,
    }

    await PRODUCERS_SERVICES.create(user.token, newProducer)
      .then((data) => {
        setProducerId(data.data._id)
        setProducerNotFound(false)
        setLoading(false)
        if (from !== 'saveGuide') {
          dispatch(actionsUI.addAlert({id: '1', title: 'Productor ingresado', color: 'success', iconType: 'check'}))
        }
        setUpdateProducer(true)
      })
      .catch((error) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
        setUpdateProducer(false)
      })
  }

  const saveExporter = async (from) => {
    setLoading(true)
    const form = {
      rut: clean(exporterRut),
      name: exporterName,
      address: {
        street: exporterAddress,
        city: exporterCity,
        commune: exporterCommune,
      },
      email: exporterEmail,
      contact: Number(phone),
      turn: exporterTurn,
      economicActivity: activity,
      transferType: '-',
      emails: exporterEmails,
    }

    await PRODUCERS_SERVICES.getByRutGuide(user.token, clean(exporterRut))
      .then((data) => {
        if (data.data._id) {
          dispatch(
            actionsUI.addAlert({
              id: '1',
              title: 'El rut que esta ingresando esta registrado como Exportadora',
              color: 'danger',
              iconType: 'alert',
            }),
          )
          setLoading(false)
          return null
        } else {
          EXPORTERS_SERVICES.create(user.token, form)
            .then((data) => {
              setExporterNotFound(false)
              setExporterId(data.data._id)
              setLoading(false)
              if (from !== 'saveGuide') {
                dispatch(
                  actionsUI.addAlert({id: '1', title: 'Exportador ingresado', color: 'success', iconType: 'check'}),
                )
              }
              setUpdateExporter(true)
            })
            .catch((error) => {
              setLoading(false)
              setUpdateExporter(false)
              dispatch(
                actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
              )
            })
        }
      })
      .catch(async (error) => {
        console.log({error})
      })
  }

  const saveCarrier = async () => {
    setLoading(true)
    const form = {
      rut: clean(carrierRut),
      name: carrierName,
    }

    await CARRIERS_SERVICES.createFromPreLoad(user.token, form)
      .then((data) => {
        setCarrierNotFound(false)
        setCarrierId(data.data._id)
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: 'Transportista ingresado', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const saveDriver = async () => {
    setLoading(true)
    const form = {
      rut: clean(driverRut),
      name: driverName,
      lastName: driverLastName,
    }

    await DRIVERS_SERVICES.createFromPreLoad(user.token, form)
      .then((data) => {
        setDriverNotFound(false)
        setDriverId(data.data._id)
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: 'Chofer ingresado', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }
  const debounceNumber = useDebounce((val, rut) => findGuide(val, rut), 1000)
  /**
   * RELLENAR CON PRE-GUIA
   */

  const onChangeNumber = (e) => {
    setNumber(e.target.value)
    debounceNumber(Number(e.target.value), clean(producerRut))
  }

  const findGuide = async (gNumber, pRut) => {
    setLoading(true)
    await GUIDES_SERVICES.getByNumber(user.token, gNumber, pRut)
      .then((data) => {
        if (data.data.state !== 'preload') {
          setLoading(false)

          setErrors({number: ['N° de guía ya registrada']})

          setDuplicate(true)
          dispatch(
            actionsUI.addAlert({
              id: '1',
              title: `La guía [${data.data.number}] ya se ecuentra ingresada`,
              color: 'danger',
              iconType: 'alert',
            }),
          )
        } else {
          setGuideState(data.data.state)
          setGuideId(data.data._id)
          setIsGuidePreLoad(true)
          setDriverRut(format(data.data.driver.rut))
          setWeight(data.data.weight)

          // Carrier

          setCarrierRut(format(data.data.carrier.rut))
          setCarrierName(data.data.carrier.name)
          setCarrierId(data.data._id)

          // Driver
          setDriverId(data.data.driver._id)
          setDriverRut(format(data.data.driver.rut))
          setDriverName(data.data.driver.name)
          setDriverLastName(data.data.driver.lastName)

          // Exporter
          setExporterId(data.data.exporter._id)
          setExporterRut(format(data.data.exporter.rut))
          setExporterName(data.data.exporter.name)
          setPhone(data.data.exporter.contact)

          setExporterAddress(data.data.exporter.address.street)
          setExporterCity(data.data.exporter.address.city)
          setExporterCommune(data.data.exporter.address.commune)

          setExporterTurn(data.data.exporter.turn)

          setExporterEmail(data.data.exporter.email)

          //setTransferType(data.data.exporter.transferType)
          setActivity(data.data.exporter.economicActivity)
          setLoading(false)
        }
      })
      .catch((error) => {
        delete errors.number

        setErrors(errors)
        setDuplicate(false)
        setLoading(false)
      })
  }
  const save = async () => {
    setDisable(true)
    const form = {
      //producer
      producerRut,
      producerName,
      contact: '0',
      producerAddress,
      producerCity,
      producerCommune,
      producerTurn,
      producerEmail,
      producerEmails,
      // exporter
      exporterRut,
      exporterName,
      exporterAddress,
      exporterCity,
      exporterCommune,
      exporterEmail,
      exporterEmails,
      phone,
      exporterTurn,
      activity,
      transferType: '-',
      // transporter
      carrierRut,
      carrierName,
      // chofer
      driverRut,
      driverName,
      driverLastName,
      exporter: exporterId,
      driver: driverId,
      carrier: carrierId,
      weight,
      productName: selectedOptionsProductType,
      patent,
      // selectedOptionsUsers,
      //selectedOptionPriority,
      number: number,
      date,
      state: 'load',
      description: '-',
      isPreLoad: isGuidePreLoad,
      //priority: selectedOptionPriority,
    }
    let validationResult
    validationResult = validate(form, constraintsEs)
    validate.validators.custom = function (value, options, key, attributes) {
      if (validateRut(value) === false) {
        return (validationResult[key] = ['Rut invalido'])
      }
    }
    // validate({exporterRut: form.exporterRut}, {exporterRut: {custom: 'options'}})
    validate({producerRut: form.producerRut}, {producerRut: {custom: 'options'}})
    validate({driverRut: form.driverRut}, {driverRut: {custom: 'options'}})
    validate({carrierRut: form.carrierRut}, {carrierRut: {custom: 'options'}})

    if (validationResult !== undefined) {
      dispatch(
        actionsUI.addAlert({
          id: '1',
          title: 'Debe ingresar todos los campos obligatorios',
          color: 'danger',
          iconType: 'alert',
        }),
      )
      setErrors(validationResult)
      setLoading(false)
      setDisable(false)
      return
    }

    setErrors({})

    //saveProducer('saveGuide')
    if (exporterRut) saveExporter('saveGuide')

    const dataGuide = {
      exporter: exporterId ? exporterId : undefined,
      producer: producerId,
      driver: driverId,
      carrier: carrierId,
      products: {
        name: selectedOptionsProductType,
        quantity: weight,
      },
      //selectedOptionsUsers,
      number: number,
      date,
      patent,
      state: 'load',
      description: '-',
      isPreLoad: isGuidePreLoad,
    }

    if (edit || guideState === 'preload') {
      await GUIDES_SERVICES.update(user.token, dataGuide, guideId)
        .then((data) => {
          if (guideState === 'preload') {
            deleteGuide(data.data)
            addGuide(data.data)
          } else {
            updateGuide(data.data)
          }
          setLoading(false)
          setDisable(false)
          dispatch(actionsUI.addAlert({id: '1', title: 'Carga actualizas', color: 'success', iconType: 'check'}))
        })
        .catch((error) => {
          setLoading(false)
          dispatch(
            actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
          )
        })
    } else {
      await GUIDES_SERVICES.create(user.token, dataGuide)
        .then((data) => {
          setIsPreLoad(false)
          addGuide(data.data)
          cleanForm()
          setLoading(false)
          setDisable(false)
          dispatch(actionsUI.addAlert({id: '1', title: 'Carga ingresada', color: 'success', iconType: 'check'}))
        })
        .catch((error) => {
          setLoading(false)
          dispatch(
            actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
          )
        })
    }

    closeModalGuide()
  }

  const showForm = (prod, exp, tran) => {
    setOpenProducer(prod)
    setOpenExporter(exp)
    setOpenTransporter(tran)
  }

  const formSample = (
    <EuiForm>
      <EuiFlexGroup responsive={true}>
        <EuiFlexItem>
          <EuiPanel grow={false}>
            <EuiCollapsibleNavGroup
              background={'light'}
              titleSize={'xs'}
              title='PRODUCTOR'
              isCollapsible={true}
              initialIsOpen={true}
              forceState={openProducer}
              onClick={() => showForm('open', 'closed', 'closed')}
            >
              {producerNotFound ? (
                <EuiCallOut
                  title='Debe completar los datos del productor para continuar'
                  color='warning'
                  iconType='help'
                ></EuiCallOut>
              ) : null}
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow
                    label='Rut'
                    fullWidth
                    isInvalid={!errors.producerRut ? false : true}
                    error={!errors.producerRut ? '' : errors.producerRut[0]}
                  >
                    <EuiFieldText
                      isLoading={loading}
                      fullWidth
                      name='producerRut'
                      onChange={(e) => onChangeProducerRut(e)}
                      value={producerRut}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label='N° Guía de despacho'
                    fullWidth
                    isInvalid={!errors.number ? false : true}
                    error={!errors.number ? '' : errors.number[0]}
                  >
                    <EuiFieldText
                      controlOnly={true}
                      fullWidth
                      name='number'
                      onChange={(e) => onChangeNumber(e)}
                      value={number}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow
                    label='Razón social'
                    fullWidth
                    isInvalid={!errors.producerName ? false : true}
                    error={!errors.producerName ? '' : errors.producerName[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='producerName'
                      onChange={(e) => onChangeProducerName(e)}
                      value={producerName}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiFlexGroup>
                <EuiFlexItem grow={2}>
                  <EuiFormRow
                    label='Dirección'
                    fullWidth
                    isInvalid={!errors.producerAddress ? false : true}
                    error={!errors.producerAddress ? '' : errors.producerAddress[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='producerAddress'
                      onChange={(e) => onChangeProducerAddress(e)}
                      value={producerAddress}
                    />
                  </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem grow={1}>
                  <EuiFormRow
                    label='Ciudad'
                    fullWidth
                    isInvalid={!errors.producerCity ? false : true}
                    error={!errors.producerCity ? '' : errors.producerCity[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='producerCity'
                      onChange={(e) => onChangeProducerCity(e)}
                      value={producerCity}
                    />
                  </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem grow={1}>
                  <EuiFormRow
                    label='Comuna'
                    fullWidth
                    isInvalid={!errors.producerCommune ? false : true}
                    error={!errors.producerCommune ? '' : errors.producerCommune[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='producerCommune'
                      onChange={(e) => onChangeProducerCommune(e)}
                      value={producerCommune}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow
                    label='Giro'
                    fullWidth
                    isInvalid={!errors.producerTurn ? false : true}
                    error={!errors.producerTurn ? '' : errors.producerTurn[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='producerTurn'
                      onChange={(e) => onChangeProducerTurn(e)}
                      value={producerTurn}
                    />
                  </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem>
                  <EuiFormRow
                    label='Email'
                    fullWidth
                    isInvalid={!errors.producerEmail ? false : true}
                    error={!errors.producerEmail ? '' : errors.producerEmail[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='producerEmail'
                      onChange={(e) => onChangeProducerEmail(e)}
                      value={producerEmail}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow label='Emails' fullWidth>
                    <EuiFieldText
                      fullWidth
                      name='producerEmails'
                      onChange={(e) => onChangeProducerEmails(e)}
                      value={producerEmails}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              {updateProducer && (
                <EuiFlexGroup>
                  <EuiFlexItem>&nbsp;</EuiFlexItem>
                  <EuiFlexItem>
                    <EuiButton color='warning' onClick={updatedProducer}>
                      Actualizar Productor
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              )}
              {producerNotFound ? (
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiButton onClick={saveProducer} color='warning'>
                      Ingresar nuevo Productor
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              ) : null}
            </EuiCollapsibleNavGroup>

            <EuiCollapsibleNavGroup
              background={'light'}
              titleSize={'xs'}
              title='EXPORTADOR'
              isCollapsible={true}
              initialIsOpen={false}
              forceState={openExporter}
              onClick={() => showForm('closed', 'open', 'closed')}
            >
              {exporterNotFound ? (
                <EuiCallOut
                  title='Debe completar los datos del exportador para continuar'
                  color='warning'
                  iconType='help'
                ></EuiCallOut>
              ) : null}
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow
                    label='Rut Exportador'
                    fullWidth
                    isInvalid={!errors.exporterRut ? false : true}
                    error={!errors.exporterRut ? '' : errors.exporterRut[0]}
                  >
                    <EuiFieldText
                      isLoading={loading}
                      fullWidth
                      name='exporterRut'
                      onChange={(e) => onChangeExporterRut(e)}
                      value={exporterRut}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label='Razón social'
                    fullWidth
                    isInvalid={!errors.exporterName ? false : true}
                    error={!errors.exporterName ? '' : errors.exporterName[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='exporterName'
                      onChange={(e) => onChangeExporterName(e)}
                      value={exporterName}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem grow={2}>
                  <EuiFormRow
                    label='Dirección'
                    fullWidth
                    isInvalid={!errors.exporterAddress ? false : true}
                    error={!errors.exporterAddress ? '' : errors.exporterAddress[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='exporterAddress'
                      onChange={(e) => onChangeExporterAddress(e)}
                      value={exporterAddress}
                    />
                  </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem grow={1}>
                  <EuiFormRow
                    label='Ciudad'
                    fullWidth
                    isInvalid={!errors.exporterCity ? false : true}
                    error={!errors.exporterCity ? '' : errors.exporterCity[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='exporterCity'
                      onChange={(e) => onChangeExporterCity(e)}
                      value={exporterCity}
                    />
                  </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem grow={1}>
                  <EuiFormRow
                    label='Comuna'
                    fullWidth
                    isInvalid={!errors.exporterCommune ? false : true}
                    error={!errors.exporterCommune ? '' : errors.exporterCommune[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='exporterCommune'
                      onChange={(e) => onChangeExporterCommune(e)}
                      value={exporterCommune}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow
                    label='Email'
                    fullWidth
                    isInvalid={!errors.exporterEmail ? false : true}
                    error={!errors.exporterEmail ? '' : errors.exporterEmail[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='exporterEmail'
                      onChange={(e) => onChangeExporterEmail(e)}
                      value={exporterEmail}
                    />
                  </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem>
                  <EuiFormRow
                    label='Teléfono'
                    fullWidth
                    isInvalid={!errors.phone ? false : true}
                    error={!errors.phone ? '' : errors.phone[0]}
                  >
                    <EuiFieldNumber min='0' fullWidth name='phone' onChange={(e) => onChangePhone(e)} value={phone} />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow
                    label='Giro'
                    fullWidth
                    isInvalid={!errors.exporterTurn ? false : true}
                    error={!errors.exporterTurn ? '' : errors.exporterTurn[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='exporterTurn'
                      onChange={(e) => onChangeExporterTurn(e)}
                      value={exporterTurn}
                    />
                  </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem>
                  <EuiFormRow
                    label='Actividad Económica'
                    fullWidth
                    isInvalid={!errors.activity ? false : true}
                    error={!errors.activity ? '' : errors.activity[0]}
                  >
                    <EuiFieldText fullWidth name='activity' onChange={(e) => onChangeActivity(e)} value={activity} />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow label='Emails' fullWidth>
                    <EuiFieldText
                      fullWidth
                      name='exporterEmails'
                      onChange={(e) => onChangeExporterEmails(e)}
                      value={exporterEmails}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              {updateExporter && (
                <EuiFlexGroup>
                  <EuiFlexItem>&nbsp;</EuiFlexItem>
                  <EuiFlexItem>
                    <EuiButton color='warning' onClick={updatedExporter}>
                      Actualizar Exportador
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              )}
              {exporterNotFound ? (
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiButton onClick={saveExporter} color='warning'>
                      Ingresar nuevo Exportador
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              ) : null}
            </EuiCollapsibleNavGroup>

            <EuiCollapsibleNavGroup
              background={'light'}
              titleSize={'xs'}
              title='TRANSPORTE'
              isCollapsible={true}
              initialIsOpen={false}
              onClick={() => showForm('closed', 'closed', 'open')}
              forceState={openTransporter}
            >
              {carrierNotFound ? (
                <EuiCallOut
                  title='Debe completar los datos del transportista para continuar'
                  color='warning'
                  iconType='help'
                ></EuiCallOut>
              ) : null}
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow
                    label='Rut Transportista'
                    fullWidth
                    isInvalid={!errors.carrierRut ? false : true}
                    error={!errors.carrierRut ? '' : errors.carrierRut[0]}
                  >
                    <EuiFieldText
                      isLoading={loading}
                      fullWidth
                      name='carrierRut'
                      onChange={(e) => onChangeCarrierRut(e)}
                      value={carrierRut}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label='Nombre'
                    fullWidth
                    isInvalid={!errors.carrierName ? false : true}
                    error={!errors.carrierName ? '' : errors.carrierName[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='carrierName'
                      onChange={(e) => onChangeCarrierName(e)}
                      value={carrierName}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              {carrierNotFound ? (
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiButton onClick={saveCarrier} color='warning'>
                      Ingresar nuevo Transportista
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              ) : null}
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow
                    label='Rut Chofer'
                    fullWidth
                    isInvalid={!errors.driverRut ? false : true}
                    error={!errors.driverRut ? '' : errors.driverRut[0]}
                  >
                    <EuiFieldText fullWidth name='driverRut' onChange={(e) => onChangeDriverRut(e)} value={driverRut} />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label='Nombre Chofer'
                    fullWidth
                    isInvalid={!errors.driverName ? false : true}
                    error={!errors.driverName ? '' : errors.driverName[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='driverName'
                      onChange={(e) => onChangeDriverName(e)}
                      value={driverName}
                    />
                  </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem>
                  <EuiFormRow
                    label='Apellido Chofer'
                    fullWidth
                    isInvalid={!errors.driverLastName ? false : true}
                    error={!errors.driverLastName ? '' : errors.driverLastName[0]}
                  >
                    <EuiFieldText
                      fullWidth
                      name='driverLastName'
                      onChange={(e) => onChangeDriverLastName(e)}
                      value={driverLastName}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>

              {driverNotFound ? (
                <EuiCallOut title='Debe completar los datos del chofer para continuar' color='warning' iconType='help'>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiButton onClick={saveDriver} color='warning'>
                        Ingresar nuevo Chofer
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiCallOut>
              ) : null}
            </EuiCollapsibleNavGroup>
          </EuiPanel>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiPanel grow={false}>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow
                  label='Fecha de Emisión'
                  fullWidth
                  isInvalid={!errors.date ? false : true}
                  error={!errors.date ? '' : errors.date[0]}
                >
                  <EuiDatePicker
                    popoverPlacement={'top-start'}
                    selected={date}
                    fullWidth
                    name='date'
                    onChange={onChangeDate}
                    value={date}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow
                  label='Patente'
                  isInvalid={!errors.patent ? false : true}
                  error={!errors.patent ? '' : errors.patent[0]}
                >
                  <EuiFieldText fullWidth name='patent' onChange={(e) => onChangePatent(e)} value={patent} />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup responsive={false} alignItems='center'>
              <EuiFlexItem grow={4}>
                <EuiFormRow
                  label='Variedad'
                  fullWidth
                  isInvalid={!errors.name ? false : true}
                  error={!errors.name ? '' : errors.name[0]}
                >
                  <EuiSuperSelect
                    placeholder='Seleccione una opción'
                    options={variety}
                    valueOfSelected={selectedOptionsProductType}
                    onChange={onChangeProductType}
                    fullWidth
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFormRow
                  label='Peso según guía'
                  isInvalid={!errors.weight ? false : true}
                  error={!errors.weight ? '' : errors.weight[0]}
                >
                  <EuiFieldNumber name='weight' onChange={(e) => onChangeWeight(e)} value={weight} />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiForm>
  )

  let modal

  if (isModalVisibleGuide) {
    modal = (
      <EuiOverlayMask>
        <EuiModal maxWidth={false} onClose={closeModalGuide} initialFocus='[name=popswitch]'>
          <EuiModalHeader>
            {loading ? <EuiProgress size='xs' position='absolute' /> : null}
            <EuiModalHeaderTitle>Guía de despacho</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>{formSample}</EuiModalBody>

          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeModalGuide}>Salir</EuiButtonEmpty>
            {edit ? (
              <EuiButton onClick={save} isDisabled={disable || duplicate} fill>
                Actualizar
              </EuiButton>
            ) : (
              <EuiButton onClick={save} fill isDisabled={disable || duplicate}>
                Guardar
              </EuiButton>
            )}
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <>
      {edit ? (
        <EuiButtonIcon onClick={showModalGuide} iconType='indexRollupApp' iconSize='l' />
      ) : (
        <EuiButtonIcon
          color='text'
          iconSize='xl'
          onClick={showModalGuide}
          iconType='plusInCircleFilled'
          aria-label='Next'
        />
      )}
      {modal}
    </>
  )
}

export default GuideForm
