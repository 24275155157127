import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';


import combineReducers from './reducers';

const store = createStore(
    combineReducers,
    {},
    composeWithDevTools(applyMiddleware(thunk, logger))
)

export { store };