export const constraintsEs = {
  value: {
    numericality: {
      greaterThan: 0,
      notGreaterThan: 'debe ser mayor a 0',
      lessThanOrEqualTo: 100,
      message: '^ Ingrese porcentaje (0 a 100)',
    },
  },
  box: {
    presence: {
      allowEmpty: false,
      message: '^Seleccione un cajón',
    },
  },
}
