import {EuiText} from '@elastic/eui'

var objets = {
  options: [
    {
      value: 10,
      inputDisplay: <EuiText size='m'>10</EuiText>,
    },
    {
      value: 20,
      inputDisplay: <EuiText size='m'>20</EuiText>,
    },
    {
      value: 40,
      inputDisplay: <EuiText size='m'>40</EuiText>,
    },
    {
      value: 80,
      inputDisplay: <EuiText size='m'>80</EuiText>,
    },
    {
      value: 100,
      inputDisplay: <EuiText size='m'>100</EuiText>,
    },
  ],

  userType: [
    {value: 'user', inputDisplay: 'USUARIO'},
    {value: 'operator', inputDisplay: 'OPERADOR'},
    {value: 'admin', inputDisplay: 'ADMIN'},
  ],
  userStatus: [
    {value: 'ACTIVE', inputDisplay: 'ACTIVO'},
    {value: 'INACTIVE', inputDisplay: 'INACTIVO'},
  ],
  action: function (editUser, deleteUser, sendEmail) {
    return [
      {
        name: 'Editar',
        description: 'Editar',
        icon: 'documentEdit',
        type: 'icon',
        onClick: editUser,
      },
      {
        name: 'Eliminar',
        description: 'Eliminar',
        icon: 'trash',
        type: 'icon',
        color: 'danger',
        onClick: deleteUser,
      },
      //{
      // name: 'Enviar correo',
      //  description: 'Enviar correo',
      //  icon: 'email',
      //  type: 'icon',
      //  color: 'primary',
      //  onClick: sendEmail,
      //},
    ]
  },
}
export default objets
