import React, {useState, useContext} from 'react'
import {
  EuiFlexItem,
  EuiCard,
  EuiFlexGroup,
  EuiHorizontalRule,
  EuiOverlayMask,
  EuiModalBody,
  EuiBadge,
  EuiStat,
  EuiBeacon,
  EuiLoadingContent,
} from '@elastic/eui'
import './index.css'
import ReceptionReport from './../ReceptionReport'
import Timer from './../Timer'
import GuideForm from './../GuideForm'
import DeleteGuide from './../DeleteGuide'
import ReceptionPdf from './../Pdf/Reception'
import {DataContext} from './../../context/DataContext'
import GuidePhoto from './../GuidePhoto'
import ShowGuidePhoto from './../ShowGuidePhoto'

const GuideReception = ({guide, setActionEvent}) => {
  const {isGuidePhoto} = guide
  const {user, loading} = useContext(DataContext)
  const {exporter, producer} = guide
  const [maskOpen, changeMask] = useState(false)

  const modal = (
    <EuiOverlayMask
      onClick={() => {
        changeMask(false)
      }}
    >
      <EuiModalBody>
        <ReceptionPdf data={guide} />
      </EuiModalBody>
    </EuiOverlayMask>
  )

  return (
    <>
      {maskOpen ? modal : undefined}

      <EuiCard
        style={{minWidth: 220}}
        display='plain'
        textAlign='left'
        description={
          <>
            <EuiStat title={exporter?.name ? exporter.name : '-'} description='Exportador' titleSize='s' />
            <EuiStat title={producer.name} description='Productor' titleSize='s' />
          </>
        }
        title={
          <EuiFlexGroup gutterSize='none'>
            {user.user.type === 'user' ? null : (
              <>
                <EuiFlexItem grow={1}>
                  <Timer date={guide.createdAt} />
                </EuiFlexItem>
              </>
            )}
            <EuiFlexItem grow={1}></EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiBadge color='#FCF7BC'>
                Guía<b>{` #${guide.number}`}</b>
              </EuiBadge>
            </EuiFlexItem>
            <EuiFlexItem grow={1}></EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiBadge color='#E4A6C7'>
                Lote <b>{` #${guide.lot}`}</b>
              </EuiBadge>
            </EuiFlexItem>
            <EuiFlexItem grow={6}></EuiFlexItem>
            <EuiFlexItem grow={1}>
              {guide.state === 'preload' && user.user.type !== 'user' ? <DeleteGuide guide={guide} /> : null}
              {guide.reception?.state === 'draft' && user.user.type !== 'user' ? (
                <EuiBeacon style={{backgroundColor: 'red', marginLeft: 5, marginTop: 4}} />
              ) : null}
            </EuiFlexItem>
          </EuiFlexGroup>
        }
        footer={
          <span>
            {user.user.type !== 'user' ? (
              <>
                {loading ? (
                  <EuiLoadingContent lines={1} />
                ) : (
                  <>
                    <EuiHorizontalRule margin='xs' style={{backgroundColor: '#006636'}} />
                    <EuiFlexGroup gutterSize='none' responsive={false} alignItems='center'>
                      <EuiFlexItem grow={1}>
                        <GuideForm edit={true} guide={guide} />
                      </EuiFlexItem>
                      <EuiFlexItem grow={7}></EuiFlexItem>

                      {isGuidePhoto ? <ShowGuidePhoto id={guide.id} state={guide.state} /> : null}

                      <EuiFlexItem grow={1}>
                        <GuidePhoto id={guide.id} isGuidePhoto={isGuidePhoto} state={guide.state} />
                      </EuiFlexItem>

                      {guide.state === 'load' ? (
                        <EuiFlexItem grow={1}>
                          <ReceptionReport setActionEvent={setActionEvent} guide={guide} />
                        </EuiFlexItem>
                      ) : null}
                    </EuiFlexGroup>
                  </>
                )}{' '}
              </>
            ) : null}
          </span>
        }
      ></EuiCard>
    </>
  )
}

export default GuideReception
