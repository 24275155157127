import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {useCookies} from 'react-cookie'

const PrivateRoute = ({component: Component, roles, ...rest}) => {
  const [cookies] = useCookies(['_w3c'])

  return (
    <Route
      {...rest}
      render={(props) => {
        console.log('cookies._w3c**', cookies._w3c)
        if (cookies._w3c === undefined) {
          console.log('cookies._w3c**', cookies._w3c)
          return <Redirect to={{pathname: '/login', state: {from: props.location}}} />
        }
        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute
