import React, {useState, useContext, useEffect} from 'react'
import {EuiButtonIcon, EuiOverlayMask, EuiModalBody, EuiFlexGroup, EuiFlexItem} from '@elastic/eui'
import {DataContext} from './../../context/DataContext'
import Pdf from './../Pdf'
import GUIDES_SERVICES from './../../services/guides'
import QUALITY_SERVICES from './../../services/quality'

const PreviewPdf = ({guide}) => {
  const {state, isGuidePhoto} = guide
  const {user, isMobile} = useContext(DataContext)
  const [maskOpen, changeMask] = useState(false)
  const [guidePhotos, setGuidePhotos] = useState([])
  const [qualityPhotos, setQualityPhotos] = useState([])
  const [typePdf, setTypePdf] = useState('')

  useEffect(() => {
    if (state === 'load') setTypePdf('reception')
    if (state === 'quality') setTypePdf('quality')
    if (state === 'drying') setTypePdf('dispatch')
    if (state === 'dispatch') setTypePdf('attended')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPhotos = async () => {
    await GUIDES_SERVICES.getPhotos(user.token, guide.id)
      .then((data) => {
        setGuidePhotos(data.data.guidePhoto)
        changeMask(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getQualityPhotos = async (type) => {
    await QUALITY_SERVICES.getPhotos(user.token, guide.quality._id)
      .then((data) => {
        setQualityPhotos(data.data.photos)
        changeMask(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const generatePdf = async () => {
    if (typePdf === 'reception') isGuidePhoto ? getPhotos() : changeMask(true)
    if (typePdf === 'quality') getQualityPhotos()
    if (typePdf === 'dispatch' || typePdf === 'attended') changeMask(true)
  }

  const modal = (
    <React.Fragment>
      <EuiOverlayMask
        onClick={() => {
          changeMask(false)
        }}
      >
        <EuiModalBody
          style={isMobile ? {position: 'fixed', overflow: 'scroll', height: '90vh', margin: 'auto'} : {margin: 'auto'}}
        >
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                onClick={() => changeMask(false)}
                iconType='cross'
                iconSize='l'
                color='danger'
                aria-label='Next'
                display='base'
              />
            </EuiFlexItem>
          </EuiFlexGroup>

          <Pdf guide={guide} type={typePdf} user={user} guidePhotos={guidePhotos} qualityPhotos={qualityPhotos} />
        </EuiModalBody>
      </EuiOverlayMask>
    </React.Fragment>
  )

  return (
    <>
      {maskOpen ? modal : undefined}
      <EuiButtonIcon color='danger' iconSize='l' iconType={'document'} onClick={() => generatePdf()} />
    </>
  )
}

export default PreviewPdf
