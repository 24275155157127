import React, {Fragment, useEffect, useState} from 'react'
import {Route, Switch, BrowserRouter as Router} from 'react-router-dom'
import PrivateRoute from './helpers/privateRoute'
import {EuiGlobalToastList, EuiOverlayMask, EuiLoadingSpinner} from '@elastic/eui'
import Home from './pages/Home'
import Login from './pages/Login'
import Users from './pages/Users'
import Configurations from './pages/Configurations'
import {useDispatch, useSelector} from 'react-redux'
import * as actionsUI from './store/actions/uiActions'
import * as actionsAUTH from './store/actions/authActions'
import {useCookies} from 'react-cookie'
import AUTH_SERVICES from './services/auth'

const App = () => {
  const dispatch = useDispatch()
  const ui = useSelector((store) => store.ui)
  const [cookies] = useCookies(['_w3c'])
  const [pass, setPass] = useState(true)

  useEffect(() => {
    if (!cookies._w3c) {
      setPass(false)
    } else {
      AUTH_SERVICES.me(cookies._w3c)
        .then((data) => {
          dispatch(actionsAUTH.loginSuccess(data.data))
          setPass(false)
        })
        .catch((error) => {
          document.cookie.split(';').forEach((c) => {
            document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
          })
          window.location.href = '/login'
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const removeToast = (removedToast) => {
    if (ui.alerts.length === 0) {
      return
    }
    dispatch(actionsUI.removeAlert({id: removedToast.id}))
  }

  const modal = (
    <Fragment>
      <EuiOverlayMask>
        <EuiLoadingSpinner size='xl' />
      </EuiOverlayMask>
    </Fragment>
  )

  if (pass) return modal

  return (
    <>
      <EuiGlobalToastList toasts={ui.alerts} dismissToast={removeToast} toastLifeTimeMs={3000} />
      <Router>
        <Switch>
          <Route path='/login' component={Login} />
          <PrivateRoute path='/configurations' component={Configurations} />
          <PrivateRoute path='/users' component={Users} />
          <PrivateRoute path='/' component={Home} />
        </Switch>
      </Router>
    </>
  )
}

export default App
