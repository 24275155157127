import React, {useEffect, useContext, useState} from 'react'
import {EuiStat, EuiFlexItem, EuiFlexGroup, EuiPanel, EuiHorizontalRule, EuiSpacer, EuiTitle} from '@elastic/eui'
import GUIDES_SERVICES from '../../../../services/guides'
import {DataContext} from './../../../../context/DataContext'
import moment from 'moment'
import momenttz from 'moment-timezone'

const TotalProcess = ({selected, year}) => {
  const {dispatch, actionsUI, user, formatNumber} = useContext(DataContext)
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (selected !== undefined && selected.length > 0) {
      getGuides(selected[0].number, selected[0].producer)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  useEffect(() => {
    if (selected === undefined) {
      getGuides()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selected !== undefined && selected.length > 0) {
      getGuides(selected[0].number, selected[0].producer)
    } else {
      getGuides()
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year])

  const getGuides = async (number, producerId) => {
    setLoading(true)
    await GUIDES_SERVICES.getAll(
      user.token,
      {types: ['admin'], user: user.user, state: 'attended', year},
      '',
      producerId ? producerId : '',
      number,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    )
      .then((data) => {
        let totalGrossWeight = 0
        let totalNetWeight = 0
        let totalDryWeight = 0
        let totalDryWeightTare = 0
        let promMeasure = 0
        let endHumidity = 0
        let countBoxes = 0
        let dryingTime = 0
        let countGuides = 0
        const guides = data.data.docs
        // eslint-disable-next-line array-callback-return
        guides.map((guide) => {
          const {reception, drying} = guide
          const dataContainers = [].concat.apply([], drying.containers)
          const measurements = [].concat.apply([], drying.measurements)

          dryingTime =
            dryingTime +
            moment(drying.createdAt).diff(momenttz(drying.approvalDate).tz('America/Santiago'), 'hour') * -1

          const boxes = [].concat.apply([], drying?.boxes)
          const measureBox = boxes.map((box) => {
            return measurements.filter((measure) => measure.box === box.box)
          })

          measureBox.map((t) => {
            countBoxes++
            return t.length === 0 ? 0 : (promMeasure = promMeasure + t.pop().value)
          })

          dataContainers.map((c) => {
            return (
              (totalDryWeight = totalDryWeight + c.weightWithContainer),
              (totalDryWeightTare = totalDryWeightTare + c.weightWithContainer - c.tare)
            )
          })

          totalGrossWeight = totalGrossWeight + reception.grossWeight
          totalNetWeight = totalNetWeight + reception.netWeight
          endHumidity = (promMeasure / countBoxes).toFixed(1)
          countGuides++
        })

        setData({
          totalGrossWeight,
          totalNetWeight,
          totalDryWeight,
          totalDryWeightTare,
          endHumidity,
          dryingTime: dryingTime <= 0 ? 0 : dryingTime / countGuides,
        })
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  return (
    <>
      <EuiFlexGroup direction='column' style={{marginLeft: 5, marginRight: 5}}>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size='xs'>
              <h4>Recepción</h4>
            </EuiTitle>{' '}
            <EuiHorizontalRule margin='xs' style={{backgroundColor: '#006636'}} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPanel paddingSize='s'>
              <EuiStat
                titleSize='m'
                title={formatNumber(data.totalGrossWeight)}
                description='Total peso bruto'
                textAlign='right'
                isLoading={loading}
                titleColor='subdued'
              ></EuiStat>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel paddingSize='s'>
              <EuiStat
                titleSize='m'
                title={formatNumber(data.totalNetWeight)}
                description='Peso neto'
                textAlign='right'
                isLoading={loading}
                titleColor='primary'
              ></EuiStat>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size='s' />
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size='xs'>
              <h4>Secado</h4>
            </EuiTitle>
            <EuiHorizontalRule margin='xs' style={{backgroundColor: '#006636'}} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPanel paddingSize='s'>
              <EuiStat
                titleSize='s'
                title={formatNumber(data.totalDryWeightTare)}
                description='Peso neto verde'
                titleColor='secondary'
                textAlign='right'
                isLoading={loading}
              ></EuiStat>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel paddingSize='s'>
              <EuiStat
                titleSize='s'
                title={formatNumber(data.totalDryWeight)}
                description='Peso bruto verde'
                titleColor='danger'
                textAlign='right'
                isLoading={loading}
              ></EuiStat>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel paddingSize='s'>
              <EuiStat
                titleSize='s'
                title={`${formatNumber(((data.totalDryWeightTare / data.totalNetWeight) * 100).toFixed(1))}%`}
                description='Rendimiento'
                titleColor='blue'
                textAlign='right'
                isLoading={loading}
              ></EuiStat>
            </EuiPanel>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiPanel paddingSize='s'>
              <EuiStat
                titleSize='s'
                title={`${formatNumber(data.endHumidity)}%`}
                description='Humedad'
                titleColor='accent'
                textAlign='right'
                isLoading={loading}
              ></EuiStat>
            </EuiPanel>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiPanel paddingSize='s'>
              <EuiStat
                titleSize='s'
                title={`${formatNumber(data.dryingTime)} Hrs`}
                description='T. Secado'
                titleColor='accent'
                textAlign='right'
                isLoading={loading}
              ></EuiStat>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexGroup>
      <EuiSpacer />
    </>
  )
}

export default TotalProcess
