import React, {useState} from 'react'
import {
  EuiFormRow,
  EuiImage,
  EuiPanel,
  EuiFieldText,
  EuiFieldPassword,
  EuiButton,
  EuiText,
  EuiLink,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui'
import './index.css'
import {validate} from 'validate.js'
import {useDispatch} from 'react-redux'
import {useCookies} from 'react-cookie'
import {useHistory} from 'react-router-dom'
import {constraints, constraintsRecovery} from './validates'
import '@elastic/eui/dist/eui_theme_light.css'
import LogoImg from '../../assets/images/logo-green.png'
import AUTH_SERVICES from '../../services/auth'
import * as actionsAUTH from '../../store/actions/authActions'
import * as actionsUI from '../../store/actions/uiActions'

const Login = () => {
  let history = useHistory()
  const dispatch = useDispatch()
  const [, setCookie] = useCookies(['_w3c'])
  const [errors, setErrors] = useState({})
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [recovery, setRecovery] = useState(false)
  const [loading, setLoading] = useState(false)

  const onChange = (e, t) => {
    if (t === 'E') {
      setEmail(e.target.value)
    } else {
      setPassword(e.target.value)
    }
  }

  const changeOption = () => {
    setRecovery(!recovery)
    setErrors([])
  }

  const handleLogin = (number) => {
    setLoading(!loading)

    const form = {
      email: email.trim(),
      password: password.trim(),
    }
    let validationResult

    validationResult = number === 1 ? validate(form, constraints) : validate(form, constraints)

    if (validationResult !== undefined) {
      setLoading(false)
      setErrors(validationResult)
      return
    }
    setErrors({})
    AUTH_SERVICES.login(form)
      .then((data) => {
        dispatch(actionsAUTH.loginSuccess(data.data))
        setCookie('_w3c', data.data.token)
        history.push('/')
      })
      .catch((error) => {
        setLoading(false)
        console.log({error})
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const handleRecovery = (number) => {
    const form = {
      email: email.trim(),
    }
    let validationResult
    validationResult = number === 1 ? validate(form, constraintsRecovery) : validate(form, constraintsRecovery)
    if (validationResult !== undefined) {
      setErrors(validationResult)
      return
    }

    setErrors({})
    AUTH_SERVICES.recovery(email)
      .then((data) => {
        dispatch(actionsUI.addAlert({id: 1, title: data.data.message, color: 'success', iconType: 'check'}))
        setRecovery(false)
      })
      .catch((error) => {
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <EuiFlexGroup style={{margin: 0}} direction='column' alignItems='center'>
        <EuiFlexItem>
          <EuiPanel
            className='panel'
            style={{
              height: 540,
              boxShadow: ' 0px 24px 20px -17px rgba(42, 35, 50, 0.78309)',
            }}
          >
            <EuiFormRow display='rowCompressed' style={{alignItems: 'center', marginTop: 50, marginBottom: 30}}>
              <EuiImage size='m' alt='Accessible image alt goes here' url={LogoImg} style={{alignSelf: 'center'}} />
            </EuiFormRow>
            <EuiFormRow
              className='fields'
              style={{height: 88}}
              label='Email'
              display='rowCompressed'
              isInvalid={errors.email === undefined ? false : true}
              error={errors.email === undefined ? '' : errors.email[0]}
            >
              <EuiFieldText
                fullWidth={true}
                style={{alignSelf: 'center', height: 58, left: 0, top: 0}}
                icon='user'
                name='email'
                compressed
                isInvalid={errors.email === undefined ? false : true}
                onChange={(e) => onChange(e, 'E')}
              />
            </EuiFormRow>
            {!recovery && (
              <EuiFormRow
                style={{height: 88}}
                label='Contraseña'
                display='rowCompressed'
                isInvalid={errors.password === undefined ? false : true}
                error={errors.password === undefined ? '' : errors.password[0]}
              >
                <EuiFieldPassword
                  fullWidth={true}
                  style={{alignSelf: 'center', height: 58}}
                  icon='lock'
                  name='password'
                  compressed
                  isInvalid={errors.password === undefined ? false : true}
                  onChange={(e) => onChange(e, 'P')}
                />
              </EuiFormRow>
            )}

            <EuiFormRow display='rowCompressed' hasChildLabel={false}>
              <EuiButton
                fullWidth={true}
                isLoading={loading}
                type='submit'
                color='secondary'
                fill
                style={{alignSelf: 'center', height: 58}}
                onClick={() => {
                  !recovery ? handleLogin(1) : handleRecovery(1)
                }}
              >
                {loading ? '' : recovery === false ? 'Inicio' : 'Recuperar'}
              </EuiButton>
            </EuiFormRow>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow display='rowCompressed' hasChildLabel={false} style={{alignItems: 'flex-start'}}>
            <EuiText size='xs'>
              Olvidaste tu contraseña ?
              <EuiLink style={{marginLeft: 5}} color='secondary' onClick={changeOption}>
                {recovery === false ? 'Recuperar' : 'Login'}
              </EuiLink>
            </EuiText>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}

export default Login
