export const constraintsEn = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^Please enter a name',
    },
  },
}

export const constraintsEs = {
  producerRut: {
    presence: {
      allowEmpty: false,
    },
  },
  driverRut: {
    presence: {
      allowEmpty: false,
    },
  },
  number: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      notGreaterThan: 'debe ser mayor a 0',
      message: ': Ingrese guía de despacho',
    },
  },
  weight: {
    numericality: {
      greaterThan: 0,
      notGreaterThan: 'debe ser mayor a 0',
      message: ': Ingrese peso',
    },
  },
}

export const constraintsEsExporter = {
  rut: {
    presence: {
      allowEmpty: false,
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese email',
    },
    email: {
      message: '^Ingrese email valido',
    },
  },
}

export const constraintsEsProducer = {
  rut: {
    presence: {
      allowEmpty: false,
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese email',
    },
    email: {
      message: '^Ingrese email valido',
    },
  },
}

export const constraintsEsDriver = {
  rut: {
    presence: {
      allowEmpty: false,
    },
  },
  name: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese nombre',
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese apellido',
    },
  },
}
