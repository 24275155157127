import React, {useState, useContext} from 'react'
import {EuiBasicTable, EuiSpacer, EuiHealth} from '@elastic/eui'
import Filters from './../Filters'
import moment from 'moment'
import momenttz from 'moment-timezone'
import {DataContext} from './../../../context/DataContext'
import EMAIL_SERVICES from '../../../services/email'

const MailReception = () => {
  const {dispatch, actionsUI, user, useDebounce} = useContext(DataContext)
  const [loading, setLoading] = useState(false)
  const [selectedProducer, setSelectedProducer] = useState([])
  const [selectedExporter, setSelectedExporter] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([])
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months').startOf('day'))
  const [endDate, setEndDate] = useState(moment().add(1, 'd').endOf('day'))
  const [numberGuideFilter, setNumberGuideFilter] = useState('')
  const [activity, setActivity] = useState([])

  const onChangeNumberGuide = (e) => {
    setNumberGuideFilter(Number(e.target.value))
    debounceNumberGuide(Number(e.target.value))
  }

  const onChangeProducer = (itemSelected) => {
    setSelectedProducer(itemSelected)
    if (itemSelected.length > 0) {
      setSelectedStatus([])
      setSelectedExporter([])
      setNumberGuideFilter('')
      getActivity(itemSelected, '')
    }
  }

  const onChangeExporter = (itemSelected) => {
    setSelectedExporter(itemSelected)
    if (itemSelected.length > 0) {
      setSelectedStatus([])
      setSelectedProducer([])
      setNumberGuideFilter('')
      getActivity('', itemSelected)
    }
  }

  const onChangeStatus = (itemSelected) => {
    setSelectedStatus(itemSelected)
    if (itemSelected.length > 0) {
      setSelectedProducer([])
      setNumberGuideFilter('')
      setSelectedExporter([])
      getActivity('', '', '', '', itemSelected)
    }
  }

  const handleChangeStart = (date) => {
    setSelectedStatus([])
    setSelectedExporter([])
    setSelectedProducer([])
    setNumberGuideFilter('')
    setStartDate(date)
    getActivity('', '', date, endDate)
  }

  const handleChangeEnd = (date) => {
    setSelectedStatus([])
    setSelectedExporter([])
    setSelectedProducer([])
    setNumberGuideFilter('')
    setEndDate(date)
    getActivity('', '', startDate, date)
  }

  const debounceNumberGuide = useDebounce((val) => getActivity('', '', val), 1000)

  const getActivity = async (producer, exporter, startDate, endDate, status) => {
    const form = {
      producer: producer[0],
      exporter: exporter[0],
      startDate,
      endDate,
      status: status ? status[0] : '',
    }
    setLoading(true)

    await EMAIL_SERVICES.getActivity(user.token, {form})
      .then((data) => {
        setActivity(data.data.messages)
        setLoading(false)
      })
      .catch((e) => {
        dispatch(actionsUI.addAlert({id: 1, title: e.response.data.message, color: 'danger', iconType: 'alert'}))
        setLoading(false)
      })
  }

  const columns = [
    {
      field: 'to_email',
      name: 'Correo destino',
    },
    {
      field: 'subject',
      name: 'Asunto',
    },
    {
      field: 'status',
      name: 'Estatus',
      render: (value) => {
        const color = value === 'delivered' ? 'success' : 'danger'
        return <EuiHealth color={color}>{value === 'delivered' ? 'Entregado' : 'No entregado'}</EuiHealth>
      },
    },
    {
      field: 'last_event_time',
      name: 'Fecha',
      render: (date) => moment(momenttz(date).tz('America/Santiago')).format('DD-MM-YYYY HH:mm'),
    },
  ]

  return (
    <>
      <EuiSpacer size='m' />
      <Filters
        loading={loading}
        getGuides=''
        onChangeNumberGuide={onChangeNumberGuide}
        numberGuideFilter={numberGuideFilter}
        onChangeProducer={onChangeProducer}
        selectedProducer={selectedProducer}
        onChangeExporter={onChangeExporter}
        selectedExporter={selectedExporter}
        startDate={startDate}
        handleChangeStart={handleChangeStart}
        endDate={endDate}
        handleChangeEnd={handleChangeEnd}
        exitNumberGuideFilter='fromMail'
        onChangeExitNumberGuide=''
        selectedStatus={selectedStatus}
        onChangeStatus={onChangeStatus}
      />
      <EuiSpacer size='m' />
      <EuiBasicTable
        loading={loading}
        items={activity}
        rowHeader='firstName'
        columns={columns}
        //rowProps={getRowProps}
        // cellProps={getCellProps}
      />
    </>
  )
}

export default MailReception
