import API from './constants'

var DRIVERS_SERVICES = {
  getByRut: async (token, rut) => {
    return await API.get(`driver/rut/${rut}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  createFromPreLoad: async (token, data) => {
    return await API.post(`driver/preLoad/`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
}

export default DRIVERS_SERVICES
