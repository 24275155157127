import API from './constants'

var CARRIERS_SERVICES = {
  getByRut: async (token, rut) => {
    return await API.get(`carrier/rut/${rut}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  createFromPreLoad: async (token, data) => {
    return await API.post(`carrier/preLoad/`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  update: async (token, data, id) => {
    return await API.put(`carrier/update/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
}

export default CARRIERS_SERVICES
