import React, {useState} from 'react'
import Resizer from 'react-image-file-resizer'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFilePicker,
  EuiText,
  EuiImage,
  EuiCard,
  EuiIcon,
  EuiButtonIcon,
  EuiFormErrorText,
  EuiOverlayMask,
} from '@elastic/eui'
import Camera, {FACING_MODES, IMAGE_TYPES} from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'

const FilePicker = ({files, setFiles, handleTakePhoto}) => {
  const [maskOpenCamera, changeMaskCamera] = useState(false)

  const onChange = (fil) => {
    Object.keys(fil).map((item, i) => {
      return resizeFile(fil[item])
    })
  }

  const resizeFile = async (file) => {
    setFiles([
      ...files,
      await new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          1080,
          1920,
          'JPEG',
          30,
          0,
          (uri) => {
            resolve(uri)
          },
          'base64',
        )
      }),
    ])
  }

  const modalCamera = (
    <EuiOverlayMask
      onClick={() => {
        changeMaskCamera(false)
      }}
    >
      <Camera
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        imageType={IMAGE_TYPES.JPG}
        isImageMirror={false}
        imageCompression={0.95}
        onTakePhoto={(dataUri) => {
          handleGetPhoto(dataUri)
        }}
      />
    </EuiOverlayMask>
  )

  /* const fileReader = (file) => {
    let reader = new FileReader()
    reader.onload = handleReaderLoaded.bind(this)
    reader.readAsBinaryString(file)
  } */

  /* const handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result
    let base64 = 'data:image/jpg;base64,' + btoa(binaryString)

    setFiles([...files, base64])
  } */

  function handleGetPhoto(dataUri) {
    setFiles([...files, dataUri])
    handleTakePhoto()
    changeMaskCamera(false)
  }

  const deleteFile = (item) => {
    const newFiles = files.filter((e) => e !== item)
    setFiles(newFiles)
  }

  const renderFiles = () => {
    if (files.length > 0) {
      return (
        <ul>
          {Object.keys(files).map((item, i) => {
            return (
              <EuiImage
                key={i}
                allowFullScreen
                hasShadow
                size={50}
                caption={
                  <EuiButtonIcon onClick={() => deleteFile(files[item])} iconType='cross' iconSize='m' color='danger' />
                }
                url={`${files[item]}`}
              />
            )
          })}
        </ul>
      )
    }
  }

  return (
    <>
      {maskOpenCamera ? modalCamera : undefined}

      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFilePicker
            fullWidth
            id='files'
            multiple
            compressed
            initialPromptText='Seleccione imagen'
            onChange={(files) => {
              onChange(files)
            }}
            isInvalid={files.length > 0 ? false : true}
            error={'error'}
          />
          {files.length > 0 ? null : <EuiFormErrorText id={1}>Ingrese al menos una imagen</EuiFormErrorText>}
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size='xxl' type='image' />}
            title='Utilizar camara'
            titleSize='xs'
            paddingSize='none'
            onClick={() => changeMaskCamera(true)}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText>{renderFiles()}</EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

export default FilePicker
