import API from './constants'

var DISPATCH_SERVICES = {
  create: async (token, data) => {
    return await API.post(`dispatch`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  update: async (token, data, id) => {
    return await API.put(`dispatch/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  deleteTrip: async (token, data, id) => {
    return await API.put(`dispatch/deleteTrip/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  updateState: async (token, data, id) => {
    return await API.put(`dispatch/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  updateCommentary: async (token, data, id) => {
    return await API.put(`dispatch/updateCommentary/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
}

export default DISPATCH_SERVICES
