import API from './constants'

var PRODUCERS_SERVICES = {
  getByRut: async (token, rut) => {
    return await API.get(`producer/rut/${rut}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  getByRutGuide: async (token, rut) => {
    return await API.get(`producer/rutGuide/${rut}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  createFromPreLoad: async (token, data) => {
    return await API.post(`producer/preLoad/`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  create: async (token, data) => {
    return await API.post(`producer/`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  getAll: async (token, types) => {
    return await API.post(
      `producer/all`,
      {types},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        contentType: true,
      },
    )
  },
  update: async (token, data, id) => {
    return await API.put(`producer/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
}

export default PRODUCERS_SERVICES
