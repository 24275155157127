import React, {useState} from 'react'

import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiLink,
  EuiText,
  EuiSpacer,
  EuiPopover,
  htmlIdGenerator,
} from '@elastic/eui'
import UpdatePassword from './../UpdatePassword'
import EditProfile from './../EditProfile'

const HeaderUserMenu = ({name, user}) => {
  const id = htmlIdGenerator()()
  const [isOpen, setIsOpen] = useState(false)
  const [updatePassword, setUpdatePassword] = useState(false)
  const [editProfile, setEditProfile] = useState(false)

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    setIsOpen(false)
    setEditProfile(false)
    setUpdatePassword(false)
  }

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={id}
      aria-expanded={isOpen}
      aria-haspopup='true'
      aria-label='Account menu'
      onClick={onMenuButtonClick}
    >
      <EuiAvatar name={name} size='s' style={{textTransform: 'uppercase', backgroundColor: '#56D5FC'}} />
    </EuiHeaderSectionItemButton>
  )

  const goLogout = () => {
    document.cookie.split(';').forEach((c) => {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
    })
    window.location.href = '/login'
  }

  const handleUpdatePassword = (option) => {
    setUpdatePassword(option)
    setEditProfile(false)
  }

  const handleEditProfile = (option) => {
    setEditProfile(option)
    setUpdatePassword(false)
  }

  return (
    <EuiPopover
      id={id}
      ownFocus
      button={button}
      isOpen={isOpen}
      anchorPosition='downRight'
      closePopover={closeMenu}
      panelPaddingSize='none'
    >
      <div style={{width: 320}}>
        <EuiFlexGroup gutterSize='m' className='euiHeaderProfile' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiAvatar name={name} size='xl' style={{textTransform: 'uppercase', backgroundColor: 'red'}} />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>{name}</EuiText>
            <EuiSpacer size='s' />
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFlexGroup justifyContent='spaceBetween'>
                  <EuiFlexItem grow={false}>
                    <EuiLink onClick={() => handleEditProfile(true)}>Editar perfil</EuiLink>
                  </EuiFlexItem>

                  <EuiFlexItem grow={false}>
                    <EuiLink onClick={goLogout}>Cerrar sesión</EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size='s' />
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFlexGroup justifyContent='spaceBetween'>
                  <EuiFlexItem grow={false}>
                    <EuiLink onClick={() => handleUpdatePassword(true)}>Cambiar contraseña</EuiLink>
                  </EuiFlexItem>

                  <EuiFlexItem grow={false}></EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
        {updatePassword ? (
          <EuiFlexGroup gutterSize='m'>
            <EuiFlexItem grow={false}></EuiFlexItem>
            <EuiFlexItem>
              <UpdatePassword user={user} handleUpdatePassword={handleUpdatePassword} />
            </EuiFlexItem>
            <EuiFlexItem grow={false}></EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          false
        )}

        {editProfile ? (
          <EuiFlexGroup gutterSize='m'>
            <EuiFlexItem grow={false}></EuiFlexItem>
            <EuiFlexItem>
              <EditProfile user={user} handleEditProfile={handleEditProfile} editProfile={editProfile} />
            </EuiFlexItem>
            <EuiFlexItem grow={false}></EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          false
        )}
        <EuiSpacer size='s' />
      </div>
    </EuiPopover>
  )
}

export default HeaderUserMenu
