export const constraintsEs = {
  number: {
    presence: {
      allowEmpty: false,
      message: ': Ingrese guía de despacho',
    },
  },
  producerName: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese razón social',
    },
  },
  /*contact: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      message: ': Ingrese numero de contacto',
    },
  },*/
  producerAddress: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese dirección',
    },
  },
  producerCity: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese ciudad',
    },
  },
  producerCommune: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese comuna',
    },
  },
  producerTurn: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese giro',
    },
  },
  producerEmail: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese email',
    },
    email: {
      message: '^Ingrese email valido',
    },
  },
  /* exporterName: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese nombre',
    },
  },
  exporterAddress: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese dirección',
    },
  },
  exporterCity: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese ciudad',
    },
  },
  exporterCommune: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese comuna',
    },
  },
  exporterEmail: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese email',
    },
    email: {
      message: '^Ingrese email valido',
    },
  },
  phone: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese numero',
    },
  },
  exporterTurn: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese giro',
    },
  },
  activity: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese actividad economica',
    },
  }, */
  date: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese fecha',
    },
  },
  transferType: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese tipo de traslado',
    },
  },
  carrierName: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese nombre',
    },
  },
  patent: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese patente',
    },
  },
  driverName: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese nombre',
    },
  },
  driverLastName: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese apellido',
    },
  },
  weight: {
    numericality: {
      greaterThan: 0,
      notGreaterThan: 'debe ser mayor a 0',
      message: ': Ingrese peso',
    },
  },
  /* priority: {
    presence: {
      allowEmpty: false,
      message: '^Seleccione prioridad',
    },
  }, */
  productName: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese nombre',
    },
  },
}

export const constraintsEsProducer = {
  producerRut: {
    presence: {
      allowEmpty: false,
    },
  },
  name: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese nombre',
    },
  },
}

export const valProducts = {
  /* name: {
    presence: {
      allowEmpty: false,
      message: '^Ingrese nombre',
    },
  },
  quantity: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      message: '^ Ingrese cantidad',
    },
  }, */
}
