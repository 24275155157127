export const AUTH_TYPES = { 
    AUTH_LOGIN: '[AUTH LOGIN] auth login',
    AUTH_LOGIN_SUCCESS: '[AUTH LOGIN] login success',
    AUTH_LOGIN_OFF: '[AUTH LOGIN] login off',
    AUTH_LOGOUT: '[AUTH LOGOUT] logout',
    AUTH_ME: '[AUTH ME] me',
    AUTH_REGISTER: '[AUTH] register'
};

export const login = () => ({
    type: AUTH_TYPES.AUTH_LOGIN,
});

export const loginSuccess = (user) => ({
    type: AUTH_TYPES.AUTH_LOGIN_SUCCESS,
    user
});

export const loginOff = () => ({
    type: AUTH_TYPES.AUTH_LOGIN_OFF,
});

