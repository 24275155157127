import React, {useEffect, useContext, useState} from 'react'
import {Chart, Settings, BarSeries, Axis} from '@elastic/charts'
import {
  EuiComboBox,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiLoadingContent,
  EuiSuperSelect,
} from '@elastic/eui'
import {EUI_CHARTS_THEME_DARK, EUI_CHARTS_THEME_LIGHT} from '@elastic/eui/dist/eui_charts_theme'
import moment from 'moment'
import '@elastic/charts/dist/theme_only_light.css'
import GUIDES_SERVICES from '../../../services/guides'
import {DataContext} from './../../../context/DataContext'
import TotalProcess from './TotalProcess'
import PieChart from './PieChart'
import AverageTimes from './AverageTimes'

const Kpis = () => {
  const {dispatch, actionsUI, user} = useContext(DataContext)
  const [loading, setLoading] = useState(false)
  const [netWeight, setNetWeight] = useState([])
  const [quality, setQuality] = useState([])
  const [qualityPie1, setQualityPie1] = useState([])
  const [qualityPie2, setQualityPie2] = useState([])
  const [isDarkTheme] = useState(false)
  const [comboGuide1, setComboGuide1] = useState([])
  const [selectedGuide1, setSelectedGuide1] = useState([])
  const [selectedGuide2, setSelectedGuide2] = useState([])
  const [averageGuideTime, setAverageGuideTime] = useState('')
  const [years, setYears] = useState([
    {
      value: moment().format('YYYY'),
      inputDisplay: `${moment().format('YYYY')}`,
    },
  ])
  const [selectedYear, setSelectedYear] = useState(Number(moment().format('YYYY')))
  const euiChartTheme = isDarkTheme ? EUI_CHARTS_THEME_DARK.theme : EUI_CHARTS_THEME_LIGHT.theme
  const euiPartitionConfig = euiChartTheme.partition

  useEffect(() => {
    getTotalGross()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeGuide1 = (itemSelected) => {
    setSelectedGuide1(itemSelected)
    calculateQuality(itemSelected, 'pie1')
  }

  const onChangeYear = (selectedOptions) => {
    setSelectedYear(selectedOptions)
    getTotalGross(selectedOptions)
  }

  const onChangeGuide2 = (itemSelected) => {
    setSelectedGuide2(itemSelected)
    calculateQuality(itemSelected, 'pie2')
  }

  const calculateQuality = (data, type) => {
    let sum1 = 0
    let sum2 = 0
    let sum3 = 0
    let sum4 = 0
    let sum5 = 0
    let sumT = 0

    data.map((q) => {
      // eslint-disable-next-line array-callback-return
      return q.quality.map((s) => {
        const {p1, p2, p3, p4, p5} = s.samples
        sumT = sumT + Number(p1) + Number(p2) + Number(p3) + Number(p4) + Number(p5)
        sum1 = sum1 + Number(p1)
        sum2 = sum2 + Number(p2)
        sum3 = sum3 + Number(p3)
        sum4 = sum4 + Number(p4)
        sum5 = sum5 + Number(p5)
      })
    })
    sum1 = sum1 / data.length
    sum2 = sum2 / data.length
    sum3 = sum3 / data.length
    sum4 = sum4 / data.length
    sum5 = sum5 / data.length
    sumT = sumT / data.length

    let result = [
      {total: sum1, name: 'Pelón adherido'},
      {total: sum2, name: 'Daño por insecto'},
      {total: sum3, name: 'Hongo en cascara'},
      {total: sum4, name: 'Nueces partidas y trizadas'},
      {total: sum5, name: 'Nueces vanas'},
      {total: 100 - sumT, name: 'En buen estado'},
    ]
    if (type === 'anual') {
      setQuality(result)
    }

    if (type === 'pie1') {
      setQualityPie1(result)
    }

    if (type === 'pie2') {
      setQualityPie2(result)
    }
  }

  const compareQuality = (data) => {
    const dataGuides = data.map((c) => ({
      id: c._id,
      label: `[ ${c.number} ] ${c.producer[0].name}`,
      quality: c.quality,
      number: c.number,
      producer: c.producer[0]._id,
    }))
    setComboGuide1(dataGuides)
  }

  const getYears = (data) => {
    const dataYears = data.map((c) => ({
      value: c._id,
      inputDisplay: `${c._id}`,
    }))
    setYears(dataYears)
  }
  const getTotalGross = async (year) => {
    setLoading(true)
    await GUIDES_SERVICES.getData(user.token, {types: ['admin'], year: year ? year : selectedYear})
      .then((data) => {
        calculateQuality(data.data.quality, 'anual')
        compareQuality(data.data.quality)
        getYears(data.data.years)
        setNetWeight(
          data.data.netWeight.map((guides) => {
            return {_id: guides._id[0], netWeight: guides.netWeight}
          }),
        )
        setAverageGuideTime(data.data.averageGuideTime)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        dispatch(actionsUI.addAlert({id: 1, title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  return (
    <>
      <EuiSpacer />
      {years ? (
        <EuiFlexGroup>
          <EuiFlexItem grow={8}>
            {' '}
            <EuiTitle size='m'>
              <h4>Total kilos por productor anual</h4>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiTitle size='s'>
              <h4>Filtro por año</h4>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiSuperSelect
              placeholder='Seleccione año'
              singleSelection={{asPlainText: true}}
              options={years}
              valueOfSelected={selectedYear}
              onChange={onChangeYear}
              isLoading={loading}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : null}

      <EuiSpacer />

      <EuiPanel paddingSize='s' color='subdued'>
        <EuiFlexGroup>
          <EuiFlexItem>
            {loading ? (
              <EuiLoadingContent lines={10} />
            ) : (
              <Chart size={{height: 300}}>
                <Settings
                  theme={isDarkTheme ? EUI_CHARTS_THEME_DARK.theme : EUI_CHARTS_THEME_LIGHT.theme}
                  rotation={90}
                  showLegend={false}
                />
                <BarSeries
                  id='_id'
                  name='kilos'
                  data={netWeight}
                  xAccessor={'_id'}
                  yAccessors={['netWeight']}
                  //splitSeriesAccessors={['_id']}
                />
                <Axis id='bottom-axis' position={'left'} showGridLines={false} />
                <Axis id='left-axis' position={'bottom'} tickFormat={(d) => d} />
              </Chart>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
      <EuiSpacer />
      <EuiTitle size='m'>
        <h4>Medidas de procesos y calidad anual</h4>
      </EuiTitle>
      <EuiSpacer />
      <EuiPanel paddingSize='s' color='subdued'>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiTitle size='s'>
                  <h4>Control de procesos</h4>
                </EuiTitle>
                <EuiSpacer />
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
              <EuiFlexItem>
                {averageGuideTime ? <AverageTimes averageGuideTime={averageGuideTime} year={selectedYear} /> : null}
                <EuiSpacer />
                <TotalProcess year={selectedYear} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle size='s'>
              <h4>Promedio de calidad </h4>
            </EuiTitle>
            <EuiSpacer />
            <EuiPanel paddingSize='s'>
              <PieChart data={quality} euiChartTheme={euiChartTheme} euiPartitionConfig={euiPartitionConfig} />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size='m'>
            <h4>Comparación por guías</h4>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel paddingSize='s' color='subdued'>
            <EuiFlexGroup direction='column'>
              <EuiFlexItem grow={false}>
                <EuiComboBox
                  placeholder='Seleccione guía'
                  singleSelection={{asPlainText: true}}
                  options={comboGuide1}
                  selectedOptions={selectedGuide1}
                  onChange={onChangeGuide1}
                  fullWidth
                  isDisabled={loading}
                />
              </EuiFlexItem>

              {selectedGuide1.length > 0 ? (
                <>
                  <EuiFlexItem grow={false}>
                    <EuiTitle size='s'>
                      <h4>Promedio de Calidad</h4>
                    </EuiTitle>
                    <EuiPanel paddingSize='s'>
                      <PieChart
                        data={qualityPie1}
                        euiChartTheme={euiChartTheme}
                        euiPartitionConfig={euiPartitionConfig}
                      />{' '}
                    </EuiPanel>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <TotalProcess selected={selectedGuide1} year={selectedYear} />
                  </EuiFlexItem>
                </>
              ) : (
                <EuiFlexItem grow={false} style={{height: 300}}></EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel paddingSize='s' color='subdued'>
            <EuiFlexGroup direction='column'>
              <EuiFlexItem grow={false}>
                <EuiComboBox
                  placeholder='Seleccione guía'
                  singleSelection={{asPlainText: true}}
                  options={comboGuide1}
                  selectedOptions={selectedGuide2}
                  onChange={onChangeGuide2}
                  fullWidth
                  isDisabled={loading}
                />
              </EuiFlexItem>
              {selectedGuide2.length > 0 ? (
                <>
                  <EuiFlexItem grow={false}>
                    <EuiTitle size='s'>
                      <h4>Promedio de Calidad</h4>
                    </EuiTitle>
                    <EuiPanel paddingSize='s'>
                      <PieChart
                        data={qualityPie2}
                        euiChartTheme={euiChartTheme}
                        euiPartitionConfig={euiPartitionConfig}
                      />
                    </EuiPanel>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <TotalProcess selected={selectedGuide2} year={selectedYear} />
                  </EuiFlexItem>
                </>
              ) : null}
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

export default Kpis
