import React, {useState, useContext} from 'react'
import {
  EuiButton,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiCollapsibleNavGroup,
  EuiFieldNumber,
  EuiCallOut,
  EuiProgress,
} from '@elastic/eui'
import {validate} from 'validate.js'
import {validate as validateRut, clean, format} from 'rut.js'
import {constraintsEs, constraintsEsExporter, constraintsEsProducer, constraintsEsDriver} from './validates'
import GUIDES_SERVICES from '../../services/guides'
import EXPORTERS_SERVICES from '../../services/exporters'
import PRODUCERS_SERVICES from '../../services/producers'
import DRIVERS_SERVICES from '../../services/drivers'
import CARRIERS_SERVICES from './../../services/carriers'
import {DataContext} from './../../context/DataContext'

const GuidePreLoad = ({setIsPreLoad}) => {
  const {addGuide, dispatch, actionsUI, user} = useContext(DataContext)
  const [isModalVisibleGuide, setIsModalVisibleGuide] = useState(false)
  const [errors, setErrors] = useState({})
  const [errorsExporter, setErrorsExporter] = useState({})
  const [errorsProducer, setErrorsProducer] = useState({})
  const [errorsDriver, setErrorsDriver] = useState({})
  const [disable, setDisable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [weight, setWeight] = useState('')
  const [exporterEmail, setExporterEmail] = useState('')
  const [exporterId, setExporterId] = useState()
  const [exporterNotFound, setExporterNotFound] = useState(false)
  const [exporterRut, setExporterRut] = useState('')
  const [producerEmail, setProducerEmail] = useState('')
  const [driverName, setDriverName] = useState('')
  const [driverLastName, setDriverLastName] = useState('')
  const [producerId, setProducerId] = useState()
  const [producerNotFound, setProducerNotFound] = useState(false)
  const [producerRut, setProducerRut] = useState('')
  const [carrierName, setCarrierName] = useState('')
  const [carrierNotFound, setCarrierNotFound] = useState(false)
  const [carrierId, setCarrierId] = useState()
  const [carrierRut, setCarrierRut] = useState('')
  const [driverId, setDriverId] = useState()
  const [driverNotFound, setDriverNotFound] = useState(false)
  const [driverRut, setDriverRut] = useState('')
  const [number, setNumber] = useState()

  const onChangeWeight = (e) => {
    setWeight(e.target.value <= 0 ? '' : e.target.value)
  }

  const onChangeNumber = (e) => {
    setNumber(e.target.value)
  }

  /**
   * EXPORTADOR
   */

  const onChangeExporterRut = async (e) => {
    setExporterRut(format(e.target.value))
    const cleanrut = clean(e.target.value)
    if (!validateRut(cleanrut)) {
      setDisable(true)
      setErrors({exporterRut: ['Rut invalido']})
    } else {
      setDisable(false)
      delete errors.exporterRut
    }
    if (cleanrut.length >= 8 && validateRut(cleanrut)) {
      setLoading(true)
      await EXPORTERS_SERVICES.getByRut(user.token, cleanrut)
        .then((data) => {
          setExporterNotFound(false)
          setExporterId(data.data._id)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          setExporterNotFound(true)
        })
    }
  }

  const onChangeExporterEmail = (e) => {
    setExporterEmail(e.target.value)
  }

  const onChangeProducerEmail = (e) => {
    setProducerEmail(e.target.value)
  }

  const onChangeDriverName = (e) => {
    setDriverName(e.target.value)
  }

  const onChangeDriverLastName = (e) => {
    setDriverLastName(e.target.value)
  }

  /**
   *  PRODUCTOR
   */

  const onChangeProducerRut = async (e) => {
    setProducerRut(format(e.target.value))
    const cleanrut = clean(e.target.value)

    if (!validateRut(cleanrut)) {
      setDisable(true)
      setErrors({producerRut: ['Rut invalido']})
    } else {
      setDisable(false)
      delete errors.producerRut
    }

    if (cleanrut.length >= 8 && validateRut(cleanrut)) {
      setLoading(true)
      await PRODUCERS_SERVICES.getByRut(user.token, cleanrut)
        .then((data) => {
          setLoading(false)
          setProducerNotFound(false)
          setProducerId(data.data._id)
        })
        .catch((error) => {
          setLoading(false)
          setProducerNotFound(true)
        })
    }
  }

  /**
   * TRANSPORTISTA
   */

  const onChangeCarrierName = (e) => {
    setCarrierName(e.target.value)
  }

  const onChangeCarrierRut = async (e) => {
    setCarrierRut(format(e.target.value))

    const cleanrut = clean(e.target.value)

    if (!validateRut(cleanrut)) {
      setDisable(true)
      setErrors({carrierRut: ['Rut invalido']})
    } else {
      setDisable(false)
      delete errors.carrierRut
    }

    if (cleanrut.length >= 8 && validateRut(cleanrut)) {
      setLoading(true)
      await CARRIERS_SERVICES.getByRut(user.token, cleanrut)
        .then((data) => {
          setCarrierId(data.data._id)
          setCarrierNotFound(false)
          setLoading(false)
        })
        .catch(async (error) => {
          setCarrierNotFound(true)
          setLoading(false)
        })
    }
  }

  const saveCarrier = async () => {
    setLoading(true)
    const form = {
      rut: clean(carrierRut),
      name: carrierName,
    }
    await CARRIERS_SERVICES.createFromPreLoad(user.token, form)
      .then((data) => {
        setCarrierNotFound(false)
        setCarrierId(data.data._id)
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: 'Transportista ingresado', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  /**
   * CHOFER
   * */

  const onChangeDriverRut = async (e) => {
    setLoading(true)
    setDriverRut(format(e.target.value))

    const cleanrut = clean(e.target.value)

    if (!validateRut(cleanrut)) {
      setDisable(true)
      setErrors({driverRut: ['Rut invalido']})
      setLoading(false)
    } else {
      setDisable(false)
      setLoading(false)
      delete errors.driverRut
    }

    if (cleanrut.length >= 8 && validateRut(cleanrut)) {
      await DRIVERS_SERVICES.getByRut(user.token, cleanrut)
        .then((data) => {
          setDriverNotFound(false)
          setDriverId(data.data._id)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          setDriverNotFound(true)
        })
    }
  }

  const showModalGuide = () => setIsModalVisibleGuide(true)
  const closeModalGuide = () => setIsModalVisibleGuide(false)

  const saveExporter = async () => {
    setLoading(true)
    const form = {
      rut: clean(exporterRut),
      email: exporterEmail,
      name: '',
    }
    let validationResult
    validationResult = validate(form, constraintsEsExporter)
    if (validationResult !== undefined) {
      setErrorsExporter(validationResult)
      setLoading(false)
      return
    }
    setErrorsExporter({})

    await EXPORTERS_SERVICES.createFromPreLoad(user.token, form)
      .then((data) => {
        setExporterNotFound(false)
        setExporterEmail('')
        setExporterId(data.data._id)
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: 'Exportador ingresado', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const saveProducer = async () => {
    const form = {
      rut: clean(producerRut),
      email: producerEmail,
      name: '',
    }
    let validationResult
    validationResult = validate(form, constraintsEsProducer)

    if (validationResult !== undefined) {
      setErrorsProducer(validationResult)
      return
    }
    setErrorsProducer({})

    await PRODUCERS_SERVICES.createFromPreLoad(user.token, form)
      .then((data) => {
        setProducerId(data.data._id)

        setProducerNotFound(false)
        setProducerEmail('')
        dispatch(actionsUI.addAlert({id: '1', title: 'Productor ingresado', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const saveDriver = async () => {
    const form = {
      rut: clean(driverRut),
      name: driverName,
      lastName: driverLastName,
    }
    let validationResult
    validationResult = validate(form, constraintsEsDriver)
    if (validationResult !== undefined) {
      setErrorsDriver(validationResult)
      return
    }
    setErrorsDriver({})

    await DRIVERS_SERVICES.createFromPreLoad(user.token, form)
      .then((data) => {
        setDriverNotFound(false)
        setDriverName('')
        setDriverId(data.data._id)
        setDriverLastName('')
        dispatch(actionsUI.addAlert({id: '1', title: 'Chofer ingresado', color: 'success', iconType: 'check'}))
      })
      .catch((error) => {
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const save = async () => {
    setDisable(true)
    setLoading(true)
    const form = {
      exporterRut: clean(exporterRut),
      exporter: exporterId ? exporterId : undefined,
      producerRut: clean(producerRut),
      producer: producerId,
      carrier: carrierId,
      driverRut: clean(driverRut),
      driver: driverId,
      number: number,
      weight: Number(weight),
      state: 'preload',
      isPreLoad: true,
    }
    let validationResult
    validationResult = validate(form, constraintsEs)

    if (validationResult !== undefined) {
      setErrors(validationResult)
      setLoading(false)
      setDisable(false)
      return
    }

    setErrors({})

    await GUIDES_SERVICES.createPreLoad(user.token, form)
      .then((data) => {
        setIsPreLoad(true)
        addGuide(data.data)
        dispatch(actionsUI.addAlert({id: '1', title: 'Pre carga ingresada', color: 'success', iconType: 'check'}))
        setExporterRut('')
        setProducerRut('')
        setDriverRut('')
        setCarrierRut('')
        setNumber()
        setWeight('')
        closeModalGuide()
        setLoading(false)
        setDisable(false)
        CARRIERS_SERVICES.update(user.token, {driver: driverId}, carrierId)
          .then((data) => {})
          .catch((error) => {
            setLoading(false)
            dispatch(
              actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}),
            )
          })
      })
      .catch((error) => {
        setLoading(false)
        dispatch(actionsUI.addAlert({id: '1', title: error.response.data.message, color: 'danger', iconType: 'alert'}))
      })
  }

  const formSample = (
    <EuiForm>
      <EuiFlexGroup responsive={true}>
        <EuiFlexItem>
          <EuiCollapsibleNavGroup background={'light'} titleSize={'xs'} isCollapsible={false} initialIsOpen={true}>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow
                  label='Rut Productor'
                  fullWidth
                  isInvalid={!errors.producerRut ? false : true}
                  error={!errors.producerRut ? '' : errors.producerRut[0]}
                >
                  <EuiFieldText
                    fullWidth
                    name='producerRut'
                    onChange={(e) => onChangeProducerRut(e)}
                    value={producerRut}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            {producerNotFound ? (
              <EuiCallOut title='Productor no registrado, debe indicar su correo' color='warning' iconType='help'>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      fullWidth
                      isInvalid={!errorsProducer.email ? false : true}
                      error={!errorsProducer.email ? '' : errorsProducer.email[0]}
                    >
                      <EuiFieldText
                        fullWidth
                        name='producerEmail'
                        onChange={(e) => onChangeProducerEmail(e)}
                        value={producerEmail}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiButton onClick={saveProducer} color='warning'>
                      Ingresar
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiCallOut>
            ) : null}

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow
                  label='Rut Exportador'
                  fullWidth
                  isInvalid={!errors.exporterRut ? false : true}
                  error={!errors.exporterRut ? '' : errors.exporterRut[0]}
                >
                  <EuiFieldText
                    fullWidth
                    name='exporterRut'
                    onChange={(e) => onChangeExporterRut(e)}
                    value={exporterRut}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            {exporterNotFound ? (
              <EuiCallOut title='Exportador no registrado, debe indicar su email' color='warning' iconType='help'>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      fullWidth
                      isInvalid={!errorsExporter.email ? false : true}
                      error={!errorsExporter.email ? '' : errorsExporter.email[0]}
                    >
                      <EuiFieldText
                        fullWidth
                        name='exporterEmail'
                        onChange={(e) => onChangeExporterEmail(e)}
                        value={exporterEmail}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiButton onClick={saveExporter} color='warning'>
                      Ingresar
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiCallOut>
            ) : null}

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow
                  label='Rut Transportista'
                  fullWidth
                  isInvalid={!errors.carrierRut ? false : true}
                  error={!errors.carrierRut ? '' : errors.carrierRut[0]}
                >
                  <EuiFieldText
                    fullWidth
                    name='carrierRut'
                    onChange={(e) => onChangeCarrierRut(e)}
                    value={carrierRut}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            {carrierNotFound ? (
              <EuiCallOut title='Transportista no registrado, debe indicar el nombre' color='warning' iconType='help'>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      fullWidth
                      isInvalid={!errors.carrierName ? false : true}
                      error={!errors.carrierName ? '' : errors.carrierName[0]}
                    >
                      <EuiFieldText
                        fullWidth
                        name='carrierName'
                        onChange={(e) => onChangeCarrierName(e)}
                        value={carrierName}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiButton onClick={saveCarrier} color='warning'>
                      Ingresar
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiCallOut>
            ) : null}

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow
                  label='Rut Chofer'
                  fullWidth
                  isInvalid={!errors.driverRut ? false : true}
                  error={!errors.driverRut ? '' : errors.driverRut[0]}
                >
                  <EuiFieldText fullWidth name='driverRut' onChange={(e) => onChangeDriverRut(e)} value={driverRut} />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            {driverNotFound ? (
              <EuiCallOut title='Chofer no registrado, debe registrarlo' color='warning' iconType='help'>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      label='Nombre'
                      fullWidth
                      isInvalid={!errorsDriver.name ? false : true}
                      error={!errorsDriver.name ? '' : errorsDriver.name[0]}
                    >
                      <EuiFieldText
                        fullWidth
                        name='driverName'
                        onChange={(e) => onChangeDriverName(e)}
                        value={driverName}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>

                  <EuiFlexItem>
                    <EuiFormRow
                      label='Apellido'
                      fullWidth
                      isInvalid={!errorsDriver.lastName ? false : true}
                      error={!errorsDriver.lastName ? '' : errorsDriver.lastName[0]}
                    >
                      <EuiFieldText
                        fullWidth
                        name='driverLastName'
                        onChange={(e) => onChangeDriverLastName(e)}
                        value={driverLastName}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiButton onClick={saveDriver} color='warning'>
                      Ingresar
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiCallOut>
            ) : null}

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow
                  label='N° Guía de despacho'
                  fullWidth
                  isInvalid={!errors.number ? false : true}
                  error={!errors.number ? '' : errors.number[0]}
                >
                  <EuiFieldNumber min='0' fullWidth name='number' onChange={(e) => onChangeNumber(e)} value={number} />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow
                  label='Peso según guía'
                  fullWidth
                  isInvalid={!errors.weight ? false : true}
                  error={!errors.weight ? '' : errors.weight[0]}
                >
                  <EuiFieldNumber fullWidth name='weight' onChange={(e) => onChangeWeight(e)} value={weight} />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiCollapsibleNavGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiForm>
  )

  let modal

  if (isModalVisibleGuide) {
    modal = (
      <EuiOverlayMask>
        <EuiModal maxWidth={false} onClose={closeModalGuide} initialFocus='[name=popswitch]'>
          <EuiModalHeader>
            {loading ? <EuiProgress size='xs' position='absolute' /> : null}
            <EuiModalHeaderTitle>Precarga de Guía de Despacho</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>{formSample}</EuiModalBody>

          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeModalGuide}>Salir</EuiButtonEmpty>

            <EuiButton onClick={save} fill isDisabled={disable}>
              Guardar
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <span className='buttonIcon'>
      <EuiButtonIcon iconSize='xl' onClick={showModalGuide} iconType='createSingleMetricJob' aria-label='Next' />

      {modal}
    </span>
  )
}

export default GuidePreLoad
