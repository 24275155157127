import React from 'react'
import {EuiFlexItem, EuiFlexGroup, EuiText} from '@elastic/eui'
import GuideForm from './../GuideForm'
import GuidePreLoad from './../GuidePreLoad'
import './index.css'

const ReceptionCard = ({setIsPreLoad}) => {
  return (
    <>
      <EuiFlexGroup gutterSize='xs' alignItems='center'>
        <EuiFlexItem grow={false}>
          <GuideForm setIsPreLoad={setIsPreLoad} />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>Carga</EuiText>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <GuidePreLoad setIsPreLoad={setIsPreLoad} />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>Precarga</EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

export default ReceptionCard
