import React, {useState} from 'react'
import {
  EuiOverlayMask,
  EuiModal,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButtonIcon,
  EuiBasicTable,
  EuiPanel,
  EuiIcon,
  EuiModalHeader,
} from '@elastic/eui'
import QrPrint from './../QrPrint'

const DryingContainers = ({guide}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [guideContainers, setGuideContainers] = useState([])
  const [maskOpen, changeMask] = useState(false)
  const [code, setCode] = useState(0)
  const [weight, setWeight] = useState('')

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const showModal = async () => {
    setIsModalVisible(true)
    const dataContainer = [].concat.apply([], guide.drying.containers)
    setGuideContainers(dataContainer)
  }

  const changeMaskQr = (e) => {
    setCode(e.code)
    setWeight(e.weightWithContainer)
    changeMask(true)
  }

  const modalMask = <QrPrint guide={guide} code={code} weight={weight} changeMask={changeMask} />

  const actions = [
    {
      name: 'Imprimir',
      description: 'Imprimir',
      icon: 'tableDensityExpanded',
      type: 'icon',
      onClick: changeMaskQr,
    },
  ]

  const columns = [
    {
      field: 'code',
      name: 'Código',
    },
    {
      field: 'box',
      name: 'Cajón',
    },
    {
      field: 'container[0].name',
      name: 'Envase',
    },
    {
      field: 'tare',
      name: 'Tara',
    },
    {
      field: '',
      name: 'Peso neto',
      render: (c) => c.weightWithContainer - c.tare,
    },
    {
      field: 'weightWithContainer',
      name: 'Peso bruto',
    },

    {
      field: 'stateCheck',
      name: 'Checkeado',
      render: (check) =>
        check ? <EuiIcon type='check' color='secondary' size='l' /> : <EuiIcon type='cross' color='danger' size='l' />,
    },
    {
      field: 'stateLoad',
      name: 'Cargado',
      render: (check) =>
        check ? <EuiIcon type='check' color='secondary' size='l' /> : <EuiIcon type='cross' color='danger' size='l' />,
    },
    {
      actions,
      width: '5%',
    },
  ]

  let modal
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal maxWidth={false} onClose={closeModal} initialFocus='[name=popswitch]'>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Resumen de Secado</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiPanel>
              <EuiBasicTable items={guideContainers} rowHeader='firstName' columns={columns} />
            </EuiPanel>
          </EuiModalBody>

          <EuiModalFooter></EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <>
      {modal}
      {maskOpen ? modalMask : undefined}
      <EuiButtonIcon onClick={showModal} iconType='filebeatApp' iconSize='l' />
    </>
  )
}

export default DryingContainers
