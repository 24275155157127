import API from './constants'

var VARIABLES_SERVICES = {
  getAll: async (token, types, searchName) => {
    return await API.get(
      `variable?filterName=${searchName}`,
      {types},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        contentType: true,
      },
    )
  },
  getOne: async (token, id) => {
    return await API.get(`variable/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  getLot: async (token) => {
    return await API.get(`variable/lot`, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
  update: async (token, data, id) => {
    return await API.put(`variable/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      contentType: true,
    })
  },
}

export default VARIABLES_SERVICES
