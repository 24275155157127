import React, {useState, useContext, useEffect} from 'react'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiFlexGrid,
  EuiPanel,
  EuiCollapsibleNavGroup,
  EuiSwitch,
  EuiLoadingSpinner,
  EuiComboBox,
  EuiFieldSearch,
  EuiDatePickerRange,
  EuiDatePicker,
  EuiProgress,
  EuiHeaderSectionItemButton,
} from '@elastic/eui'
import ReceptionCard from './../../components/ReceptionCard'
import GuideCard from './../../components/GuideCard'
import GuideReception from './../../components/GuideReception'
import {DataContext} from './../../context/DataContext'
import Stats from './../Stats'
import moment from 'moment'

const Guides = () => {
  const {
    guidesPreLoad,
    guidesLoad,
    guidesQuality,
    guidesDispatch,
    guidesDrying,
    user,
    getGuides,
    loading,
    useDebounce,
    comboExporters,
    comboProducers,
  } = useContext(DataContext)
  const [collapse, setCollapse] = useState(false)
  const [checked, setChecked] = useState(false)
  const [isLoading] = useState(false)
  const [numberGuide, setNumberGuide] = useState('')
  const [selectedProducer, setSelectedProducer] = useState([])
  const [selectedExporter, setSelectedExporter] = useState([])
  const [startDate, setStartDate] = useState(moment().startOf('year'))
  const [endDate, setEndDate] = useState(moment().add(1, 'd'))
  const [totalDry, setTotalDry] = useState(0)
  const [totalContainers, setTotalContainers] = useState(0)

  useEffect(() => {
    calcule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guidesDispatch])

  const calcule = () => {
    let dry = 0
    let totalContainers = 0
    // eslint-disable-next-line array-callback-return
    guidesDispatch.map((guide) => {
      if (guide.state === 'dispatch') {
        const c = [].concat.apply([], guide.drying.containers)

        // eslint-disable-next-line array-callback-return
        c.map((container) => {
          dry = dry + (container.weightWithContainer - container.tare)
          totalContainers++
        })
      }
    })

    setTotalDry(dry)
    setTotalContainers(totalContainers)
  }

  const onChange = (e) => {
    setChecked(e.target.checked)
  }

  const onChangeProducer = (itemSelected) => {
    setSelectedProducer(itemSelected)
    itemSelected.length > 0
      ? getGuides(itemSelected, numberGuide, startDate, endDate, selectedExporter)
      : getGuides('', numberGuide, startDate, endDate, selectedExporter)
  }

  const onChangeExporter = (itemSelected) => {
    setSelectedExporter(itemSelected)
    itemSelected.length > 0
      ? getGuides(selectedProducer, numberGuide, startDate, endDate, itemSelected)
      : getGuides(selectedProducer, numberGuide, startDate, endDate, '')
  }

  const debounceNumber = useDebounce(
    (val, producer, start, end, exporter) => getGuides(producer, val, start, end, exporter),
    1000,
  )
  const onChangeNumberGuide = (e) => {
    setNumberGuide(e.target.value)
    debounceNumber(e.target.value, selectedProducer, startDate, endDate, selectedExporter)
  }

  const handleChangeStart = (date) => {
    setStartDate(date)
    getGuides(selectedProducer, numberGuide, date, endDate, selectedExporter)
  }

  const handleChangeEnd = (date) => {
    setEndDate(date)
    getGuides(selectedProducer, numberGuide, startDate, date, selectedExporter)
  }

  window.addEventListener('resize', resize)

  function resize() {
    if (window.innerWidth < 1029) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  }

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem grow={2}>
          {user.user.type === 'user' ? null : <ReceptionCard setIsPreLoad={setChecked} />}
        </EuiFlexItem>
        <EuiFlexItem grow={3}></EuiFlexItem>

        {user.user.type !== 'user' || (user.user.isExporter && user.user.isProducer === false) ? (
          <EuiFlexItem grow={2}>
            <EuiComboBox
              placeholder='Productor'
              singleSelection={{asPlainText: true}}
              options={comboProducers}
              selectedOptions={selectedProducer}
              onChange={onChangeProducer}
              fullWidth
              isLoading={loading}
              isDisabled={loading}
            />
          </EuiFlexItem>
        ) : null}

        {user.user.type !== 'user' ? (
          <EuiFlexItem grow={2}>
            <EuiComboBox
              placeholder='Exportador'
              singleSelection={{asPlainText: true}}
              options={comboExporters}
              selectedOptions={selectedExporter}
              onChange={onChangeExporter}
              fullWidth
              isLoading={loading}
              isDisabled={loading}
            />
          </EuiFlexItem>
        ) : null}

        <EuiFlexItem grow={2}>
          <EuiFieldSearch
            placeholder='N° Guía'
            fullWidth
            value={numberGuide > 0 ? numberGuide : ''}
            onChange={(e) => onChangeNumberGuide(e)}
            aria-label='Use aria labels when no actual label is in use'
            isLoading={loading}
            disabled={loading}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={4}>
          <EuiDatePickerRange
            startDateControl={
              <EuiDatePicker
                fullWidth
                selected={startDate}
                onChange={handleChangeStart}
                startDate={startDate}
                endDate={endDate}
                isInvalid={startDate > endDate}
                aria-label='Start date'
                readOnly={loading}
                disabled={loading}
              />
            }
            endDateControl={
              <EuiDatePicker
                selected={endDate}
                onChange={handleChangeEnd}
                startDate={startDate}
                endDate={endDate}
                isInvalid={startDate > endDate}
                aria-label='End date'
                readOnly={loading}
                disabled={loading}
              />
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      {user.user.type === 'admin' ? (
        <Stats totalDry={totalDry} totalContainers={totalContainers} loading={loading} />
      ) : null}
      {loading ? <EuiProgress size='s' style={{marginTop: 4, marginBottom: 6}} /> : null}
      <EuiFlexGroup responsive={true}>
        <EuiFlexItem>
          <EuiFlexGrid columns={4}>
            <EuiFlexItem>
              <EuiCollapsibleNavGroup
                style={{backgroundColor: '#d3d9e4'}}
                titleSize={'xs'}
                iconType='reportingApp'
                title={
                  <EuiFlexGroup>
                    <EuiFlexItem grow={8}>RECEPCION</EuiFlexItem>
                    <EuiHeaderSectionItemButton
                      aria-label='total'
                      notificationColor='subdued'
                      notification={checked ? guidesPreLoad.length : guidesLoad.length}
                    >
                      <EuiFlexItem grow={false}></EuiFlexItem>
                    </EuiHeaderSectionItemButton>
                  </EuiFlexGroup>
                }
                isCollapsible={collapse}
                initialIsOpen={true}
              >
                <EuiSwitch
                  label={`Guías en ${checked ? 'Pre-carga' : 'Carga'}`}
                  checked={checked}
                  onChange={(e) => onChange(e)}
                />
                <EuiSpacer size='xs' />
                {isLoading ? (
                  <EuiPanel>
                    <EuiLoadingSpinner size='xl' />
                  </EuiPanel>
                ) : (
                  <>
                    {checked
                      ? guidesPreLoad.map((guide) => {
                          return (
                            <>
                              <GuideReception setIsPreLoad={setChecked} key={guide._id} guide={guide} />
                              <EuiSpacer />
                            </>
                          )
                        })
                      : guidesLoad.map((guide) => {
                          return (
                            <>
                              <GuideReception key={guide._id} guide={guide} />
                              <EuiSpacer />
                            </>
                          )
                        })}
                  </>
                )}
              </EuiCollapsibleNavGroup>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiCollapsibleNavGroup
                style={{backgroundColor: '#d3d9e4'}}
                iconType='searchProfilerApp'
                titleSize={'xs'}
                title={
                  <EuiFlexGroup>
                    <EuiFlexItem grow={8}>CALIDAD</EuiFlexItem>
                    <EuiHeaderSectionItemButton
                      aria-label='total'
                      notificationColor='subdued'
                      notification={guidesQuality.length}
                    >
                      <EuiFlexItem grow={false}></EuiFlexItem>
                    </EuiHeaderSectionItemButton>
                  </EuiFlexGroup>
                }
                isCollapsible={collapse}
                initialIsOpen={true}
              >
                {' '}
                {guidesQuality.map((guide) => {
                  return (
                    <>
                      <GuideCard key={guide._id} guide={guide} />
                      <EuiSpacer />
                    </>
                  )
                })}
              </EuiCollapsibleNavGroup>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiCollapsibleNavGroup
                style={{backgroundColor: '#d3d9e4'}}
                iconType='discoverApp'
                titleSize={'xs'}
                title={
                  <EuiFlexGroup>
                    <EuiFlexItem grow={8}>SECADO</EuiFlexItem>
                    <EuiHeaderSectionItemButton
                      aria-label='total'
                      notificationColor='subdued'
                      notification={guidesDrying.length}
                    >
                      <EuiFlexItem grow={false}></EuiFlexItem>
                    </EuiHeaderSectionItemButton>
                  </EuiFlexGroup>
                }
                isCollapsible={collapse}
                initialIsOpen={true}
              >
                {guidesDrying.map((guide) => {
                  return (
                    <>
                      <GuideCard key={guide._id} guide={guide} />
                      <EuiSpacer />
                    </>
                  )
                })}
              </EuiCollapsibleNavGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCollapsibleNavGroup
                style={{backgroundColor: '#d3d9e4'}}
                iconType='uptimeApp'
                titleSize={'xs'}
                title={
                  <EuiFlexGroup>
                    <EuiFlexItem grow={8}>DESPACHO</EuiFlexItem>
                    <EuiHeaderSectionItemButton
                      aria-label='total'
                      notificationColor='subdued'
                      notification={guidesDispatch.length}
                    >
                      <EuiFlexItem grow={false}></EuiFlexItem>
                    </EuiHeaderSectionItemButton>
                  </EuiFlexGroup>
                }
                isCollapsible={collapse}
                initialIsOpen={true}
              >
                {guidesDispatch.map((guide) => {
                  return (
                    <>
                      <GuideCard key={guide._id} guide={guide} />
                      <EuiSpacer />
                    </>
                  )
                })}
              </EuiCollapsibleNavGroup>
            </EuiFlexItem>
          </EuiFlexGrid>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

export default Guides
